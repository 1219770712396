import { ScrollView, View, } from "react-native";
import { Checkbox, Divider, Menu, TextInput, TouchableRipple, useTheme, } from "react-native-paper";
import React, { forwardRef, useEffect, useState, useCallback, Fragment, } from "react";
import {isString} from 'lodash';

const MultiDropDown = forwardRef((props, ref) => {
  const activeTheme = useTheme();
  const { multiSelect = false, visible, onDismiss, showDropDown, value, setValue, activeColor, mode, label, placeholder, inputProps, list, dropDownContainerMaxHeight, dropDownContainerHeight, theme, dropDownStyle, dropDownItemStyle, dropDownItemSelectedStyle, dropDownItemTextStyle, dropDownItemSelectedTextStyle, accessibilityLabel, } = props;
  const [displayValue, setDisplayValue] = useState("");
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onLayout = (event) => {
    setInputLayout(event.nativeEvent.layout);
  };

  useEffect(() => {
    if (multiSelect) {
      if(value){
        const _labels = list
          .filter((_) => value.indexOf(_.value) !== -1)
          .map((_) => _.label)
          .join(", ");

        setDisplayValue(_labels);
      }
    }
    else {
      const _label = list.find((_) => _.value === value)?.label;
      if (_label) {
        setDisplayValue(_label);
      }
    }
  }, [list, value]);

  const isActive = useCallback((currentValue) => {
    if (multiSelect) {
      if(value){
        return value.indexOf(currentValue) !== -1;
      }else{
        //setValue([]);
        return false;
      }
    }
    else {
      return value === currentValue;
    }
  }, [value]);

  const setActive = useCallback((currentValue) => {
    if (multiSelect) {
      console.log('currentValue', currentValue);
      console.log('value', value);

      //For starting off blank
      let valueIndex = -1;
      if(value === undefined){
        setValue(currentValue);
      }else{
        valueIndex = value.indexOf(currentValue);
      }

      console.log('valueIndex', valueIndex);

      if (valueIndex === -1) {
        //Add
        if(value === undefined || value.length === 0){
          setValue([currentValue].join(","));
        }else{
          setValue([value, currentValue].join(","));
        }

      }
      else {
        //Remove
        console.log(value);
        let newValue = value;
        if(isString(newValue)){
          console.log('newValue is a string!');
          newValue = newValue.substring(0).split(",")
        }
        newValue = newValue.filter((value) => value != currentValue).join(",");
        console.log(newValue);
        setValue(newValue);
      }

    }
    else {
      setValue(currentValue);
    }
  }, [value]);

  return (
    <Menu
      visible={visible}
      onDismiss={() => onDismiss(value)}
      theme={theme}
      anchor={
        <TouchableRipple /*ref={ref}*/ onPress={showDropDown} onLayout={onLayout} accessibilityLabel={accessibilityLabel}>
          <View pointerEvents={"none"}>
            <TextInput
              value={displayValue}
              ref={ref}
              mode={mode}
              label={label}
              placeholder={placeholder}
              pointerEvents={"none"}
              theme={theme}
              right={<TextInput.Icon name={visible ? "menu-up" : "menu-down"}/>}
              {...inputProps}
            />
          </View>
        </TouchableRipple>
      }
      style={{
        maxWidth: inputLayout?.width,
        width: inputLayout?.width,
        marginTop: inputLayout?.height,
        ...dropDownStyle,
      }}
    >
    <ScrollView bounces={false} style={{
      ...(dropDownContainerHeight
        ? {
          height: dropDownContainerHeight,
        }
        : {
          maxHeight: dropDownContainerMaxHeight || 200,
        }),
    }}>
      {list.map((_item, _index) => (<Fragment key={_item.value}>
        <TouchableRipple
          style={{
            flexDirection: "row",
            alignItems: "center",
            maxWidth: '100%',
          }}
          onPress={() => console.log(_item)}
          /*onPress={(e) => {
            console.log('ripplePress', e);
            setActive(_item.value);
            if (onDismiss) {
              console.log('dismiss ripplePress');
              onDismiss();
            }
          }}*/
        >
          <Fragment>
            <Menu.Item
              titleStyle={{
                color: isActive(_item.value)
                  ? activeColor || (theme || activeTheme).colors.primary
                  : (theme || activeTheme).colors.text,
                ...(isActive(_item.value)
                  ? dropDownItemSelectedTextStyle
                  : dropDownItemTextStyle),
              }}

              title={_item.custom || _item.label}

              contentStyle={{
                width: '100%',
                maxWidth: '100%',
              }}

              style={{
                flex: 1,
                width: '100%',
                maxWidth: '100%',
                /*maxWidth: inputLayout?.width,
                ...(isActive(_item.value)
                  ? dropDownItemSelectedStyle
                  : dropDownItemStyle),*/
              }}

              onPress={() => {
                console.log('item press')
                setActive(_item.value)
                //if(!multiSelect) { setActive(_item.value)}
              }}
            />
            {multiSelect &&
              (<Checkbox.Android
                theme={{
                  colors: { accent: activeTheme?.colors.primary },
                }}
                status={isActive(_item.value) ? "checked" : "unchecked"}
                onPress={() => setActive(_item.value)}
              />)
            }
          </Fragment>
        </TouchableRipple>
        <Divider />
      </Fragment>))}
    </ScrollView>
  </Menu>
  );
});
export default MultiDropDown;
