import * as React from 'react';
import { Modal, Portal, Text, Button, Provider, TextInput } from 'react-native-paper';
import {useContext} from "react";
import {PowerforceContext} from "../../helpers/context";
import {Pressable, ScrollView, View} from "react-native";
import {Feather} from "@expo/vector-icons";

const BookmarkManagerModal = (props) => {

  const powerforce = useContext(PowerforceContext);

  const containerStyle = {backgroundColor: 'white', padding: 20, zIndex: 999, margin: '20px', alignSelf: 'center'};

  const [bookMarks, setBookMarks] = React.useState(props.bookmarks); //Stays in background and keeps track of bookmarks
  const [saving, setSaving] = React.useState(false);

  // Reset text on modal close/open
  React.useEffect(() => {
    setSaving(false);
  }, [props.visible])

  function save(){
    console.log(bookMarks);
    props.onSave(bookMarks)
    setSaving(true);
  }

  function markForDeletion(bookmark){
    let bookMarksUpdated = bookMarks;
    bookMarksUpdated = bookMarksUpdated.filter(function( obj ) {
      return obj.value !== bookmark.value;
    });
    console.log(bookMarksUpdated);
    setBookMarks(bookMarksUpdated);
  }

  function unmarkForDeletion(bookmark){
    let bookMarksUpdated = bookMarks;
    bookMarksUpdated.push(bookmark);
    console.log(bookMarksUpdated);
    setBookMarks(bookMarksUpdated);
  }

  function updateBookmarkLabel(label, bookmark){
    let bookMarksUpdated = bookMarks;
    let objIndex = bookMarksUpdated.findIndex(obj => obj.value == bookmark.value);
    bookMarksUpdated[objIndex].label = label;
    setBookMarks(bookMarksUpdated);
  }


  return (
    <Provider >
      <Portal >
        <Modal
          visible={props.visible}
          onDismiss={props.onDismiss}
          contentContainerStyle={containerStyle}
          style={{zIndex: 999}}
        >
          <Pressable
            onPress={props.onDismiss}
            style={{marginLeft:"auto"}}
          >
            <Feather name={'x'} size={20} color={'gray'}  />
          </Pressable>
          <Text style={{color:'black'}}>Bookmarks Manager</Text>
          <Text style={{color:'black', fontSize:'10px'}}>Changes are only saved when the 'Save Changes' button is clicked.</Text>
          <ScrollView style={{maxHeight:'400px'}}>
            {props.bookmarks.map((bookmark) => {
              console.log(bookmark);
              return(
                <Bookmark
                  bookmark={bookmark}
                  key={bookmark.value}
                  onMarkForDeletion={markForDeletion}
                  onUnmarkForDeletion={unmarkForDeletion}
                  updateBookmarkLabel={updateBookmarkLabel}
                  saving={saving}
                />
              )
            })}
          </ScrollView>

          <Button
            icon="content-save"
            mode="contained"
            onPress={save}
            disabled={saving}
            loading={saving}
            color={powerforce.colors.primary}
            labelStyle={saving ? {'color':'grey'} : {'color':'white'}}
          >
            {saving ? 'Saving Changes' : 'Save Changes'}
          </Button>

        </Modal>
      </Portal>
    </Provider>
  );
};

function Bookmark(props){
  const powerforce = useContext(PowerforceContext);

  const [label, setLabel] = React.useState(props.bookmark.label);
  const [deleteThis, setDeleteThis] = React.useState(false);

  let textBoxStyles = {
    marginVertical: '5px',
    minWidth: '100px',
    marginRight: '10px',
    height: '50px'
  };

  let deleteTextBoxStyles = {
    marginVertical: '5px',
    minWidth: '100px',
    height: '50px',
    marginRight: '10px',
    backgroundColor: 'red',
    textDecoration:'line-through'
  };

  const markOrUnmark = (markForDeletion) => {

    if(!markForDeletion){
      props.onMarkForDeletion(props.bookmark)
    }else {
      props.onUnmarkForDeletion(props.bookmark)
    }

    setDeleteThis(!deleteThis);

  }

  const updateLabel = (newLabel) => {
    props.updateBookmarkLabel(newLabel, props.bookmark);
    setLabel(newLabel)
  }

  return (
    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
      <TextInput
        label=""
        value={label}
        onChangeText={text => updateLabel(text)}
        placeholder={'Example: Q4 Projections'}
        editable={!deleteThis}
        activeUnderlineColor={deleteThis ? 'red' : powerforce.colors.primary}
        style={deleteThis ? deleteTextBoxStyles : textBoxStyles}
      />
      <Button
        icon="delete"
        mode="contained"
        onPress={ () => {
          markOrUnmark(deleteThis);
        }}
        color={powerforce.colors.primary}
        disabled={props.saving}
      >
        {deleteThis ? 'Undo Delete' : 'Delete'}
      </Button>
    </View>
  );
}

export default BookmarkManagerModal;
