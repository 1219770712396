import React, { useCallback, useEffect, useRef, useState} from 'react'
import { Dimensions, Text, View, Platform } from 'react-native'
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown'

import Feather from 'react-native-vector-icons/Feather'
import {isValidHttpUrl} from "../../../helpers/general";
import {getData} from "../../../helpers/api";
import {PowerforceContext} from "../../../helpers/context";
Feather.loadFont()

export const Autocomplete = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [suggestionsList, setSuggestionsList] = useState(props.value ? [{id:1, title: props.value}] : null)

  const [selectedItem, setSelectedItem] = useState(props.value ? 1 : null)
  const dropdownController = useRef(null)

  const [readyForSaving, setReadyForSaving] = useState(false)

  //const searchRef = useRef(ref)

  const powerforce = React.useContext(PowerforceContext);

  function prepUrl(url, query){
    if(isValidHttpUrl(url)){
      //External URL
      return url;
    }else{
      //Internal URL
      return '/data/'+url+'/'+props.gridCode+'/'+query;
    }
  }

  const getSuggestions = useCallback(async q => {
    const filterToken = q.toLowerCase()
    if (typeof q !== 'string' || q.length < 3) {
      setSuggestionsList(null)
      return
    }
    setLoading(true)
    const items = await getData(prepUrl(props.url, q), powerforce.authToken)
    console.log(items);
    //const items = await response.json()
    let suggestions = [];
    if(items.status !== undefined && items.status > 200){
      suggestions = [{id: 0, title: 'Error getting autocomplete.'}]
    }else{
      suggestions = items
        .filter(item => item.title.toLowerCase().includes(filterToken))
        .map(item => ({
          id: item.id,
          title: item.title,
        }))
    }

    // First let's save the text, then open suggestions list
    props.onChange(props.dataSource, q);
    setSuggestionsList(suggestions)
    setLoading(false)

  }, [])

  const onClearPress = useCallback(() => {
    setSuggestionsList(null)
  }, [])

  const onOpenSuggestionsList = useCallback(isOpened => {}, [])

  const [filled, setFilled] = useState(selectedItem ? true : false)
  const unfilledLabelStyle = {fontSize: '16px', color: 'rgb(106, 106, 106)',  position:'absolute', top: '20px', zIndex: -1};
  const filledLabelStyle = {fontSize: '11px', color: 'rgb(106, 106, 106)', position:'absolute', top: '10px', zIndex: -1};


  const onFill = () => {
    if(filled === false){
      setFilled(true)
    }else{
      setSuggestionsList(null)
    }
  }

  const onBlur = () => {
    if(!selectedItem){
      //setFilled(false)
    }
  }

  //Delay for 1 sec after first load to not save this value every time
  useEffect(() => {
    //setReadyForSaving(true);
    setTimeout(() => {
      if(!readyForSaving){
        setReadyForSaving(true);
      }
    }, 250)
    return () => {
      setReadyForSaving(false);
    }
  }, [])


  return (
    <>
      <View
        //ref={ref}
        //focusable={true}
        style={[
          {
            backgroundColor: 'rgb(231, 231, 231)',
            color: 'rgb(106, 106, 106)',
            paddingTop:'10px',
            paddingLeft:'10px',
            paddingRight:'10px',
            position: 'relative'
          },
          Platform.select({ ios: { zIndex: 1 } }),
        ]}>
          <AutocompleteDropdown
            ref={ref}
            controller={controller => {
              dropdownController.current = controller
            }}
            initialValue={props.value ? {id:1, title: props.value} : null}
            direction={Platform.select({ ios: 'down' })}
            dataSet={suggestionsList}
            onChangeText={getSuggestions}
            onSelectItem={item => {
              item && setSelectedItem(item.id)
              if(readyForSaving && item){
                props.onChange(props.dataSource, item.title, props.sectionIndex);
              }
            }}
            debounce={600}
            suggestionsListMaxHeight={Dimensions.get('window').height * 0.4}
            onClear={onClearPress}
            onFocus={onFill}
            onBlur={onBlur}
            //  onSubmit={(e) => onSubmitSearch(e.nativeEvent.text)}
            onOpenSuggestionsList={onOpenSuggestionsList}
            loading={loading}
            useFilter={false} // set false to prevent rerender twice
            textInputProps={{
              placeholder: '',
              autoCorrect: false,
              autoCapitalize: 'none',
              style: {
                width: '100%',
                backgroundColor: 'transparent',
                outlineColor: 'rgb(231, 231, 231)',
                outlineWidth: 0,
                color: '#000',
                fontSize: '16px',
                zIndex: 2
              },
            }}
            containerStyle={{
              backgroundColor: 'transparent',
            }}
            inputContainerStyle={{
              backgroundColor: 'transparent',
            }}
            renderItem={(item, text) => <Text style={{ color: '#000', backgroundColor: '#fff', padding: 15 }}>{item.title}</Text>}
            ChevronIconComponent={<Feather name="chevron-down" size={20} color="#000" />}
            ClearIconComponent={<Feather name="x-circle" size={18} color="#000" />}
            EmptyResultComponent={<Text style={{ color: '#000', backgroundColor: '#fff', padding: 15 }}>No results</Text>}
            inputHeight={50}
            showChevron={false}
            showClear={false}
            clearOnFocus={false}
            //closeOnBlur={true}
          />
        <Text
          style={filled ? filledLabelStyle : unfilledLabelStyle}
          onPress={onFill}
        >
          {props.label}
        </Text>
          <View style={{
            backgroundColor: 'rgba(0, 0, 0, 0.26)',
            height: '2px',
            width: '100%',
            position:'absolute',
            bottom:'0px',
            left:'0px'
          }}
          />
      </View>
    </>
  )
})
