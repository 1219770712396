import * as React from 'react';
import {Button, Card, Paragraph} from 'react-native-paper';
import {getData, postData} from "../../helpers/api";
import {Text, View} from "react-native";
import {isDesktop} from '../../helpers/responsive'
import {PowerforceContext} from "../../helpers/context";

export default function ButtonComponent(props){

  const powerforce = React.useContext(PowerforceContext);

  const goToLink = (link) => {

    if(props.data.onPress){
      console.log('onPress!');

      postData('/data/button/' + props.data.code, powerforce.authToken, {onPress: true}).then(response => {
        console.log(response);
        alert(response);

      });

    }else{
      if(link.startsWith('http')){
        window.location.href = props.data.url
      }else{
        //Internal Navigation
        if(link.match(/\//)){

          let module = link.replace(/\/[^\/]+$/, '');

          let screen = link.replace(/^[^/]+\//, '');
          if(screen.startsWith('/')){
            screen = undefined;
          }

          props.navigation.navigate(
            module,
            {
              screenName: screen,
              recordID: undefined,
              tableName: undefined,
            }
          )
        }else{
          props.navigation.navigate(link);
        }
      }
    }
    }


  return (
    <Button
      icon={props.data.icon}
      mode="contained"
      onPress={() => goToLink(props.data.url)}
      dataSet={{'code': props.code}}
    >
      {props.data.text}
    </Button>
  )

}
