import * as React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import { Button, Menu, Divider, Provider } from 'react-native-paper';
import {Picker} from "@react-native-picker/picker";

const BookmarksMenu = (props) => {

  const [value, setValue] = React.useState(' ');

  const [filled, setFilled] = React.useState();

  React.useEffect(() => {
    if(value !== ' '){
      setFilled(true);
    }else{
      setFilled(false);
    }
  }, [value])



  return (
    <View style={{'position': 'relative', 'minWidth': '20%', 'maxWidth':'300px'}}>
      {/*<Text style={filled ? styles.smallLabel : styles.bigLabel}>Saved Bookmarks</Text>*/}
      <Picker
        selectedValue={value ? value : ''}
        itemStyle={styles.pickerItem}
        mode={'dialog'}
        //ref={ref}
        //numberOfLines={3}\
        onFocus={() => {console.log('onfocus')}}
        //onBlur={}
        style={styles.picker}
        onValueChange={(itemValue, itemIndex) => {
          if(itemValue !== ' ') {
            props.onChange(itemValue);
          }
          setValue(' ');
        }
        }>
        <Picker.Item
          label="Saved Bookmarks"
          value=" "
        />
        <Picker.Item
          label="Reset Filters"
          value="reset"
        />
        {
          props.items.map((item, index) => {
            return(
              <Picker.Item
                key={index}
                label={item.label}
                value={item.value}
              />
            );
          })
        }
      </Picker>
    </View>
  );
};

export default BookmarksMenu;

const styles = StyleSheet.create({
  picker : {
    backgroundColor:'#e7e7e7',
    color: '#6a6a6a',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '16px',
    borderTopWidth: '0',
    borderLeftWidth: '0',
    borderRightWidth: '0',
    borderBottomWidth: '1px',
    borderBottomColor: '#ababab',
    //marginRight: '10px',
  },
  pickerItem : {
    fontSize: '16px'
  },
  smallLabel : {
    fontSize: '12px',
    top: '5px',
    position: 'absolute',
    color: '#6a6a6a',
    left: '12px'
  },
  bigLabel : {
    fontSize: '16px',
    top: '18px',
    position: 'absolute',
    color: '#6a6a6a',
    left: '12px'
  },
});
