import React from "react";
import { Menu, TextInput, List} from "react-native-paper";
import {StyleSheet, ScrollView, View, Text} from "react-native";
import {PowerforceContext} from "../../../helpers/context";
import {Picker} from '@react-native-picker/picker';
import MultiDropDown from "./MultiDropDown";
import {isEmpty} from 'lodash';


export const Dropdown = React.forwardRef((props, ref) => {

  const powerforce = React.useContext(PowerforceContext);

  const [visible, setVisible] = React.useState(false);

  const list = [];
  //If there is a relation table change the values to match api url
  if(props.relationTable){
    props.items.map((item) => {
      let listItem = {
        label: item[props.relationColumn],
        value: item.id,
      }
      list.push(listItem);
    })
  }else{
    props.items.map((item) => {
      let listItem = {
        label: item[props.relationColumn],
        value: item[props.relationColumn],
      }
      list.push(listItem);
    })
  }


  const prepareValue = (unpreparedValue) => {
    //If it's an array, convert to string
    if(Array.isArray(unpreparedValue)){
      //console.log(unpreparedValue);
      //Unless it has no items
      if(unpreparedValue.length === 0){
        return '';
      }

      let valueArray = [];
      unpreparedValue.map((item) => {
        let labelObject = list.find(x => x.value === item);
        let valueItem = {
          label: labelObject.label,
          value: item,
        }
        valueArray.push(valueItem);
      })
      return valueArray;

    }

    return unpreparedValue;
  }
  const [value, setValue] = React.useState(props.value);

  const [filled, setFilled] = React.useState(true);
  React.useEffect(() => {
    //setFilled(props.value ? true : false);
  }, [props])

  const prepareValueForSave = (unpreparedValue) => {
    let valueArray = [];
    unpreparedValue.map((item) => {
      valueArray.push(item.value);
    })
    return valueArray;
  }

  if(!props.editable){
    if(props.multi){

      return (

        <TextInput
          label={props.label}
          value={props.value}
          editable={false}
          multiline={true}
          numberOfLines={5}
        />

      )
    }else{

      //Get title of selected item instead of api string (api/something/01)
      let valueId, selectedItem;
      if(props.value.includes('/api/')){
        valueId = Number(props.value.split('/')[3]);
        selectedItem = props.items.find(item => {
          return item.id === valueId;
        })
      }else{
        selectedItem = {};
        selectedItem[props.relationColumn] = 'None Selected'
      }

      return (

        <TextInput
          label={props.label}
          value={selectedItem[props.relationColumn]}
          editable={false}
          disabled={true}
        />

      )

    }
  }



  if(props.multi){


    //Paper dropdown (wierd render thing)
    return(
      <View style={{'position': 'relative'}}>
        <MultiDropDown
          label={props.label}
          mode={"flat"}
          multiSelect={true}
          visible={visible}
          showDropDown={() => setVisible(true)}
          onDismiss={(newVal) => {
            setVisible(false);
            let formattedVal;
            if(isEmpty(newVal) || newVal === ''){
              formattedVal = [];
            }else{
              if(Array.isArray(newVal)){
                formattedVal = newVal;
              }else{
                formattedVal = newVal.substring(0).split(",")
              }
            }
            props.onChange(props.dataSource, formattedVal, props.sectionIndex)
          }}
          ref={ref}
          value={value}
          setValue={(newVal) => {
            setValue(newVal)
          }}
          list={list}
        />
      </View>
    )

  }


  //Picker - not styled consistently
  return(
    <View style={{'position': 'relative'}}>
      <Text style={filled ? styles.smallLabel : styles.bigLabel}>{props.label}</Text>
      <Picker
        selectedValue={value ? value : ''}
        itemStyle={styles.pickerItem}
        mode={'dialog'}
        ref={ref}
        //numberOfLines={3}\
        onFocus={() => {console.log('onfocus')}}
        //onBlur={}
        style={styles.picker}
        onValueChange={(itemValue, itemIndex) => {
          props.onChange(props.dataSource, itemValue, props.sectionIndex );
          console.log(itemValue);
          setValue(itemValue);
        }
        }>
        <Picker.Item
          label=""
          value=" "
        />
      {
        props.items.map((item) => {
          return(
            <Picker.Item
              key={item.id}
              label={item[props.relationColumn]}
              value={'/api/'+props.relationTable+'/'+item.id}
            />
          );
        })
      }
      </Picker>
    </View>
  )

  //Accordion Try
  return (
    <View style={{'position': 'relative'}}>
      <List.Accordion
        title="Uncontrolled Accordion"
        style={styles.accordion}
        left={props => <List.Icon {...props} icon="folder" />}
      >
        <ScrollView style={styles.listItemContainer}>
        {
          props.items.map((item) => {
            return(
              <List.Item
                key={item.id}
                onPress={() => {
                  props.onChange(props.dataSource, item[props.relationColumn], true);
                  setVisible(false)
                }}
                title={item[props.relationColumn]}
                style={styles.listItem}
              />
            );
          })
        }
        </ScrollView>
      </List.Accordion>
    </View>
  );

  //Menu Try
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  let dropDownAnchor = <TextInput
    label={props.label}
    value={props.value}
    editable={false}
    style={styles.textInput}
    right={<TextInput.Icon
      name="menu-down"
      onPress={openMenu}
    />}
  />;

  return (

    <View style={{'position': 'relative'}}>
      <Menu
        visible={visible}
        style={{marginTop: '50px'}}
        onDismiss={closeMenu}
        anchor={dropDownAnchor}>
        <ScrollView style={styles.scrollView}>
          {
            props.items.map((item) => {
              return(
                <Menu.Item
                  key={item.id}
                  onPress={() => {
                    props.onChange(props.dataSource, item[props.relationColumn], true);
                    setVisible(false)
                  }}
                  title={item[props.relationColumn]}
                />
              );
            })
          }
        </ScrollView>

      </Menu>
    </View>

  );
})


const styles = StyleSheet.create({
  scrollView: {
    marginHorizontal: 20,
    maxHeight: '300px'
  },
  textInput: {
    marginRight: '0px'
  },
  accordion: {
    position: 'relative',
  },
  listItemContainer : {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 99
  },
  listItem: {
    backgroundColor: '#ff0000',
    position: 'relative',
    zIndex: 9
  },
  picker : {
    backgroundColor:'#e7e7e7',
    color: '#6a6a6a',
    paddingTop: '29px',
    paddingBottom: '14px',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '16px',
    borderTopWidth: '0',
    borderLeftWidth: '0',
    borderRightWidth: '0',
    borderBottomWidth: '1px',
    borderBottomColor: '#ababab',
    //marginRight: '10px',
  },
  pickerItem : {
    fontSize: '16px'
  },
  smallLabel : {
    fontSize: '12px',
    top: '5px',
    position: 'absolute',
    color: '#6a6a6a',
    left: '12px'
  },
  bigLabel : {
    fontSize: '16px',
    top: '18px',
    position: 'absolute',
    color: '#6a6a6a',
    left: '12px'
  },
});
