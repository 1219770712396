import {View} from "react-native-web";
import {Pressable, StyleSheet} from "react-native";
import {Feather} from "@expo/vector-icons";
import * as React from "react";
import {Divider, Menu} from "react-native-paper";


export const GridControls = (props) => {

  const [filters, setFilters] = React.useState(props.filters);
  const [filterMenuVisible, setFilterMenuVisible] = React.useState(false);

  const clickFilterMenu = () => {
    if(filters.length > 0){
      setFilterMenuVisible(true);
    }else{
      props.onFilter()
    }
  };

  const closeFilterMenu = () => setFilterMenuVisible(false);

  const [editText, setEditText] = React.useState(props.activeFilter.title ? 'Edit ' + props.activeFilter.title + ' Filter' : "Edit Filter");

  //Props change
  React.useEffect(() => {
    setEditText(props.activeFilter.title ? 'Edit ' + props.activeFilter.title + ' Filter' : "Edit Filter")
  }, [props]);

  const editActiveFilterButton = () => {
    if(props.activeFilter.title){
      return(
        <Menu.Item
          onPress={() => {
            props.onSelectFilter('edit')
            closeFilterMenu()
          }}
          title={editText}
        />
      )
    }
  }

  const AddButton = () => {
    if(props.canCreate){
      return (
        <Pressable
          onPress={() => props.onAdd()}
          style={styles.gridButton}
          dataSet={{'code': 'grid_add_button'}}
        >
          <Feather name="plus-circle" size={24} color="#00264d" />
        </Pressable>
      );
    }
    return null;
  }

  if(props.permission === 'write'){
    return(
      <View style={{
        flex:1,
        flexDirection: 'row',
        fontSize:'1rem',
        justifyContent: 'right'
      }}>



        <Menu
          visible={filterMenuVisible}
          onDismiss={closeFilterMenu}
          anchor={
            <Pressable
              onPress={() => {
                clickFilterMenu()
              }}
              style={styles.gridButton}
            >
              <Feather name="filter" size={24} color="#00264d" />
            </Pressable>
          }
          style={{
            marginTop: '33px'
          }}
        >
          <Menu.Item
            onPress={() => {
              props.onSelectFilter(null)
              closeFilterMenu()
            }}
            titleStyle={{fontStyle:'italic'}}
            title="Filter Off"
          />

          {filters.map(filter => {
            return(
              <Menu.Item
                key={filter.id}
                title={filter.title}
                onPress={() => {
                  props.onSelectFilter(filter)
                  closeFilterMenu()
                }}
              />
            )
          })}

          <Divider />

          {editActiveFilterButton()}

          <Menu.Item
            onPress={() => {
              props.onSelectFilter('create')
              closeFilterMenu()
            }}
            title={"Create New Filter"}
          />

        </Menu>

        <Pressable
          onPress={() => props.onDownload()}
          style={styles.gridButton}
        >
          <Feather name="download" size={24} color="#00264d" />
        </Pressable>

        {/* TODO Revisit upload functionality with error handling
          <Pressable
            onPress={() => props.onUpload()}
            style={styles.gridButton}
          >
            <Feather name="upload-cloud" size={24} color="#00264d"/>
          </Pressable>
        */}

        <AddButton />
      </View>
    )
  }

  if(props.permission === 'read'){
    return(
      <View style={{
        flex:1,
        flexDirection: 'row',
        fontSize:'1rem',
        justifyContent: 'right'
      }}>

        <Pressable
          onPress={() => props.onFilter()}
          style={styles.gridButton}
        >
          <Feather name="filter" size={24} color="#00264d" />
        </Pressable>

        <Pressable
          onPress={() => props.onDownload()}
          style={styles.gridButton}
        >
          <Feather name="download" size={24} color="#00264d" />
        </Pressable>

      </View>
    )
  }

}


const styles = StyleSheet.create({
  gridButton: {
    padding: '10px',
  }
});

