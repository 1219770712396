import {View} from "react-native-web";
import {Pressable, StyleSheet, Text} from "react-native";
import {Feather} from "@expo/vector-icons";
import * as React from "react";
import {Button, Dialog, Portal, TextInput} from "react-native-paper";
import MultiDropDown from "./IndividualRecord/MultiDropDown";
import {debounce } from "lodash";


export const FilterPopup = (props) => {

  //State for Filter popup
  const [isFilterPopupVisible, setIsFilterPopupVisible] = React.useState(props.visible);

  //For Changing Props
  React.useEffect(() => {
    setIsFilterPopupVisible(props.visible);
    setRowData(props.filterData.rowData ? props.filterData.rowData : []);
    setTitle(props.filterData.title ? props.filterData.title : 'Unnamed Filter')
  }, [props]);

  const showFilterPopup = () => setIsFilterPopupVisible(true);
  const hideFilterPopup = () => {
    setIsFilterPopupVisible(false);
    setRowData([]);
    props.onClose();
  }

  const [matchConditionText, setMatchConditionText] = React.useState(props.filterData.matchConditionText ? props.filterData.matchConditionText : 'all conditions');
  const changeMatchConditionText = () => {
    if(matchConditionText === 'all conditions'){
      setMatchConditionText('at least one condition');
    }else{
      setMatchConditionText('all conditions');
    }
  };

  const [rowData, setRowData] = React.useState(props.filterData.rowData ? props.filterData.rowData : []);
  const [title, setTitle] = React.useState(props.filterData.title ? props.filterData.title : 'Unnamed Filter');
  const changeTitle = (newTitle) => {
    setTitle(newTitle)
  }
  const debounceChangeTitle = debounce(changeTitle, 500)

  function removeFilterDataRow(rowToRemoveId){
    let newRowData = rowData;
    newRowData.splice(rowToRemoveId, 1);
    setRowData([...newRowData])
  }

  function addFilterDataRow(selectedColumn){
    let newRowData = rowData;
    newRowData.push({column: selectedColumn});
    setRowData([...newRowData])
  }

  function editFilterDataRow(rowId, key, newValue){
    let newRowData = rowData;

    newRowData[rowId][key] = newValue;

    setRowData([...newRowData])

  }

  function saveAndApplyFilter(){

    let newFilterData = {
      title: title,
      matchConditionText: matchConditionText,
      query: createFilterQueryFromValues(),
      rowData: rowData
    }

    if(props.filterData.id){
      newFilterData.id = props.filterData.id;
    }

    props.saveAndApplyFilter(newFilterData);
    hideFilterPopup();
  }

  function deleteFilter(){

    let newFilterData = {
      title: title,
      matchConditionText: matchConditionText,
      query: createFilterQueryFromValues(),
      rowData: rowData
    }

    if(props.filterData.id){
      newFilterData.id = props.filterData.id;
    }

    hideFilterPopup()
    props.deleteFilter(newFilterData)
  }

  function createFilterQueryFromValues(){
    let filterQuery = 'SELECT * FROM ? WHERE ';
    let i = 1;
    rowData.map(filter => {

      if(filter.operation === 'is blank'){
        filterQuery += filter.column + ' IS NULL';
      }

      if(filter.operation === 'is not blank'){
        filterQuery += filter.column + ' IS NOT NULL';
      }

      if(filter.operation === 'is equal to'){
        filterQuery += filter.column + ' = ' + '"'+filter.value+'"';
      }

      if(filter.operation === 'is not equal to'){
        filterQuery += filter.column + ' != ' + filter.value;
      }

      if(filter.operation === 'is greater than'){
        filterQuery += filter.column + ' > ' + filter.value;
      }

      if(filter.operation === 'is greater than or equal to'){
        filterQuery += filter.column + ' >= ' + filter.value;
      }

      if(filter.operation === 'is less than'){
        filterQuery += filter.column + ' < ' + filter.value;
      }

      if(filter.operation === 'is less than or equal to'){
        filterQuery += filter.column + ' <= ' + filter.value;
      }

      if(filter.operation === 'is between'){
        filterQuery += filter.column + ' BETWEEN ' + filter.value + ' AND ' + filter.value2;
      }

      if(filter.operation === 'is not between'){
        filterQuery += filter.column + ' NOT BETWEEN ' + filter.value + ' AND ' + filter.value2;
      }

      if(filter.operation === 'is a number'){
        filterQuery += filter.column + ' NOT LIKE "%[^0-9]%"';
      }

      if(filter.operation === 'is not a number'){
        filterQuery += filter.column + ' LIKE "%[^0-9]%"';
      }

      if(filter.operation === 'contains'){
        filterQuery += filter.column + ' LIKE ' + '"%'+filter.value+'%"';
      }

      if(filter.operation === 'does not contain'){
        filterQuery += ' NOT '+ filter.column + ' LIKE ' + '"%'+filter.value+'%"';
      }

      //Put AND or OR after all statements except the last filter
      if(i !== rowData.length){
        if(matchConditionText === 'all conditions'){
          filterQuery += ' AND ';
        }else{
          filterQuery += ' OR ';
        }
      }

      i++;
    })

    return filterQuery;

  }

  return (

    <Portal>
      <Dialog
        visible={isFilterPopupVisible}
        onDismiss={hideFilterPopup}
        style={{maxWidth:'500px', minWidth: '50%', marginLeft: 'auto', marginRight: 'auto'}}
      >
        <Dialog.Title>Filter Editor</Dialog.Title>
        <Dialog.Content>
          <TextInput
            label={'Name'}
            value={title}
            editable={true}
            onChangeText={(text) => debounceChangeTitle(text)}
          />

          <View style={styles.row}>
            <Text
              style={{marginTop: '7px', marginBottom:'9px'}}
            >
              Show rows that match
            </Text>
            <Button
              onPress={changeMatchConditionText}
              style={{marginTop:'0px', marginBottom:'0px'}}
            >{matchConditionText}</Button>
          </View>

          {
            rowData.map((rowDatum, index) => {
              return (
                <View style={styles.row} key={index}>
                  <ConditionRow
                    columns={props.columns}
                    rowData={rowDatum}
                    changeColumn={(data) => editFilterDataRow(index, 'column', data)}
                    changeOperation={(data) => editFilterDataRow(index, 'operation', data)}
                    changeValue={(data) => editFilterDataRow(index, 'value', data)}
                    changeValue2={(data) => editFilterDataRow(index, 'value2', data)}
                  />
                  <View style={{flex: '.2', alignItems: 'center', justifyContent: 'center'}}>
                    <Pressable
                      onPress={() => removeFilterDataRow(index)}
                    >
                      <Feather name="x-circle" size={24} color="#FF0000" />
                    </Pressable>
                  </View>
                </View>
              )
            })
          }

          <View style={styles.row}>
            <ConditionRowCreator
              columns={props.columns}
              onColumnSelection={addFilterDataRow}
            />
          </View>


        </Dialog.Content>

        <Dialog.Actions>

          <Button
            onPress={() => deleteFilter()}
            style={styles.button}
            mode={'contained'}
          >
            <Feather name="trash" size={16} color="#ffffff" />
          </Button>

          <Button
            onPress={() => hideFilterPopup()}
            style={styles.button}
            mode={'contained'}
          >
            Cancel
          </Button>

          <Button
            onPress={() => saveAndApplyFilter()}
            style={styles.button}
            mode={'contained'}
          >
            Apply
          </Button>

        </Dialog.Actions>

      </Dialog>
    </Portal>

  )

}

const ConditionRow = (props) => {

  /**
   * Operation Value
   */
  const [operationValueType, setOperationValueType] = React.useState(props.rowData.operation ? changeOperationsValue(props.rowData.operation) : 'none');

  const operationValue = (value, value2) => {
    if(operationValueType === 'none'){
      return(
        <TextInput
          label={''}
          value={''}
          editable={false}
        />
      )
    }

    if(operationValueType === 'text'){
      return(
        <TextInput
          label={'Value'}
          value={value ? value : ''}
          editable={true}
          onChangeText={ text => { debounceChangeValue(text)} }
        />
      )
    }

    if(operationValueType === 'doubleText'){
      return(
        <View>
          <TextInput
            label={'Value'}
            value={value ? value : ''}
            editable={true}
            onChangeText={ text => { debounceChangeValue(text)} }
          />
          <TextInput
            label={'Value 2'}
            value={value2 ? value2 : ''}
            editable={true}
            onChangeText={ text => { debounceChangeValue2(text)} }
          />
        </View>
      )
    }

    if(operationValueType === 'dropdown'){
      return(
        <FilterDropdown
          label={'Value'}
          value={value ? value : ''}
          list={operationList}
          onValueChange={changeValue}
        />
      )
    }

  }

  /**
   * Lists
   */

  const operationList = [
    //{label:'is one of', value:'is one of'},
    //{label:'is not one of', value:'is not one of'},
    {label:'is blank', value:'is blank'},
    {label:'is not blank', value:'is not blank'},
    {label:'is equal to', value:'is equal to'},
    {label:'is not equal to', value:'is not equal to'},
    {label:'is greater than', value:'is greater than'},
    {label:'is greater than or equal to', value:'is greater than or equal to'},
    {label:'is less than', value:'is less than'},
    {label:'is less than or equal to', value:'is less than or equal to'},
    {label:'is between', value:'is between'},
    {label:'is not between', value:'is not between'},
    {label:'is a number', value:'is a number'},
    {label:'is not a number', value:'is not a number'},
    {label:'contains', value:'contains'},
    {label:'does not contain', value:'does not contain'},
  ]


  const columnList = () => {

    let list = [];
    props.columns.map(column => {
      list.push({label: column, value: column})
    })
    return list;
  }

  /**
   * Changing Values
   */
  const changeColumn = (newColumn) => {
    props.changeColumn(newColumn)
  }

  const changeValue = (newValue) => {
    props.changeValue(newValue)
  }
  const debounceChangeValue = debounce(changeValue, 500)

  const changeValue2 = (newValue) => {
    props.changeValue2(newValue)
  }
  const debounceChangeValue2 = debounce(changeValue2, 500)

  const changeOperation = (newOperation) => {
    setOperationValueType(changeOperationsValue(newOperation));
    props.changeOperation(newOperation)
  }

  function changeOperationsValue(newOperation){
    if(
      newOperation === 'is blank' ||
      newOperation === 'is not blank' ||
      newOperation === 'is a number' ||
      newOperation === 'is not a number'
    ){
      return 'none';
    }

    if(
      newOperation === 'is between' ||
      newOperation === 'is not between'
    ){
      return 'doubleText';
    }

    return 'text';
  }

  /**
   * Display
   */
  return(
    <View style={styles.row}>
      <View style={styles.column}>
        <FilterDropdown
          label={'Select Column'}
          value={props.rowData.column}
          list={createColumnList(props.columns)}
          onValueChange={changeColumn}
        />
      </View>

      <View style={styles.column}>
        <FilterDropdown
          label={'Operation'}
          value={props.rowData.operation}
          list={operationList}
          onValueChange={changeOperation}
        />
      </View>

      <View style={styles.column}>
        {operationValue(props.rowData.value)}
      </View>

    </View>
  )

}

const ConditionRowCreator = (props) => {

  /**
   * Changing Values
   */
  const changeColumn = (newValue) => {
    setValue('');
    props.onColumnSelection(newValue)
  }

  /**
   * Display
   */
  const [value, setValue] = React.useState('');

  return(
    <View style={styles.row}>
      <View style={styles.column}>
        <FilterDropdown
          label={'Select Column'}
          value={value}
          creator={true}
          list={createColumnList(props.columns)}
          onValueChange={newValue => changeColumn(newValue)}
        />
      </View>

      <View style={styles.column}>
        <TextInput
          label={''}
          value={''}
          editable={false}
        />
      </View>

      <View style={styles.column}>
        <TextInput
          label={''}
          value={''}
          editable={false}
        />
      </View>

    </View>
  )

}

const FilterDropdown = (props) => {

  const [visible, setVisible] = React.useState(false);
  const [value, setValue] = React.useState(props.value);

  return (
    <MultiDropDown
      label={props.label}
      mode={"flat"}
      multiSelect={false}
      visible={visible}
      showDropDown={() => setVisible(true)}
      onDismiss={() => setVisible(false)}
      value={value}
      setValue={(newVal) => {
        if(!props.creator){
          setValue(newVal)
        }
        setVisible(false)
        if(props.onValueChange){
          props.onValueChange(newVal)
        }
      }}
      list={props.list}
    />
  )

}


//Used for creating dropdown lists based on column headers
function createColumnList(columnArray){
  let list = [];
  columnArray.map(column => {
    list.push({label: column, value: column})
  })
  return list;
}




const styles = StyleSheet.create({
  button: {
    marginLeft: '10px',
  },
  row: {
    display:'flex',
    flexGrow: 10,
    flexDirection:'row',
    alignContent:'center',
    paddingTop:'20px'
  },
  column: {
    width:'32%',
    marginRight: '1%'
  }
});
