import * as React from 'react';
import {Pressable, Text, StyleSheet} from 'react-native';
import {View, Image} from "react-native-web";
import { Avatar } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import {useContext} from "react";
import {PowerforceContext} from "../../helpers/context";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Picker} from "@react-native-picker/picker";
import SimpleNav from "./SimpleNav";
import {Desktop, Tablet, Mobile } from '../../helpers/responsive'




const MultiNav = (props) => {

  //const powerforce = useContext(PowerforceContext);
  //const [companies, setCompanies] = React.useState(powerforce.companies ? powerforce.companies : []);

  const currentNav = () => {
    let routes = props.navigation.getState().routes;
    let lastRouteKey = routes.length - 1;
    let url = routes[lastRouteKey].name;
    let currentCompany = props.companies.find(x => url.includes(x.url))
    if(currentCompany === undefined){
      return '';
    }
    return currentCompany;
  }

  React.useEffect(() => {
    setSelectedCompany(currentNav())
  }, [props])

  const [selectedCompany, setSelectedCompany] = React.useState('');

  const navItems = (companyUrl) => {
    if(!companyUrl){
        return [];
    }
    if(companyUrl === 'eag-'){
      companyUrl = 'eag';
    }
    let result = props.navItems.filter((navItem) => navItem.route.includes(companyUrl + '-'));
    return result;
  }

  return (
    <View style={{flexDirection:"row"}}>
      <View style={{alignSelf:"center", maxWidth: '25%', minWidth: '140px'}}>
        <Picker
          selectedValue={selectedCompany ? selectedCompany.title : 0}
          style={styles.picker}
          nativeID={'topnav-select'}
          onValueChange={(itemValue, itemIndex) => {
            let company = props.companies.find(x => x.title === itemValue)
            setSelectedCompany(company)
          }}
        >
          <Picker.Item label={'Select a company'} value={''} key={'defaultoption'} />

          {props.companies.map(company => {
            return(<Picker.Item label={company.title} value={company.title} key={company.url} />)
          })}

        </Picker>
      </View>



      <SimpleNav navItems={navItems(selectedCompany ? selectedCompany.url : '')} navigation={props.navigation} />


    </View>
  )

}

export default MultiNav;

const styles = StyleSheet.create({
  logoContainer: {
    flex: 2,
    alignItems: "flex-start",
    justifyContent: 'left',
  },
  navItem: {
    textAlign:'center',
    padding: '10px',
    flex: 1
  },
  navBar: {
    flexDirection: "row",
    flex: 8,
    alignItems: "center"
  },
  userArea: {
    flex: 2,
    flexDirection: "row",
    justifyContent: 'right',
    alignItems: "center"
  },
  notifications: {
    paddingRight: '20px',
  },
  picker : {
    backgroundColor: 'rgb(0, 38, 77)',
    color: '#fff',
    padding: '10px',
    paddingRight: '0px',
    borderColor: 'white',
    marginLeft: '10px',
    minWidth: '140px'
  }
});
