import * as React from 'react';
import {Text, StyleSheet, View} from 'react-native';
import {ScrollView} from "react-native";
import { List } from 'react-native-paper';
import {PowerforceContext} from "../../helpers/context";
import {useContext} from "react";
import {useMediaQuery} from "react-responsive";


const SideNavigationBar = (props) => {

  const powerforce = useContext(PowerforceContext);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 776px)' })

  const styles = StyleSheet.create({
    sideNav: {
      backgroundColor: powerforce.colors.secondary,
      height: '100%',
      minHeight: isTabletOrMobile ? 'calc(100vh - 57px)' : '90vh',
      maxHeight: isTabletOrMobile ? 'calc(100vh - 57px)' : '90vh',
      maxWidth: isMobile ? '90%' : '250px',
      width: isMobile ? '350px' : 'auto',
      paddingTop: '10px'
    },
    accordion: {
      backgroundColor: powerforce.colors.primary,
      color: '#ffffff',
      //marginBottom: '10px'
    },
    accordionTitle: {
      color: '#ffffff',
    },
    listItem: {
      backgroundColor: powerforce.colors.primary,
    },
    listItemTitle: {
      paddingLeft: '20px',
      color: '#ffffff',
      fontSize: '14px'
    },
    bigListItem: {
      backgroundColor: powerforce.colors.primary,
      //marginBottom: '10px'
    },
    bigListItemTitle: {
      color: '#ffffff',
    }
  });


  /*let sideNavFakeValue = [
    {
      title: 'Something 1',
      moduleUrl: 'eag-admin',
      screenUrl: 'companies',
      subNav: []
    },
    {
      title: 'Something 2',
      moduleUrl: 'eag-admin',
      screenUrl: 'companies',
      subNav: []
    },
    {
      title: 'Dropdown',
      moduleUrl: null,
      screenUrl: null,
      subNav: [
        {
          title: 'Sub 1',
          moduleUrl: 'eag-admin',
          screenUrl: 'companies',
        },
        {
          title: 'Sub 2',
          moduleUrl: 'eag-admin',
          screenUrl: 'companies',
        },
      ]
    }
  ];*/

  if (!props.showSideMenu) {
    return null;
  }

  if (!props.sideNavItems) {
    return <Text>Loading...</Text>
  }

  if (props.sideNavItems.length > 1
      || (props.sideNavItems[0].subNav && props.sideNavItems[0].subNav.length !== 0)
  ) {

    return (
      <ScrollView style={styles.sideNav} dataSet={{'code':'sideNavBar'}}>

        {
          props.sideNavItems.map((navitem, index) => {
            //sideNavFakeValue.map((navitem, index) => {
            if ('subNav' in navitem && navitem.subNav.length > 0) {
              //Accordion
              return (
                <List.Accordion
                  title={navitem.title}
                  key={index}
                  id={index}
                  style={styles.accordion}
                  titleStyle={styles.accordionTitle}
                  theme={{colors: {text: '#ffffff'}}}
                  titleNumberOfLines={0}
                  dataSet={{'code':'sideNavBar-dropdown-'+navitem.title}}
                  nativeID={'sideNavBar-dropdown-'+navitem.title}
                  //expanded={true}
                >
                  {navitem.subNav.map((subNavItem, subIndex) => {
                    return (
                      <List.Item
                        key={index + '_' + subIndex}
                        title={subNavItem.title}
                        nativeID={'sideNavBar-subitem-'+subNavItem.screenUrl}
                        onPress={() => props.navigation.navigate(
                          subNavItem.moduleUrl,
                          {
                            screenName: subNavItem.screenUrl,
                            //recordID: undefined, //Trick to reload grid
                            //tableName: null,
                          }
                        )}
                        titleStyle={styles.listItemTitle}
                        style={styles.listItem}
                        titleNumberOfLines={0}
                      />
                    )
                  })

                  }
                </List.Accordion>
              )
            } else {
              // Not An Accordion
              return (
                <List.Item
                  key={index}
                  title={navitem.title ? navitem.title : navitem.name}
                  nativeID={'sideNavBar-'+navitem.screenUrl}
                  onPress={() => props.navigation.navigate(
                    navitem.moduleUrl,
                    {
                      screenName: navitem.screenUrl ? navitem.screenUrl : navitem.url,
                      //recordID: null, //Trick to reload grid
                      //tableName: null,
                    }
                  )}
                  titleStyle={styles.bigListItemTitle}
                  style={styles.bigListItem}
                  titleNumberOfLines={0}
                />
              )
            }

          })
        }

      </ScrollView>
    )
  }else{
    return <View></View>;
  }

}

export default SideNavigationBar;


