import {getData} from "../../helpers/api";
import {Image, StyleSheet, Text, View} from "react-native";
import Grid from "../../components/Grid/Grid";
import React from "react";
import {PowerforceContext} from "../../helpers/context";
import {ActivityIndicator, Divider, Button } from "react-native-paper";
import {IndividualRecord} from "../Grid/IndividualRecord/IndividualRecord";
import Constants from "expo-constants";
import PowerBiCustomEmbed from "../PowerBiEmbed/PowerBiCustomEmbed";
import SimpleCard from "../Card/SimpleCard";
import {ScreenEditor} from "../ScreenEditor/ScreenEditor";
import HierarchyManager from "../HierarchyManager/HierarchyManager";
import TextComponent from "../Text/TextComponent";
import ReportManager from "../ReportManager/ReportManager";
import * as WebBrowser from "expo-web-browser";
import VideoComponent from "../Video/VideoComponent";
import ButtonComponent from "../Button/ButtonComponent";


const webUrl = Constants.manifest.extra.webUrl;

export default function ScreenComponent(props) {

  const powerforce = React.useContext(PowerforceContext);

  const [data, setData] = React.useState(null);
  const [currentComponentDataUrl, setCurrentComponentDataUrl] = React.useState('');
  const [currentRouteParams, setCurrentRouteParams] = React.useState('');
  const [error, setError] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState('');

  const fetchComponentData = () => {

    //This if statement prevents multiple fetch calls when changing screens
    // and ensures that a grid is reloaded when revisting it from an individual record

    if(currentComponentDataUrl !== props.component.componentDataUrl //Screen to Screen
      //|| currentRouteParams !== props.route.params //For Ind Record to Record
    ){
      setData(null);
      setCurrentComponentDataUrl(props.component.componentDataUrl);
      setCurrentRouteParams(props.route.params);
      // Only get data if we are not looking at an individual record
      if(!props.route.params  && !props.route.params.recordID && !props.route.params.tableName)
      {
        getData(props.component.componentDataUrl, powerforce.authToken).then(fetchedData => {
          //console.log('--------fetchComponentData-------');
          //console.log('ScreenComponent fetchedData', fetchedData);
          //console.log('---------------------------------');

          if(fetchedData.status >= 400){
            setError(true)
            setErrorMessage(fetchedData.detail);

            if(fetchedData.status === 401){
              location.reload();
            }

          }

          setData(fetchedData)
        });
      }

    }
  }

  //Refetch when a comnponent changes or a route changes
  React.useEffect(() => {
    setError(false)
    setErrorMessage('');
    setData(props.component)
    //fetchComponentData()
  }, [props.component, props.route.params]);

  if(error){
    return (<Text>An error occurred.</Text>);
    return (
      <Text>ERROR: {errorMessage}</Text>
    )
  }

  if(!data){
    return (
      <ActivityIndicator animating={true} color={powerforce.colors.primary} />
    )
  }

  /** GRID **/
  if(props.component.type === 'grid'){

    //See if we are looking at a specific record
    if(props.route.params &&
      props.route.params.recordID &&
      props.route.params.tableName
    ){
      if(props.route.params.tableName === data.baseTable){
        const recordData = data.data.find(x => x.id === props.route.params.recordID)
        return (
          <IndividualRecord
            recordData={recordData}
            permission = {props.component.permission}
            gridCode = {data.code}
            recordId = {props.route.params.recordID}
            tableName={props.route.params.tableName}
            cloneRecord={props.route.params.cloneRecord}
          />
        )
      }else{
        return '';
      }

    }else{

      return (
        <Grid
          {...data}
          refresh={fetchComponentData}
          title = {props.component.title}
          permission = {props.component.permission}
          route = {props.route}
        />
      )
    }

    /** DATATABLE **/
  }else if(props.component.type === 'reportManager'){
    return (
      <ReportManager
        code = {props.component.code}
        title = {props.component.title}
        permission = {props.component.permission}
        companies = {props.component.companies}
      />
    )

    /** DATATABLE **/
  }else if(props.component.type === 'datatable'){
    /*return (
      <DataGrid
        code = {props.component.code}
        title = {props.component.title}
        permission = {props.component.permission}
      />
    )*/

    /** Text **/
  }else if(props.component.type === 'text'){
    return (
      <TextComponent
        style={{
          width: data.width ? data.width : '100%',
          fontSize: data.fontSize ? data.fontSize : '16px',
          fontStyle: data.fontStyle ? data.fontStyle : '',
          fontWeight: data.fontWeight ? data.fontWeight : 'normal',
          color: data.color ? data.color : '#000000',
          textAlign: data.textAlign ? data.textAlign : 'left',
          textTransform: data.textTransform ? data.textTransform : 'none',
          ...data.style
        }}
        text={data.text}
        nestedTextComponents={data.nestedTextComponents}
        code={ data.code }
      />
    )

    /** SPACE **/
  }else if(props.component.type === 'space'){
    return (
      <View style={{height:data.height,width:'100%'}} dataSet={{'code': data.code}}></View>
    )

    /** Hierarchy Manager **/
  }else if(props.component.type === 'hierarchy'){
    return (
      <HierarchyManager
        dataTable={ data.dataTable }
        hierarchyTable={ data.hierarchyTable }
        hierarchyKey={ data.hierarchyKey }
        title={ data.title }
        code={ data.code }
      />
    )

    /** ScreenEditor **/
  }else if(props.component.type === 'screenEditor'){

    if(data.modules){
      return (
        <ScreenEditor
          navigation={props.navigation}
          modules={data.modules}
          assets={data.assets}
          pluginsOpts={data.pluginsOpts}
          code={ data.code }
        /> )
    }else{
      return '';
    }



    /** POWER BI EMBED **/
  }else if(props.component.type === 'powerBiEmbed'){

    return (
      <PowerBiCustomEmbed
        embedUrl={data.embedUrl}
        accessToken={data.accessToken}
        type={data.pbiType}
        reportType={data.reportType}
        bookmarks={data.bookmarks}
        code={props.component.code}
        reportId={data.reportId}
        dashboardId={data.dashboardId}
        width={data.width}
        height={data.height}
        toggleSideMenu={props.toggleSideMenu}
        toggleTopMenu={props.toggleTopMenu}
        refreshData={data.refreshData}
      />
    )

    /** DIVIDER **/
  }else if(props.component.type === 'divider'){
    return (
      <Divider code={ data.code } style={{width:'100%', height:'5px', background:powerforce.colors.accent}} />
    )

    /** Image **/
  }else if(props.component.type === 'image'){
    return (
      <Image
        style={{
          width: data.width,
          height: data.height,
          maxWidth: '100%',
          marginBottom: '10px',
        }}
        code={ data.code }
        resizeMode={'contain'}
        source={{
          uri: data.uri,
        }}
      />
    )

    /** Button **/
  }else if(props.component.type === 'button'){

    return (
      <ButtonComponent
        data={data}
        navigation={props.navigation}
        code={ data.code }
      ></ButtonComponent>
    )

    /** Card **/
  }else if(props.component.type === 'card'){
    return (
      <SimpleCard
        data={data}
        navigation={props.navigation}
        code={ data.code }
      />
    )

  }else if(props.component.type === 'video'){
    return (
      <VideoComponent
        data={data}
        navigation={props.navigation}
        code={ data.code }
      />
    )

  }else{
    return (
      <Text>No Component Type Specified</Text>
    )
  }



}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //backgroundColor: '#000000',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px'
  },
});
