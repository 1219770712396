import DatePickerInput from "./DatePickerInput";
import React from "react";

export const DatePicker = React.forwardRef((props, ref) => {

  const [value, setValue] = React.useState(props.value);
  return (
    <DatePickerInput
      locale={props.locale}
      ref={ref}
      label={props.label}
      value={value ? new Date(value) : null}
      onChange={(d) => {
        console.log(d)
        if(props.editable){
          setValue(d);
          let newVal = d ? d.toISOString() : null;
          props.onChange(props.dataSource, newVal, props.sectionIndex);
        }
      }}
      inputMode="start"
      editable={props.editable}
    />
  )

})
