//import {Grid, GridLabel, GridMethods, GridStyles} from "./components/Grid";
import {Text, TextLabel, TextMethods} from "./components/Text";
import {Space, SpaceLabel, SpaceMethods} from "./components/Space";
import {Divider, DividerLabel, DividerMethods} from "./components/Divider";
import {Image, ImageLabel, ImageMethods} from "./components/Image";
import {PowerBiEmbed, PowerBiEmbedLabel, PowerBiEmbedMethods} from "./components/PowerBiEmbed";
import {Card, CardLabel, CardMethods} from "./components/Card";

import {BlankComponent, BlankComponentLabel, BlankComponentMethods} from "./components/BlankComponent";
import {deleteData} from "../../helpers/api";
import Piklor from "./helpers/colorPicker";
import React from 'react';

export const FoundationsPlugin = (editor, options) => {

  // Components

  //Grid - Block and Type
  editor.Components.addType('grid', BlankComponentMethods(options, editor));
  //editor.BlockManager.add('grid', {label: BlankComponentLabel, content: BlankComponent,})
  //editor.Components.addType('grid', BlankComponentMethods(options, editor));
  //editor.addStyle(GridStyles);

  //Text
  editor.BlockManager.add('text', {label: TextLabel, content: Text,})
  editor.Components.addType('text', TextMethods(options, editor));

  //Space
  editor.BlockManager.add('space', {label: SpaceLabel, content: Space,})
  editor.Components.addType('space', SpaceMethods(options, editor));

  //Divider
  editor.BlockManager.add('divider', {label: DividerLabel, content: Divider,})
  editor.Components.addType('divider', DividerMethods(options, editor));

  //Image - Block and Type
  editor.BlockManager.add('imageHolder', {label: ImageLabel, content: Image,})
  editor.Components.addType('imageHolder', ImageMethods(options, editor));

  //PowerBiEmbed - Block and Type
  editor.BlockManager.add('powerBiEmbed', {label: PowerBiEmbedLabel, content: PowerBiEmbed,})
  editor.DomComponents.addType('powerBiEmbed', PowerBiEmbedMethods(options, editor));

  //Card
  editor.BlockManager.add('card', {label: CardLabel, content: Card,})
  editor.Components.addType('card', CardMethods(options, editor));

  //Screeneditor
  editor.Components.addType('screenEditor', BlankComponentMethods(options, editor));

  //Hierarchy
  editor.Components.addType('hierarchy', BlankComponentMethods(options, editor));



  //Add Roles to all Components
  //console.log(editor.getComponents());

  const um = editor.UndoManager;
  editor.on('update', (some) => {
    console.log(um.getStack());
  })

  //This makes sure no IDs are cloned
  editor.on('component:clone', (newModel) => {
    newModel.removeAttributes(['componentId', 'adminComponentId']);
  });

  //Update event - could be useful later
  /*editor.on('component:update', (newModel) => {
    console.log(newModel);
  });*/

  // When adding a new component, add the user's roles to the component
  editor.on('component:add', (model) => {
    // Auto add user's roles when creating a new component
    if(model.attributes.attributes.componentId === 'add'){
      addRoleDefaultValuesToNewComponent(options.userRoles, model)
    }
  });

  //Asset Manager
  // We are passed our authtoken from ScreenEditor
  editor.on('asset:remove', (asset) => {
    console.log(asset);
    console.log(options);
    deleteData('/data/media', options.powerforce.authToken, asset)
      .then((response) => {
        console.log(response);
      });
  })

  //add asset
  editor.on('asset:add', (asset) => {
    console.log(asset);
    console.log(options);
  })

  /*** RTE ***/
  // When mounting or deslecting a subtext component (using the RTE)
  // make sure it is not selectable or draggable

  // Function to make sure text components children are not badgeable or selecteable
  function removeSubTextEditConfigs(component){
    component.attributes.components.models.forEach((model) => {
      model.set({
        badgable: false,
        draggable: false,
        highlightable: false,
        hoverable: false,
        selectable: false,
      })
      if(model.attributes.type === 'font'){
        console.log(model);
      }
      model.attributes.components.models.forEach((subModel) => {
        //console.log('submodel', subModel)
        subModel.set({
          badgable: false,
          draggable: false,
          highlightable: false,
          hoverable: false,
          selectable: false,
        })
        removeSubTextEditConfigs(subModel)
      });
    })
  }

  editor.on('component:mount', (component) => {
    // Check if mounted component is a 'text' component
    if (component.attributes.tagName === 'div' && component.attributes.type === 'text') {
      removeSubTextEditConfigs(component);
    }
  })

  editor.on('component:deselected', (component) => {
    // Check if mounted component is a 'text' component
    if (component.attributes.tagName === 'div' && component.attributes.type === 'text') {
      removeSubTextEditConfigs(component);
    }
  });

  // https://grapesjs.com/docs/api/rich_text_editor.html#add
  const rte = editor.RichTextEditor;
  let colorPicker = null;
  const colors = [
    options.powerforce.colors.primary,
    options.powerforce.colors.secondary,
    options.powerforce.colors.accent,
    "#67c8f2",
    "#b0b0b0",
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#16a085",
    "#27ae60",
    "#2980b9",
    "#8e44ad",
    "#2c3e50",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
    "#ecf0f1",
    "#95a5a6",
    "#f39c12",
    "#d35400",
    "#c0392b",
    "#bdc3c7",
    "#7f8c8d"
  ];
  rte.add('fontColor', {
    icon: `<b style="pointer-events:none;border-bottom:2px solid">A</b>
        <div id="foreColor-picker" class="rte-color-picker dark"></div>
    `,
    attributes: {
      id: 'rte-font-color',
      title: 'Font Color'
    },
    result: (rte, action) => {
      if (!colorPicker) colorPicker = new Piklor("#foreColor-picker", colors, {
        open: "span#rte-font-color.gjs-rte-action",
        closeOnBlur: true
      });
      colorPicker.colorChosen(col => rte.exec('foreColor', col));
    },
  })


  /*** Keymaps ***/

  const keymaps = editor.Keymaps;
  console.log(keymaps);
  console.log(keymaps.getAll());
  keymaps.add('ns:my-keymap', 'ctrl+v', editor => {
    console.log('do stuff');
  });

  editor.on('keymap:emit', (id, shortcut, e) => {
    console.log(id, shortcut, e);
  });


  // Only allow plaintext to be pasted
  editor.on('rte:enable', (rte) => {
    console.log('rte:enable', rte);
    rte.el.addEventListener("paste", function(e){
      console.log('PASTE', e);
      e.preventDefault();
      console.log(document);
      console.log(editor.Canvas.getDocument());
      let text = e.clipboardData.getData("text/plain");
      editor.Canvas.getDocument().execCommand('insertText', false, text);

    });
  });

  function getCaretPosition(editableDiv) {
    var caretPos = 0,
      sel, range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode == editableDiv) {
          caretPos = range.endOffset;
        }
      }
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      if (range.parentElement() == editableDiv) {
        var tempEl = document.createElement("span");
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        var tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint("EndToEnd", range);
        caretPos = tempRange.text.length;
      }
    }
    return caretPos;
  }

  editor.on('rte:disable', (rte) => {
    console.log('rte:disable', rte);
    //rte.el.removeEventListener("paste", pastePlainText);
  });

}

export function addRoleCheckboxes(roles, config){

  for(let i = 0; roles.length > i; i++){
    let roleCheckBoxConfig = {
      type: 'checkbox',
      label: roles[i],
      name: roles[i],
    }
    config.model.defaults.traits.push(roleCheckBoxConfig)

  }

  return config;

}

export function addRoleDefaultValues(userRoles, config){
  console.log(userRoles);

  for(let i = 0; userRoles.length > i; i++){
    let roleKey = userRoles[i];
    console.log(roleKey);
    console.log(userRoles[i]);
    config.model.defaults.attributes[roleKey] = true;
  }

  for(const role in userRoles){
    console.log(role);
    console.log(userRoles[role]);

    let roleKey = userRoles[role];
    config.model.defaults.attributes[roleKey] = true;
  }

  console.log(config);
  console.log(config.model.defaults.attributes);
  return config;
}

export function addRoleDefaultValuesToNewComponent(userRoles, model){

  for(const role in userRoles){
    let roleKey = userRoles[role];
    model.attributes.attributes[roleKey] = true;
  }

  return model;
}
