import * as React from 'react';
import { Modal, Portal, Text, Button, Provider, TextInput } from 'react-native-paper';
import {useContext} from "react";
import {PowerforceContext} from "../../helpers/context";
import {Feather} from "@expo/vector-icons";
import {Pressable} from "react-native";

const BookmarkModal = (props) => {

  const powerforce = useContext(PowerforceContext);

  const containerStyle = {backgroundColor: 'white', padding: 20, zIndex: 999, margin: '20px', alignSelf: 'center'};

  const [text, setText] = React.useState("");
  const [saving, setSaving] = React.useState(false);

  // Reset text on modal close/open
  React.useEffect(() => { setText(''); setSaving(false); }, [props.visible])

  function save(){
    props.onSave(text);
    setSaving(true)
    setText('Saving ' + text + ' ...');
  }

  return (
    <Provider >
      <Portal >
        <Modal
          visible={props.visible}
          onDismiss={props.onDismiss}
          contentContainerStyle={containerStyle}
          style={{zIndex: 999}}
        >
          <Pressable
            onPress={props.onDismiss}
            style={{marginLeft:"auto"}}
          >
            <Feather name={'x'} size={20} color={'gray'}  />
          </Pressable>
          <Text style={{color:'black'}}>Enter a name for this view:</Text>
          <TextInput
            label=""
            value={text}
            onChangeText={text => setText(text)}
            placeholder={'Example: Q4 Projections'}
            activeUnderlineColor={powerforce.colors.primary}
            style={{marginVertical: '20px', minWidth: '300px'}}
          />
          <Button
            icon="content-save"
            mode="contained"
            onPress={save}
            disabled={saving}
            loading={saving}
            color={powerforce.colors.primary}
            labelStyle={saving ? {'color':'grey'} : {'color':'white'}}
          >
            {saving ? 'Saving View' : 'Save View'}
          </Button>
        </Modal>
      </Portal>
    </Provider>
  );
};

export default BookmarkModal;
