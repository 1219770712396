import * as React from 'react';
import { Modal, Portal, Text, Button, Provider, TextInput } from 'react-native-paper';
import {useContext} from "react";
import {PowerforceContext} from "../../helpers/context";
import {Pressable, View} from "react-native";
import {Feather} from "@expo/vector-icons";

const InfoModal = (props) => {

  const powerforce = useContext(PowerforceContext);

  const containerStyle = {
    backgroundColor: 'white',
    padding: 20,
    paddingBottom: 40,
    zIndex: 999,
    margin: '20px',
    alignSelf: 'center'
  };



  const LastRefreshInfo = () => {

    if(props.refreshData !== null && props.refreshData.value !== undefined && props.refreshData.value.length !== 0){
      let date = new Date(props.refreshData.value[0].startTime);
      let refreshValue = date.toLocaleString("en-US", {timeZoneName: "short"});
      return(
        <View>
          <Text style={{paddingBottom:'10px', color: "black"}}>Latest Refresh: {refreshValue}</Text>
          <Text style={{paddingBottom:'10px', color: "black"}}>Status: {props.refreshData.value[0].status}</Text>
        </View>
      )
    }else{
      return (
        <Text style={{paddingBottom:'10px', color: "black"}}>No extra data available.</Text>
      )
    }
  }

  return (
    <Provider >
      <Portal >
        <Modal
          visible={props.visible}
          onDismiss={props.onDismiss}
          contentContainerStyle={containerStyle}
          style={{zIndex: 999}}
        >
          <Pressable
            onPress={props.onDismiss}
            style={{marginLeft:"auto", paddingBottom:'20px'}}
          >
            <Feather name={'x'} size={20} color={'gray'}  />
          </Pressable>
          <LastRefreshInfo />
        </Modal>
      </Portal>
    </Provider>
  );
};

export default InfoModal;
