import {addRoleCheckboxes, addRoleDefaultValues, addRoleDefaultValuesToNewComponent} from "../FoundationsPlugin";

const script = function() {

  //console.log('### Image Script ###');

}

export const ImageMethods = (options, editor) => {

  console.log(options);

  let config = {
    // Model definition
    model: {
      // Default properties
      defaults: {
        script,
        tagName: 'imageHolder', //change to IMG?
        droppable: false, // Can't drop other elements inside
        attributes: { // Default attributes
          width: '25%',
          height: '25vh',
          componentId: 'add',
        },
        traits: [
          {
            type: 'text',
            label: 'Width',
            name: 'width',
          },
          {
            type: 'text',
            label: 'Height',
            name: 'height',
          },
          {
            type: 'text',
            label: 'URI',
            name: 'src',
          },
        ],

      },
      updated(property, value, prevValue) {
        console.log('updated', property, value, prevValue);
        if (property === 'traits') {
          console.log(this.views);
          this.views[0].el.style.width = value.target.attributes.attributes.width;
          this.views[0].el.style.height = value.target.attributes.attributes.height;

        }
      },

    },
    view: {
      updated(property, value, prevValue) {
        //console.log('updateView', property, value, prevValue);
      }
    }
  }

  if(options.editorType === 'full'){

    //Add role checkboxes
    config = addRoleCheckboxes(options.roles, config);

  }


  editor.on('component:update', (newModel) => {

    //Make sure this only fires on 'imageHolders'
    if(newModel.parent() && newModel.parent().attributes.tagName === "imageHolder"){

      //For saving change the parent element trait
      let parent = newModel.parent();
      let parentAtts = parent.getAttributes();
      parentAtts.src = newModel.changed.src;
      parent.setAttributes({ ...parentAtts })

      //For viewing, change the divs backgroundimage
      newModel.view.$el[0].parentElement.children[0].style.backgroundImage = 'url(' + newModel.changed.src + ')';

      //For saving change the parent element trait
      //newModel.view.$el[0].parentElement.setAttribute('src', newModel.changed.src);
    }
  });

  return config;
}



/* Label for sidebar*/

export const ImageLabel = '<div style="font-size:20px;">' +
  '<div><b>Image</b></div>' +
  '<i class="fa fa-picture-o" aria-hidden="true" style="padding-top:10px"></i></div>'

/* Component structure */
export const Image = () => {

  let json = {
    "type": "imageHolder",
    "draggable": true,
    "editable": true,
    "selectable": true,
    "classes": [
      "basic-image"
    ],
    "attributes": {
      "width": "25%",
      "height": "25vh",
      "src": "/media/smartsheet.png",
      "style": "overflow:hidden;width:25%;height:25vh;position: relative;min-height: 10px;"
    },
    "components": [
      {
        "draggable": false,
        "droppable": false,
        "highlightable": false,
        "selectable": false,
        "hoverable": false,
        "attributes": {
          "style": "z-index:1;width:100%;height:100%;background-size:contain;background-image:url('/media/smartsheet.png');background-position: center center;background-repeat: no-repeat;",
        },
      },
      {
        "type": "image",
        "draggable": false,
        "editable": true,
        "selectable": false,
        "resizable": false,
        "attributes": {
          "src": "/media/smartsheet.png",
          "style": "position:absolute;top:0px;left:0px;height:100%;width:100%;opacity:0;z-index:2;"
        },
      }
    ]
  };

  return json;


}
