import {StyleSheet, Text, View} from "react-native";
import {TextInput as TextInputNormal} from "react-native";
import {ActivityIndicator, Button, Dialog, Paragraph, Portal, TextInput} from "react-native-paper";
import React from "react";
import {PowerforceContext} from "../../../helpers/context";
import {getData, patchData, postData} from "../../../helpers/api";
import {useNavigation} from "@react-navigation/native";
import {en, registerTranslation} from 'react-native-paper-dates';
import {Dropdown} from "./Dropdown";
import CheckboxInput, {Checkbox} from "./Checkbox";
import {randomKey} from "../../../helpers/general";
import {DatePicker} from "./DatePicker";
import {debounce} from "lodash";
import {Autocomplete} from "./Autocomplete";
import {RelationTable} from "./RelationTable";
import {AccordionList, List} from "./List";
import { MaskedTextInput, unMask, mask } from "react-native-mask-text";
import { isEqual } from "lodash";

registerTranslation('en', en);


export const IndividualRecord = (props) => {

  const powerforce = React.useContext(PowerforceContext);

  //Style that use pf globals
  const styles = StyleSheet.create({
    header : {
      display: 'flex',
      flexDirection: 'row',
      justifyItems: 'center',
      alignItems: 'center'
    },
    titleText : {
      fontSize: '2em',
      marginRight: '30px'
    },
    sectionHeader: {
      backgroundColor: powerforce.colors.primary,
      padding: '15px',
      marginBottom: '10px',
      marginTop: '30px',
      flexGrow: 1,
      width: '99%',
      marginRight: '1%'
    },
    sectionHeaderText : {
      color: '#ffffff',
    },
    section : {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: "wrap"
    },
    saveSection : {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '50px'
    },
    inputContainer : {
      flexGrow: 1,
      width: '32%',
      marginRight: '1%',
      marginBottom: '15px',
      position:"relative",
      zIndex: 1,
    },
    fullwidthInputContainer : {
      flexGrow: 1,
      width: '100%',
      marginRight: '1%',
      marginBottom: '15px',
      position:"relative",
      zIndex: 1,
    },
    checkBoxContainer:{
      display: 'flex',
      justifyContent: 'start',
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%'
    },
    primaryButton : {
      backgroundColor: powerforce.colors.primary,
      width: '32%',
      marginRight: '1%'
    },
    secondaryButton : {
      backgroundColor: powerforce.colors.secondary,
      width: '32%',
      marginRight: '1%'
    },
    disabledButton : {
      backgroundColor: '#959595',
      width: '32%',
      marginRight: '1%'
    },
    dangerButton : {
      backgroundColor: '#ff0000',
    },
    dangerText : {
      color: '#ff0000',
      fontSize: '24px',
    },
    snackbarContainer : {
      zIndex: 9999,
      //position: 'relative',
      //top: '30%',
      //width: '100%'
    },
    snackbar:{
      backgroundColor: powerforce.colors.secondary,
      color: '#ffffff',
      zIndex: 9999,
      padding: '30px'
    },
    maskedInput: {
      height: 40,
      margin: 12,
      borderWidth: 1,
    },
  });

  const sectionContainer = {
      flexGrow: 1,
      maxWidth: '32%',
      width: '32%',
      marginRight: '1%',
      marginBottom: '15px',
      position:"relative",
      zIndex: 1,
  }
  const dropdownSectionContainer = {
    flexGrow: 1,
    maxWidth: '32%',
    width: '32%',
    marginRight: '1%',
    marginBottom: '15px',
    position:"relative",
    zIndex: 1,
    //paddingRight: '10px'
  }


  //Fetch Ind Record Data and create based on information
  // User route params to fetch correct data
  const navigation = useNavigation();
  const [data, setData] = React.useState(null)
  const [structure, setStructure] = React.useState(null);
  const [update, setUpdate] = React.useState({})

  const [fetchError, setFetchError] = React.useState(false)
  const [errorText, setErrorText] = React.useState('Error')

  //Buttons
  const [showSaveButton, setShowSaveButton] = React.useState(false)
  const [showAfterSaveActionButton, setShowAfterSaveActionButton] = React.useState(false)
  const [showCancelButton, setShowCancelButton] = React.useState(false)

  const [saveButtonText, setSaveButtonText] = React.useState('')
  const [afterSaveActionButtonText, setAfterSaveActionButtonText] = React.useState('')
  const [cancelButtonText, setCancelButtonText] = React.useState('')

  //Cancel Popup
  const [isCancelPopupVisible, setIsCancelPopupVisible] = React.useState(false);
  const showPopup = () => setIsCancelPopupVisible(true);
  const hidePopup = () => setIsCancelPopupVisible(false);

  //Termination Popup
  const [isTermPopupVisible, setIsTermPopupVisible] = React.useState(false);
  const showTermPopup = () => setIsTermPopupVisible(true);
  const hideTermPopup = () => setIsTermPopupVisible(false);

  const [isSaving, setIsSaving] = React.useState(false)

  //In case we need to refresh the data
  const [reloadCount, setReloadCount] = React.useState(1)

  //State for banner
  const [snackbarVisible, setSnackbarVisible] = React.useState(false);
  const [snackbarReloadOnClose, setSnackbarReloadOnClose] = React.useState(false);
  const [snackbarReloadOnCloseRecordId, setSnackbarReloadOnCloseRecordId] = React.useState(null);
  const [snackbarText, setSnackbarText] = React.useState('Snackbar Text');
  const onDismissSnackBar = () => {
    setSnackbarVisible(false);

    if(snackbarReloadOnClose){
      navigation.setParams({
        recordID : snackbarReloadOnCloseRecordId,
        tableName : props.tableName,
      })

      let currentCount = reloadCount;
      currentCount++;
      setReloadCount(currentCount);
    }

  };

  const fieldRefs = React.useRef([]);

  React.useEffect(() => {

    let recordId = props.recordId;
    if(props.recordId === 'add'){
      recordId = 0;
    }

    //Set data and structure to null so spinner shows
    setData(null)
    setStructure(null);
    setFetchError(false);

    getData('/data/record/'+props.gridCode+'/'+recordId, powerforce.authToken)
      .then(fetchedData => {
        console.log(fetchedData);

        if(fetchedData.status >= 400){
          //setError(true)
          //setErrorMessage(fetchedData.detail);
          console.log('error');
          setFetchError(true);
          setErrorText(fetchedData.detail);
        }

        //If we are cloning add the data to update & change title
        if(props.cloneRecord && props.cloneRecord !== 'null'){
          fetchedData.record = extractDataForClone(fetchedData);
          setUpdate(fetchedData.record)
          fetchedData.structure.title = 'Clone Record';
        }

        //Add default value to update
        if(props.recordId === 'add'){
          fetchedData = extractDefaultDataForAddUpdateState(fetchedData);
        }

        //Set state
        setData(fetchedData.record)
        setStructure(fetchedData.structure);
        setShowSaveButton(fetchedData.showSaveButton);
        setShowAfterSaveActionButton(fetchedData.showAfterSaveActionButton);
        setShowCancelButton(fetchedData.showCancelButton);
        setSaveButtonText(fetchedData.saveButtonText);
        setAfterSaveActionButtonText(fetchedData.afterSaveActionButtonText);
        setCancelButtonText(fetchedData.cancelButtonText);

      });


  }, [reloadCount]);

  function extractDataForClone(fetchedData){
    let newUpdate = {};
    fetchedData.structure.sections.map(section => {
      // If there are no exclusions
      if(!fetchedData.cloneExclusions){
        newUpdate[section.dataSource] = fetchedData.record[section.dataSource];
      }else if(section.dataSource && !fetchedData.cloneExclusions.includes(section.dataSource)){
        // If there are exclusions, make sure the exclusions do not get included
        newUpdate[section.dataSource] = fetchedData.record[section.dataSource];
      }
    })
    return newUpdate;
  }

  function extractDefaultDataForAddUpdateState(fetchedData){
    let fetchedDataWithDefualtValues = {...fetchedData};
    let newUpdate = update;
    fetchedData.structure.sections.map(section => {
      if(section.defaultValue){
        fetchedDataWithDefualtValues.record[section.dataSource] = section.defaultValue;
        newUpdate[section.dataSource] = section.defaultValue;
      }
    })
    setUpdate(newUpdate);
    return fetchedDataWithDefualtValues;
  }

  /**
  * When Records are changed
  * */

  const changeRecordItem = (itemName, newValue, index = null, forceRefresh = false) => {

    let newUpdate = update;
    newUpdate[itemName] = newValue;
    setUpdate(newUpdate);

    let newData = data;
    newData[itemName] = newValue;
    setData(newData);
    let dataBeforeCalcs = {...data};

    //Apply Calculations
    applyCalculations(itemName, newValue);

    //Check conditions
    let conditionChange = checkConditionsOnUpdate(itemName, newValue);
    //If there's a difference in data after applying calculations
    // Or there is a condition change
    // then we need to rerender
    let dataAfterCalcs = { ...data };
    if(!isEqual(dataBeforeCalcs, dataAfterCalcs) || checkConditionsOnUpdate(itemName, newValue)){

      setData({...dataAfterCalcs});

      //Set focus back to field, or to next field
      if(index){
        if(fieldRefs.current[index]){
          setTimeout(() => fieldRefs.current[index].focus(), 250);
        }else{
          let nextIndex = findNextHighestIndex(index, fieldRefs.current);
          if(nextIndex){
            setTimeout(() => fieldRefs.current[nextIndex].focus(), 250);
          }
        }
      }

    }
    //If we need to force a refresh - like in a relation table
    if(forceRefresh){
      setData({...dataAfterCalcs});
    }


  }

  const debounceChangeRecordItem = debounce(changeRecordItem, 300);

  function findNextHighestIndex(passedIndex, array){
    let returnedIndex = false;
    for(const [i, value] of array.entries()){
      if(i > passedIndex && value){
        returnedIndex = i;
        break;
      }
    }
    return returnedIndex;
  }

  /**
  * Apply Calculations on Value Change
  * */

  function applyCalculations(itemName, newValue){
    for(let i = 0; i < structure.sections.length ; i++){
      if(structure.sections[i].calculateFunction) {
        //If there's a section with calculate, rerender and end
        let calc = calculate(structure.sections[i]);
      }
    }
  }

  /**
  * Condition Checker on Value Change
  * */

  function checkConditionsOnUpdate(itemName, newValue){

    let conditionChange = false;
    structure.sections.map((section) => {

      //See if it has all condition args
      if(section.conditionKey && section.conditionOp){

        //Check if the conditionKey is equal to the update key
        if(section.conditionKey === itemName){

          conditionChange = true;

        }
      }
    })
    return conditionChange;

  }

  /**
   * Check if required fields are filled
   * */
  function checkRequiredFieldsAreComplete(){
    console.log('Checking required fields');
    // Get required objects based off structure array
    const requiredObjects = structure.sections.filter((item) => item.required);
    // Returns true if every item in the array return true
    // Also checks against 'unrequiredItems'
    return requiredObjects.every((item) => {
      console.log(item);
      console.log(update[item.dataSource]);
      console.log(data[item.dataSource]);

      if(update[item.dataSource] !== undefined || data[item.dataSource] !== undefined){
        return true;
      }

      if(update[item.dataSource] === undefined
        && data[item.dataSource] === undefined
      ){
        if(unrequiredItems.includes(item.dataSource)){
          return true;
        }
      }

        return false;
    })
  }

  function getUnfilledRequiredFields(format = 'array'){
    const requiredObjects = structure.sections.filter((item) => item.required);
    let unfilledFieldsArray = [];
    let unfilledFieldsString = '';
    requiredObjects.map((item) => {
      if(update[item.dataSource] === undefined && data[item.dataSource] === undefined){
        if( ! unrequiredItems.includes(item.dataSource)){
          unfilledFieldsArray.push(item);
          unfilledFieldsString += item.label + ', ';
        }
      }
    })

    if(format === 'array'){
      return unfilledFieldsArray;
    }

    if(format === 'string'){
      return unfilledFieldsString;
    }

  }

  /**
  * Bottom Buttons - SAVE AND CANCEL
  * */

  //Save Changes and generate workflows
  const saveChanges = (doAftersave) => {

    // CHeck required fields
    if( ! checkRequiredFieldsAreComplete() ){
      alert('Please complete all required fields: ' + getUnfilledRequiredFields('string'));
      return;
    }

    setIsSaving(true);
    console.log('SAVE CHANGES!', structure.afterSaveAction, update);

    let afterSaveAction = null;
    if(doAftersave){
      console.log('Doing aftersave action: '+structure.afterSaveAction);
      afterSaveAction = structure.afterSaveAction;
    }

    if(props.recordId === 'add' || props.cloneRecord){

      postData(
        '/data/record/'+props.tableName+'/0',
        powerforce.authToken,
        {
          afterSaveAction: afterSaveAction,
          afterSaveActionData: update,
          gridCode: props.gridCode
        }
      ).then(response => {
        setIsSaving(false);

        if(response.status > 200){

          if(response.extra !== undefined && response.extra.recordId !== undefined){
            setSnackbarText('The record was created but there was an error generating workflows. This page will direct you to the new record after closing this popup. Error Details: ' + response.detail);
            setSnackbarReloadOnClose(true);
            setSnackbarReloadOnCloseRecordId(response.extra.recordId);
          }else{
            if(response.trace !== undefined){
              setSnackbarText('The record was created but there was an error generating workflows. Error Details: ' + response.detail + ' | TRACE file: ' + response.trace[0].file + ' --- line:' + response.trace[0].line);
            }else{
              setSnackbarText('The record was created but there was an error generating workflows. Error Details: ' + response.detail);
            }

          }

          setSnackbarVisible(true);

        }else{

          navigation.setParams({
            recordID : response.extra.recordId,
            tableName : props.tableName,
            //cloneRecord: null
          })

          let currentCount = reloadCount;
          currentCount++;
          setReloadCount(currentCount);

        }

      })

    }else{

      patchData(
        '/data/record/'+props.tableName+'/'+props.recordId,
        powerforce.authToken,
        {
          afterSaveAction: afterSaveAction,
          afterSaveActionData: update,
          gridCode: props.gridCode
        }
      ).then(response => {
        setIsSaving(false);

        if(response.status > 200){
          setSnackbarText(response.detail);
          setSnackbarVisible(true);
        }else{

          if(doAftersave){
            let currentCount = reloadCount;
            currentCount++;
            setReloadCount(currentCount);
          }else{
            navigation.setParams({
              recordID : null,
              tableName : null,
            })
          }

        }

      })
    }

  }

  //Cancel
  const cancelChanges = () => {
    navigation.setParams({
      recordID : null,
      tableName : null,
    })
  }


  /**
  * QA Bar Factory
  * */
  const createQuickAccessBarItem = (barItem) => {
    if(barItem.type === 'button'){
      return <Button
        key={randomKey(16)}
        icon={barItem.icon}
        mode={barItem.mode}
        onPress={() => console.log(barItem.onPress)}>
        {barItem.text}
      </Button>;
    }

    if(barItem.type === 'dropdownnn'){
      return <Button
        key={randomKey(16)}
        icon={barItem.icon}
        mode={barItem.mode}
        onPress={() => console.log(barItem.onPress)}>
        {barItem.text}
      </Button>;
    }


  }

  /**
  * Conditions
  * */

  const applyEqualsCondition = (conditionKey, conditionValue) => {

    if(data[conditionKey] == conditionValue){
      return true;
    }else{
      return false;
    }

  }

  const applyDoesNotEqualCondition = (conditionKey, conditionValue) => {

    if(data[conditionKey] != conditionValue){
      return true;
    }else{
      return false;
    }

  }

  const conditionOperators = {
    '=': applyEqualsCondition,
    '!=': applyDoesNotEqualCondition
  }

  /**
  * Calculations
  * All Calculate functions manipulate data and  must return the section
  * */


  const calculate = (section) => {

    let sectionClone = { ...section };
    let functionName = section.calculateFunction;

    if(typeof calculateFunctions[functionName] === 'function' ){
      return calculateFunctions[functionName](sectionClone);
    }else{
      console.log('function does not exist');
      return section;
    }

  }

  //Show related DropDown values
  const [relatedDropdownItems, setRelatedDropdownItems] = React.useState({})
  function onlyShowRelatedDropdownItems(section){

    let relatedValue = data[section.calculateArgs];

    if(!(section.dataSource in relatedDropdownItems)){
      let relatedDropdownItemsClone = { ...relatedDropdownItems };
      relatedDropdownItemsClone[section.dataSource] = [];
      setRelatedDropdownItems(relatedDropdownItemsClone);
    }

    let relatedDropdownItemsClone = { ...relatedDropdownItems };

    let newItems = section.items.filter(item => {
      return item[section.calculateArgs] === relatedValue;
    })

    relatedDropdownItemsClone[section.dataSource] = newItems;

    //CHeck if we need to rerender
    if(!isEqual(relatedDropdownItemsClone, relatedDropdownItems)){
      setRelatedDropdownItems(relatedDropdownItemsClone);
    }

    //Set section items and return
    section.items = newItems;
    return section;
  }


  //Set a date to not be before another date
  function notBeforeDate(section){
    let notBeforeThisDate = new Date(data[section.calculateArgs]);
    let valueDate = new Date(data[section.dataSource]);

    //Make sure we have a value here to prevent infinite re-renders
    if(data[section.dataSource]){
      if(valueDate <= notBeforeThisDate){
        let humanReadableLabel = section.calculateArgs.replace(/([A-Z])/g, ' $1').trim();
        humanReadableLabel = humanReadableLabel[0].toUpperCase() + humanReadableLabel.substring(1);
        setSnackbarText('Can not set ' + section.label + ' before ' + humanReadableLabel);
        setSnackbarVisible(true);

        let newData = data;
        newData[section.dataSource] = null;
        setData(newData);

        let newUpdate = update;
        delete newUpdate[section.dataSource];
        setUpdate(newUpdate);
      }
    }

    return section;
  }


  //Don't require this field if there's no equipment requirements
  const [unrequiredItems, setUnequiredItems] = React.useState([])

  //Add hidden value to record, must actually exist in record
  function addHiddenValuesToRecord(section){
    let sectionClone = { ...section };
    let newData = data;
    let newUpdate = update;

    if(!isEqual(newData[section.calculateArgs], JSON.parse(section.calculateMeta))){
      newData[section.calculateArgs] = JSON.parse(section.calculateMeta);
      newUpdate[section.calculateArgs] = JSON.parse(section.calculateMeta);
      setData(newData);
      setUpdate(newUpdate);
    }
    return sectionClone;
  }

  const calculateFunctions = {
    'onlyShowRelatedDropdownItems': onlyShowRelatedDropdownItems,
    'notBeforeDate': notBeforeDate,
    'addHiddenValuesToRecord': addHiddenValuesToRecord,
  }


  /**
  * Section Factory
  * */
  function applyStyles(section, styleObject){
    let styleObjectClone = {...styleObject}
    if(section.width){
      styleObjectClone.maxWidth = section.width;
      styleObjectClone.width = section.width;
    }

    if(section.required){
      styleObjectClone.borderLeft = '2px solid ' + powerforce.colors.accent;
    }

    return styleObjectClone;
  }

  const createSection = (originalSection, index) => {

    //Trick to clone and keep original structure
    let section = { ...originalSection};

    //Value & Default Value
    let value = '';
    if(section.defaultValue){
      value = section.defaultValue;
    }

    //If we have data in the datasource, use it
    if(data[section.dataSource] !== null && data[section.dataSource] !== undefined){
      value = data[section.dataSource];
    }

    //Alter Label for required fields
    if(section.required){
      section.label = 'Required: ' + section.label;
    }

    //Calculations
    if(section.calculateFunction) {
      section = calculate(section);
    }

    /*
     Conditions
     */

    //Create Only - Only when adding a record
    if((props.recordId !== 'add' && (props.cloneRecord !== 'true' && props.cloneRecord !== true))
      && section.conditionKey === 'createOnly'
    ){
      return;
    }

    //Write only - AKA only show when editing
    if((props.recordId === 'add' || props.cloneRecord === 'true') && section.conditionKey === 'updateOnly'){
      return;
    }

    if(props.cloneRecord && props.cloneRecord !== 'null' && section.conditionKey === 'updateOnly'){
      return;
    }

    //If condition exists
    if(section.conditionKey && section.conditionOp){
      //If condition is true, show field, if not don't show
      if(!conditionOperators[section.conditionOp](section.conditionKey, section.conditionValue)){
        return;
      }
    }

    //Permissions
    let editable = false;
    if(props.permission === 'write'){
      editable = true;
    }
    if(props.permission === 'read' && section.writeOnly){
      return;
    }

    //TITLE BAR
    if(section.type === 'titleBar'){
      return <View key={randomKey(16)} style={styles.sectionHeader}>
        <Text style={styles.sectionHeaderText}>{section.text}</Text>
      </View>
    }

    //SPACE
    if(section.type === 'space'){
      return <View
        key={randomKey(16)}
        style={applyStyles(section, sectionContainer)}
      >
      </View>
    }

    //BUTTON
    if(section.type === 'button'){
      return <View key={randomKey(16)} style={applyStyles(section, sectionContainer)}>
        <Button
          icon={section.icon}
          mode={section.mode}
          onPress={ () => onPressAction(section)}
          style={isSaving ? styles.disabledButton : styles[section.style]}
          loading={isSaving}
          disabled={isSaving}
          //ref={el => fieldRefs.current[index] = el}
        >
          {section.text}
        </Button>
      </View>
    }

    //LIST
    if(section.type === 'list'){
      return <View key={randomKey(16)} style={applyStyles(section, sectionContainer)}>
        <AccordionList
          icon={section.icon}
          text={section.text}
          items={section.items}
          onPress={section.onPress}
        />
      </View>
    }

    //booleanShowText
    if(section.type === 'booleanShowText'){
      if(data[section.dataSource]){
        return <View key={randomKey(16)} style={applyStyles(section, sectionContainer)}>
          <Text style={styles[section.style]}>{section.label}</Text>
          <Text>{section.text}</Text>
        </View>
      }
    }

    //DROPDOWN - RELATION
    if(section.type === 'dropdown' || section.type === 'multiDropdown'){

      let multi = false;
      if(section.type === 'multiDropdown'){
        multi = true;
      }

      return <View key={randomKey(16)} style={applyStyles(section, dropdownSectionContainer)}>
        <Dropdown
          label={section.label}
          value={value}
          dataSource={section.dataSource}
          onChange={changeRecordItem}
          items={section.items}
          multi={multi}
          relationColumn={section.relationColumn}
          relationTable={section.relationTable}
          editable={editable ? section.editable : false}
          sectionIndex={index}
          ref={el => fieldRefs.current[index] = el}
        />
      </View>
    }

    //Checkbox grid - Relation Table
    if(section.type === 'relationTable'){
      return <View key={randomKey(16)} style={applyStyles(section, sectionContainer)}>
        <RelationTable
          label={section.label}
          value={value}
          relationColumn={section.relationColumn}
          relationTable={section.relationTable}
          items={section.items}
          editable={editable ? section.editable : false}
          dataSource={section.dataSource}
          onChange={changeRecordItem}
        />
      </View>
    }

    //Textarea
    if(section.type === 'textarea'){
      return <View key={randomKey(16)} style={applyStyles(section, sectionContainer)}>
        <TextInput
          label={section.label}
          value={value}
          editable={editable ? section.editable : false}
          //onChangeText={ text => { debounceChangeRecordItem(section.dataSource, text)} }
          onBlur={(e) => changeRecordItem(section.dataSource, e.target.value)}
          multiline={true}
          numberOfLines={5}
          ref={el => fieldRefs.current[index] = el}
        />
      </View>
    }

    //autoComplete
    if(section.type === 'autoComplete'){
      return <View key={randomKey(16)} style={{...applyStyles(section, sectionContainer), zIndex:(999-index)}}>
        <Autocomplete
          label={section.label}
          value={value}
          url={section.url}
          gridCode={props.gridCode}
          dataSource={section.dataSource}
          onChange={changeRecordItem}
          sectionIndex={index}
          ref={el => fieldRefs.current[index] = el}
          //editable={editable ? section.editable : false}
          //onChangeText={ text => { debounceChangeRecordItem(section.dataSource, text)} }
          //onBlur={(e) => changeRecordItem(section.dataSource, e.target.value)}
        />
      </View>
    }

    //Input
    if(section.type === 'input'){

      //Text Input
      if(section.inputType === 'text'){
        //let textMask = section.mask ? section.mask : 'SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS';
        return <View key={randomKey(16)} style={applyStyles(section, sectionContainer)}>
          <TextInput
            label={section.label}
            value={value}
            editable={editable ? section.editable : false}
            onChangeText={ text => { debounceChangeRecordItem(section.dataSource, text, index)}            }
            onSubmitEditing={(e) => changeRecordItem(section.dataSource, e.target.value, index)}
            //onBlur={(e) => changeRecordItem(section.dataSource, e.target.value)}
            /*onKeyPress={(e) => {
              console.log(e)
              if(e.key === "Tab"){
                changeRecordItem(section.dataSource, e.target.value, findNextHighestIndex(index, fieldRefs.current));
              }
            }}*/
            //onBlur={(e) => console.log(e)}
            ref={el => fieldRefs.current[index] = el}
          />
        </View>
      }

      //Password Text Input - same as text but never show old value
      if(section.inputType === 'password'){
        //let textMask = section.mask ? section.mask : 'SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS';
        return <View key={randomKey(16)} style={applyStyles(section, sectionContainer)}>
          <TextInput
            label={section.label}
            value={''}
            editable={editable ? section.editable : false}
            onChangeText={ text => { debounceChangeRecordItem(section.dataSource, text, index)}            }
            onSubmitEditing={(e) => changeRecordItem(section.dataSource, e.target.value, index)}
            ref={el => fieldRefs.current[index] = el}
          />
        </View>
      }

      //Number
      if(section.inputType === 'number'){
        let numberMask = section.mask ? mask(value, section.mask) : value;
        return <View key={randomKey(16)} style={applyStyles(section, sectionContainer)}>
          <TextInput
            label={section.label}
            keyboardType="numeric"
            value={numberMask} //Prevents label not staying up
            editable={editable ? section.editable : false}
            onChange={ e => {
              //let number = stripInputMask(text, 'nopatternyet');
              let numberUnmask = section.mask ? unMask(e.target.value) : e.target.value;
              debounceChangeRecordItem(section.dataSource, numberUnmask, index)
            }}
            onSubmitEditing={(e) => {
              let numberUnmask = section.mask ? unMask(e.target.value) : e.target.value;
              changeRecordItem(section.dataSource,  numberUnmask, index)
            }}
            /*onBlur={(e) => {
              console.log(e);
              changeRecordItem(section.dataSource,  unMask(e.target.value))
            }}*/
            /*
            onKeyPress={(e) => {
              console.log(e)
              if(e.key === "Tab"){
                //console.log('unmask', unMask(e.target.value));
                e.preventDefault();
                changeRecordItem(section.dataSource,  unMask(e.target.value), findNextHighestIndex(index, fieldRefs.current));
              }
            }}
            */

            //onBlur={(e) => console.log(e)}
            ref={el => fieldRefs.current[index] = el}
            //mask={section.mask ? section.mask : '9999999999999999999999999'}
            //The part below is WAY more performant, but it causes calculations to destroy page
            render={props => {
              if(section.mask){
                return (<MaskedTextInput
                  {...props}
                  value={mask(value, numberMask)}
                  mask={section.mask}
                />)
              }else{ return <TextInputNormal
                {...props}
                value={numberMask}
                keyboardType="numeric"
                //style={{paddingBottom: 0, paddingmargin: 0}}
              /> }

            }
            }
          />
        </View>
      }

      //Date Input
      if(section.inputType === 'date'){
        return <View key={randomKey(16)} style={applyStyles(section, sectionContainer)}>
          <DatePicker
            label={section.label}
            locale="en"
            value={value}
            dataSource={section.dataSource}
            onChange={changeRecordItem}
            editable={editable ? section.editable : false}
            sectionIndex={index}
            ref={el => fieldRefs.current[index] = el}
            style={{height:'100%'}}
          />
        </View>

      }

      //Checkbox
      if(section.inputType === 'checkbox'){
        return <View key={randomKey(16)} style={applyStyles(section, sectionContainer)}>
          <View style={styles.checkBoxContainer} >
            <CheckboxInput
              label={section.label}
              value={value}
              dataSource={section.dataSource}
              onChange={changeRecordItem}
              disabled={editable ? !section.editable : true}
              sectionIndex={index}
              ref={el => fieldRefs.current[index] = el}
            />
            <Text>{section.label}</Text>
          </View>
        </View>

      }

    }
  }

  /**
   * OnPress Actions
   * */

  const onPressAction = (section) => {


    if(section.onPress === 'terminateEmployee'){
      setIsTermPopupVisible(true);
    }

    if(section.onPress === 'goToUrl'){
      window.open(section.url, '_blank');
    }

  }

  const sendOnPressAction = (onPressAction, onPressData) => {

    setIsSaving(true);
    patchData(
      '/data/record/'+props.tableName+'/'+props.recordId,
      powerforce.authToken,
      {
        onPress: onPressAction,
        onPressData: onPressData,
        gridCode: props.gridCode
      }
    ).then(response => {
      setIsSaving(false);

      if(response.status === 500){

        setSnackbarText(response.detail);
        setSnackbarVisible(true);

      }else if(response.status > 200){

        setSnackbarText(response.detail.message);
        setSnackbarVisible(true);

      }else{
        //Trigger Reload
        let currentCount = reloadCount;
        currentCount++;
        setReloadCount(currentCount);
      }

    })

  }

  const terminateEmployee = () => {

    setIsTermPopupVisible(false);

    //if no snackbar errors, do the action
    let today = new Date().toISOString();
    changeRecordItem('terminationDate', today);

    let todayAgain = new Date();
    let startDate = new Date(data['startDate']);
    if(todayAgain >= startDate){
      sendOnPressAction('terminateEmployee', {'terminationInstructions': data['terminationInstructions']});
    }

  }

  /**
   * Show the record
   * */
  if(fetchError){
    return (
      <Text>{errorText}</Text>
    )
  }

  /**
   * Bottom Buttons
   * */
  const afterSaveActionButton = () => {
    if(showAfterSaveActionButton){
      return(
        <Button
          icon={null}
          mode={'contained'}
          style={isSaving ? styles.disabledButton : styles.primaryButton}
          onPress={ () => saveChanges(true)}
          loading={isSaving}
          disabled={isSaving}
        >
          {afterSaveActionButtonText}
        </Button>
      )
    }
  }

  const saveButton = () => {
    if(showSaveButton){
      return(
        <Button
          icon={null}
          mode={'contained'}
          style={isSaving ? styles.disabledButton : styles.primaryButton}
          onPress={ () => saveChanges(true)}
          loading={isSaving}
          disabled={isSaving}
        >
          {saveButtonText}
        </Button>
      )
    }
  }

  const cancelButton = () => {
    if(showCancelButton){
      return(
        <Button
          icon={null}
          mode={'contained'}
          style={isSaving ? styles.disabledButton : styles.secondaryButton}
          onPress={showPopup}
          loading={isSaving}
          disabled={isSaving}
        >
          {cancelButtonText}
        </Button>
      )
    }
  }


  if(data === null || structure === null ){
    return (
      <ActivityIndicator animating={true} color={powerforce.colors.primary} />
    )
  }else{

    if(props.permission === 'write'){
      return(
        <View style={{ width: '100%'}}>

          <View style={styles.header}>
            <Text style={styles.titleText}>{structure.title}</Text>

            {structure.quickAccessBar.map(barItem => {
              return createQuickAccessBarItem(barItem);
            })}

          </View>

          <View style={styles.section}>
            {
              structure.sections.map((section, index) => {
                return createSection(section, index);
              })
            }
          </View>

          <Portal>
            <Dialog visible={snackbarVisible} onDismiss={onDismissSnackBar}>
              <Dialog.Title>Alert</Dialog.Title>
              <Dialog.Content>
                <Paragraph>{snackbarText}</Paragraph>
              </Dialog.Content>
              <Dialog.Actions>
                <Button onPress={onDismissSnackBar}>Done</Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>

          <View style={styles.saveSection}>

            {afterSaveActionButton()}
            {saveButton()}
            {cancelButton()}

            <Portal>
              <Dialog visible={isCancelPopupVisible} onDismiss={hidePopup}>
                <Dialog.Title>Cancel Alert</Dialog.Title>
                <Dialog.Content>
                  <Paragraph>Do you want to clear the form and lose your work?</Paragraph>
                </Dialog.Content>
                <Dialog.Actions>
                  <Button onPress={cancelChanges}>Yes</Button>
                  <Button onPress={hidePopup}>No</Button>
                </Dialog.Actions>
              </Dialog>
            </Portal>

            <Portal>
              <Dialog visible={isTermPopupVisible} onDismiss={hideTermPopup}>
                <Dialog.Title>Warning!</Dialog.Title>
                <Dialog.Content>
                  <Paragraph>
                    Are you sure that you want to terminate this user immediately? Doing so will generate tickets that will deactivate building and account access. Click "Yes" to continue with the termination or "No" to cancel.
                  </Paragraph>
                </Dialog.Content>
                <Dialog.Actions>
                  <Button onPress={terminateEmployee}>Yes</Button>
                  <Button onPress={hideTermPopup}>No</Button>
                </Dialog.Actions>
              </Dialog>
            </Portal>

          </View>
        </View>

      )
    }

    if(props.permission === 'read'){
      return(
        <View style={{ width: '100%'}}>

          <View style={styles.header}>
            <Text style={styles.titleText}>{structure.title}</Text>

            {structure.quickAccessBar.map(barItem => {
              return createQuickAccessBarItem(barItem);
            })}

          </View>

          <View style={styles.section}>
            {
              structure.sections.map(section => {
                return createSection(section);
              })
            }
          </View>

          <View style={styles.saveSection}>
            <Button
              icon={null}
              mode={'contained'}
              style={styles.secondaryButton}
              onPress={cancelChanges}
            >
              Back to Grid
            </Button>

          </View>
        </View>

      )
    }


  }

}


