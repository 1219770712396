import * as React from 'react';
import {Card, Paragraph, DataTable, ActivityIndicator} from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import {Pressable, ScrollView, StyleSheet, Text, View} from "react-native";
import {isDesktop} from '../../helpers/responsive'
import {Picker} from "@react-native-picker/picker";
import {PowerforceContext} from "../../helpers/context";
import {EditPopup} from "./EditPopup";
import {AddPopup} from "./AddPopup";
import {AddDataPopup} from "./AddDataPopup";
import {EditDataPopup} from "./EditDataPopup";
import {deleteData, patchData, postData} from "../../helpers/api";

export default function HierarchyManager(props){

  const powerforce = React.useContext(PowerforceContext);

  const [hierarchyTable, setHierarchyTable] = React.useState(props.hierarchyTable);
  const [dataTable, setDataTable] = React.useState(props.dataTable);

  //TODO Abstract this
  // Hierarchy Info
  const parentIdKey = 'ParentID';
  const labelKey = 'Account_Type';
  //const hierarchyKey = 'LOS_ID';

  // Data Info
  const dataStructure = [
    {
      label: 'Account Code',
      column: 'Account'
    },
    {
      label: 'Account Name',
      column: 'Name'
    }
  ]

  if(hierarchyTable === undefined){
    return (<Text>Loading...</Text>);
  }

  function findItemsByParentId(parentId){
    return hierarchyTable.find(row => (row[parentIdKey] * 1) === parentId);
  }

  const [lvl1Value, setLvl1Value] = React.useState(findItemsByParentId(0) ? findItemsByParentId(0).id : null);
  const [lvl2Value, setLvl2Value] = React.useState(findItemsByParentId((lvl1Value * 1)) ? findItemsByParentId((lvl1Value * 1)).id : null);
  const [lvl2Name, setLvl2Name] = React.useState(findItemsByParentId((lvl1Value * 1)) ? findItemsByParentId((lvl1Value * 1))[labelKey] : null);
  const [lvl3Value, setLvl3Value] = React.useState(findItemsByParentId((lvl2Value * 1)) ? findItemsByParentId((lvl2Value * 1)).id : null);
  const [lvl3Name, setLvl3Name] = React.useState(findItemsByParentId((lvl2Value * 1)) ? findItemsByParentId((lvl2Value * 1))[labelKey] : null);
  const [accountValue, setAccountValue] = React.useState(null);

  React.useEffect(() => {
    setLvl2Value(findItemsByParentId((lvl1Value * 1)) ? findItemsByParentId((lvl1Value * 1)).id : null);
    setLvl2Name(findItemsByParentId((lvl1Value * 1)) ? findItemsByParentId((lvl1Value * 1))[labelKey] : null);
    setLvl3Value(findItemsByParentId((lvl2Value * 1)) ? findItemsByParentId((lvl2Value * 1)).id : null);
    setLvl3Name(findItemsByParentId((lvl2Value * 1)) ? findItemsByParentId((lvl2Value * 1))[labelKey] : null);
    setAccountValue(null)
  }, [lvl1Value]);

  React.useEffect(() => {
    setLvl3Value(findItemsByParentId((lvl2Value * 1)) ? findItemsByParentId((lvl2Value * 1)).id : null);
    setLvl3Name(findItemsByParentId((lvl2Value * 1)) ? findItemsByParentId((lvl2Value * 1))[labelKey] : null);
    setAccountValue(null)
  }, [lvl2Value]);

  React.useEffect(() => {
    setAccountValue(null)
  }, [lvl3Value]);


  const [lvl2EditPopupVisible, setLvl2EditPopupVisible] = React.useState(false);
  const [lvl2AddPopupVisible, setLvl2AddPopupVisible] = React.useState(false);
  const [lvl3EditPopupVisible, setLvl3EditPopupVisible] = React.useState(false);
  const [lvl3AddPopupVisible, setLvl3AddPopupVisible] = React.useState(false);
  const [dataAddPopupVisible, setDataAddPopupVisible] = React.useState(false);
  const [dataEditPopupVisible, setDataEditPopupVisible] = React.useState(false);

  const changeParentId = (idToChange, newParentId, newName) => {
    let newHierarchyTable = hierarchyTable.filter(() => true);
    let changeItemIndex = newHierarchyTable.findIndex((row) => row.id === idToChange);
    newHierarchyTable[changeItemIndex][parentIdKey] = newParentId;
    newHierarchyTable[changeItemIndex][labelKey] = newName;

    setHierarchyTable([...newHierarchyTable]);

    // Fetch and save
    let data = newHierarchyTable[changeItemIndex];
    data.tableType = 'hierarchy'; // This is to differentiate if we are updating the data table or the hierarchy table
    patchData(
      '/data/hierarchy/'+props.code,
      powerforce.authToken,
      data
    ).then(response => {
      //console.log(response); //recordId
    })

  }

  const lvl2EditSave = (idToChange, newParentId, newName) => {
    changeParentId(idToChange, newParentId, newName);
    setLvl1Value(newParentId);
    setLvl2Name(newName);
  }

  const lvl3EditSave = (idToChange, newParentId, newName) => {
    changeParentId(idToChange, newParentId, newName);
    setLvl2Value(newParentId);
    setLvl3Name(newName);
  }

  const saveNewCategory = (newCategoryName, newParentId, extraData) => {

    let data = {
      'Account_Type' : newCategoryName,
      'ParentID' : newParentId,
      'tableType' : 'hierarchy',
    }

    //TODO ABSTRACT THIS
    if(extraData === 'lvl2'){
      setLvl2Loading(true)
      data['Subtype'] = 'Major';
      data['Subtype_Sequence'] = '2';
    }

    if(extraData === 'lvl3'){
      setLvl3Loading(true)
      data['Subtype'] = 'Minor';
      data['Subtype_Sequence'] = '3';
    }

    if(props.hierarchyKey === 'FinMap_ID'){
      data['Type'] = 'Financial';
    }
    if(props.hierarchyKey === 'LOS_ID'){
      data['Type'] = 'LOS';
    }
    // THIS ENDS

    //Fetch, and create. Get New ID from db
    postData(
      '/data/hierarchy/'+props.code,
      powerforce.authToken,
      data
    ).then(response => {
      //console.log(response); //recordId
      if(response.status === 500){
        alert('500');
      }else{
        let newHierarchyTable = hierarchyTable.filter(() => true);
        newHierarchyTable.push({
          'Account_Type' : newCategoryName,
          'ParentID' : newParentId,
          'id' : response.extra.recordId,
        });
        setHierarchyTable(newHierarchyTable);
      }
      setLvl2Loading(false)
      setLvl3Loading(false)

    })

  }

  const deleteLvl2Category = (id) => {
    deleteCategory(id);
    setLvl2Value(null);
  }

  const deleteLvl3Category = (id) => {
    deleteCategory(id);
    setLvl3Value(null);
  }

  const deleteCategory = (id) => {
    let newHierarchyTable = hierarchyTable.filter((row) => row.id !== id);
    setHierarchyTable([...newHierarchyTable]);

    let data = { id: id, tableType: 'hierarchy'};
    deleteData(
      '/data/hierarchy/'+props.code,
      powerforce.authToken,
      data
    ).then(response => {
      //console.log(response); //recordId
      if(response.status > 399){
        alert('Something went wrong deleting a catgeory. PLease refresh and try again.');
      }
    })
  }


  const editDataRecord = (idToChange, mainAccountCode, subAccountCode, accountName, newParentCategoryId) => {
    let newDataTable = dataTable.filter(() => true);
    let changeItemIndex = newDataTable.findIndex((row) => row.id === idToChange);

    let accountCode = mainAccountCode + '.' + subAccountCode;
    if(!subAccountCode || subAccountCode === ''){
      accountCode = mainAccountCode;
    }

    newDataTable[changeItemIndex] =  {
      'Name' : accountName,
      //'FinMap_ID' : newParentCategoryId,
      'MainAccount' : mainAccountCode,
      'SubAccount' : subAccountCode,
      'Account' : accountCode,
      'id' : idToChange,
    }

    newDataTable[changeItemIndex][props.hierarchyKey] = newParentCategoryId;

    setDataTable([...newDataTable]);

    //Fetch and update
    let data = newDataTable[changeItemIndex];
    data.tableType = 'data'; // This is to differentiate if we are updating the data table or the hierarchy table
    patchData(
      '/data/hierarchy/'+props.code,
      powerforce.authToken,
      data
    ).then(response => {
      //console.log(response); //recordId
    })
  }

  const saveNewDataRecord = (mainAccountCode, subAccountCode, accountName, newParentCategoryId) => {

    //fetch and get ID from db

    let newDataTable = dataTable.filter(() => true);

    let accountCode = mainAccountCode + '.' + subAccountCode;
    if(!subAccountCode || subAccountCode === ''){
      accountCode = mainAccountCode;
    }

    let newData = {
      'Name' : accountName,
      //'FinMap_ID' : newParentCategoryId,
      'MainAccount' : mainAccountCode,
      'SubAccount' : subAccountCode,
      'Account' : accountCode,
      'id' : newDataTable.length + 100,
    };
    newData[props.hierarchyKey] = newParentCategoryId;

    newDataTable.push(newData);

    setDataTable(newDataTable);
  }

  const saveOrEdit = (saveOrEdit, data) => {

    if(saveOrEdit === 'save'){
      saveNewDataRecord(...data);
    }

    if(saveOrEdit === 'edit'){
      editDataRecord(...data);
    }

  }

  const deleteDataRecord = (id) => {
    let newDataTable = dataTable.filter((row) => row.id !== id);
    setDataTable([...newDataTable]);
    setAccountValue(null);

    let data = { id: id, tableType: 'data'};
    deleteData(
      '/data/hierarchy/'+props.code,
      powerforce.authToken,
      data
    ).then(response => {
      //console.log(response); //recordId
      if(response.status > 399){
        alert('Something went wrong deleting an account. PLease refresh and try again.');
      }
    })
  }


  const [lvl2Loading, setLvl2Loading] = React.useState(false);
  const [lvl3Loading, setLvl3Loading] = React.useState(false);

  const lvl2LoadingRow = () => {
    if(lvl2Loading){
      return(
        <DataTable.Row>
          <DataTable.Cell>
            <ActivityIndicator animating={true} color={powerforce.colors.primary} />
          </DataTable.Cell>
        </DataTable.Row>
      )
    }
  }

  const lvl3LoadingRow = () => {
    if(lvl3Loading){
      return(
        <DataTable.Row>
          <DataTable.Cell>
            <ActivityIndicator animating={true} color={powerforce.colors.primary} />
          </DataTable.Cell>
        </DataTable.Row>
      )
    }
  }


  return(
    <View style={{ width: '100%' }} dataSet={{'code': props.code}}>
      <Text style={{fontSize:'30px', paddingBottom:'15px'}}>{props.title}</Text>
      {/*** Level 1 ***/}
      <View style={{
        'position': 'relative',
        width: isDesktop() ? '50%' : '100%',
      }}>
        <Text style={styles.smallLabel}>Level 1</Text>
        <Picker
          selectedValue={lvl1Value}
          itemStyle={styles.pickerItem}
          mode={'dialog'}
          onFocus={() => {console.log('onfocus')}}
          style={styles.picker}
          onValueChange={(itemValue, itemIndex) => {
            setLvl1Value(itemValue);
          }
          }>
          {
            hierarchyTable.map((row) => {
              if((row[parentIdKey] * 1) === 0){
                return(
                  <Picker.Item
                    key={'lvl1' + row.id}
                    label={row[labelKey]}
                    value={row.id}
                  />
                );
              }
            })
          }
        </Picker>
      </View>

      {/*** Levels 2 & 3 ***/}

      <View style={{
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: isDesktop() ? 'row' : 'column',
        paddingTop: '30px'
      }}>

        {/*** Level 2 ***/}
        <View style={{ width: isDesktop() ? '49%' : '100%' }}>

          <View style={styles.levelTableHeader}>
            <Text style={styles.textLabel}>Level 2</Text>
            <View style={styles.buttonHolder}>

              <Pressable
                onPress={() => {setLvl2AddPopupVisible(true)}}
                style={styles.button}
              >
                <Feather name="plus-circle" size={24} color="#00264d" />
              </Pressable>

              <Pressable
                onPress={() => {setLvl2EditPopupVisible(true)}}
                style={styles.button}
              >
                <Feather name="edit" size={24} color="#00264d" />
              </Pressable>

              <Pressable
                onPress={() => {deleteLvl2Category(lvl2Value)}}
                style={styles.button}
              >
                <Feather name="trash-2" size={24} color="#00264d" />
              </Pressable>

            </View>

          </View>

          <ScrollView style={styles.scrollView}>
            <DataTable>

              {
                hierarchyTable.map((row) => {
                  if((row[parentIdKey] * 1) === (lvl1Value * 1)){
                    return(
                      <DataTable.Row
                        key={'lvl2' + row.id}
                        onPress={(event) => {setLvl2Value(row.id); setLvl2Name(row[labelKey]);}}
                        style={ (row.id * 1) === (lvl2Value * 1) ? {backgroundColor: powerforce.colors.accent} : {}}
                      >
                        <DataTable.Cell>{row[labelKey]}</DataTable.Cell>
                      </DataTable.Row>
                    );
                  }
                })
              }

              {lvl2LoadingRow()}

            </DataTable>
          </ScrollView>
          <EditPopup
            title={'Level 2 Update'}
            labelKey={labelKey}
            visible={lvl2EditPopupVisible}
            onClose={ () => { setLvl2EditPopupVisible(false) }}
            onSave={ lvl2EditSave }
            parentValue={lvl1Value}
            itemValue={lvl2Value}
            itemName={lvl2Name}
            parentCategories={hierarchyTable.filter(row => (row[parentIdKey] * 1) === 0)}
          />
          <AddPopup
            title={'New Level 2 Category'}
            labelKey={labelKey}
            visible={lvl2AddPopupVisible}
            onClose={ () => { setLvl2AddPopupVisible(false) }}
            onSave={saveNewCategory}
            extraData={'lvl2'}
            parentValue={lvl1Value}
            parentCategories={hierarchyTable.filter(row => (row[parentIdKey] * 1) === 0)}
          />
        </View>

        {/*** Level 3 ***/}
        <View style={{ width: isDesktop() ? '49%' : '100%' }}>

          <View style={styles.levelTableHeader}>
            <Text style={styles.textLabel}>Level 3</Text>
            <View style={styles.buttonHolder}>

              <Pressable
                onPress={() => {setLvl3AddPopupVisible(true)}}
                style={styles.button}
              >
                <Feather name="plus-circle" size={24} color="#00264d" />
              </Pressable>

              <Pressable
                onPress={() => {setLvl3EditPopupVisible(true)}}
                style={styles.button}
              >
                <Feather name="edit" size={24} color="#00264d" />
              </Pressable>

              <Pressable
                onPress={() => {deleteLvl3Category(lvl3Value)}}
                style={styles.button}
              >
                <Feather name="trash-2" size={24} color="#00264d" />
              </Pressable>

            </View>

          </View>

          <ScrollView style={styles.scrollView}>
            <DataTable>

              {
                hierarchyTable.map((row) => {
                  if(lvl2Value === null){
                    return;
                  }
                  if((row[parentIdKey] * 1) === (lvl2Value * 1)){
                    return(
                      <DataTable.Row
                        key={'lvl3' + row.id}
                        onPress={(event) => {setLvl3Value(row.id); setLvl3Name(row[labelKey]);}}
                        style={ (row.id * 1) === (lvl3Value * 1) ? {backgroundColor: powerforce.colors.accent} : {}}
                      >
                        <DataTable.Cell>{row[labelKey]}</DataTable.Cell>
                      </DataTable.Row>
                    );
                  }
                })
              }

              {lvl3LoadingRow()}

            </DataTable>
          </ScrollView>
          <EditPopup
            title={'Level 3 Update'}
            labelKey={labelKey}
            visible={lvl3EditPopupVisible}
            onClose={ () => { setLvl3EditPopupVisible(false) }}
            onSave={ lvl3EditSave }
            parentValue={lvl2Value}
            itemValue={lvl3Value}
            itemName={lvl3Name}
            //parentCategories={hierarchyTable.filter(row => (row[parentIdKey] * 1) === (lvl1Value * 1))}
            parentCategories={hierarchyTable.filter(row => {
              return row['Subtype_Sequence'] === '2'
            })}
          />
          <AddPopup
            title={'New Level 3 Category'}
            labelKey={labelKey}
            visible={lvl3AddPopupVisible}
            onClose={ () => { setLvl3AddPopupVisible(false) }}
            onSave={saveNewCategory}
            extraData={'lvl3'}
            parentValue={lvl2Value}
            /*parentCategories={hierarchyTable.filter(row => {
              return (row[parentIdKey] * 1) === (lvl1Value * 1)
            })}*/
            parentCategories={hierarchyTable.filter(row => {
              return row['Subtype_Sequence'] === '2'
            })}
          />
        </View>

      </View>

      {/*** Accounts ***/}

      <View style={{
        width: '100%',
        paddingTop: '30px'
      }}>

        <View style={{ width: '100%' }}>

          <DataTable>
            <DataTable.Header style={styles.accountTableHeader}>
              {dataStructure.map( (cell, index) => {
                return(<DataTable.Title key={'headKey' + index}>{cell.label}</DataTable.Title>)
              })}
              <DataTable.Title>Level 3 Category</DataTable.Title>
            </DataTable.Header>

            <View style={styles.tableButtonHolder}>
              <Pressable
                onPress={() => {setDataAddPopupVisible(true)}}
                style={styles.button}
              >
                <Feather name="plus-circle" size={24} color="#00264d" />
              </Pressable>
              <Pressable
                onPress={() => {setDataEditPopupVisible(true)}}
                style={styles.button}
                disabled={!accountValue}
              >
                <Feather name="edit" size={24} color="#00264d" />
              </Pressable>

              <Pressable
                onPress={() => {deleteDataRecord(accountValue)}}
                style={styles.button}
              >
                <Feather name="trash-2" size={24} color="#00264d" />
              </Pressable>
            </View>

            <ScrollView style={styles.accountScrollView}>
            {
              dataTable.map((row, rowIndex) => {
                if(lvl3Value !== null && (row[props.hierarchyKey] * 1) === (lvl3Value * 1)){
                  return(
                    <DataTable.Row
                      key={'accounts-' + row.id + '-' + rowIndex}
                      onPress={(event) => {setAccountValue(row.id)}}
                      style={ (row.id * 1) === (accountValue * 1) ? {backgroundColor: powerforce.colors.accent} : {}}
                    >
                      {dataStructure.map( (cell, cellIndex ) => {
                        return(<DataTable.Cell key={'rowKey' + rowIndex + 'cellKey' + cellIndex}>{row[cell.column]}</DataTable.Cell>)
                      })}
                      <DataTable.Cell>{lvl3Name}</DataTable.Cell>
                    </DataTable.Row>
                  );
                }
              })
            }
            </ScrollView>

          </DataTable>
        </View>

        <EditDataPopup
          title={'Edit Account'}
          labelKey={labelKey}
          visible={dataEditPopupVisible}
          hierarchyKey={props.hierarchyKey}
          data={dataTable.find(row => (row.id * 1) === (accountValue * 1))}
          onClose={ () => { setDataEditPopupVisible(false) }}
          onSave={ editDataRecord }
          //parentCategories={hierarchyTable.filter(row => (row[parentIdKey] * 1) === (lvl2Value * 1))}
          parentCategories={hierarchyTable.filter(row => {
            return row['Subtype_Sequence'] === '3'
          })}
        />

        <AddDataPopup
          title={'Assign Account'}
          labelKey={labelKey}
          visible={dataAddPopupVisible}
          hierarchyKey={props.hierarchyKey}
          onClose={ () => { setDataAddPopupVisible(false) }}
          onSave={ saveOrEdit }
          currentParentCategoryId={lvl3Value}
          //parentCategories={hierarchyTable.filter(row => (row[parentIdKey] * 1) === (lvl2Value * 1))}
          parentCategories={hierarchyTable.filter(row => {
            return row['Subtype_Sequence'] === '3'
          })}
          dataTable={dataTable}
        />

      </View>


    </View>
  )

}

const styles = StyleSheet.create({
  scrollView: {
    height: '300px',
    borderColor: 'rgb(231, 231, 231)',
    borderWidth: 2,
    borderStyle: 'solid',
    borderTopWidth: 0
  },
  accountScrollView: {
    maxHeight: '350px'
  },
  textInput: {
    marginRight: '0px'
  },
  accordion: {
    position: 'relative',
  },
  listItemContainer : {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 99
  },
  listItem: {
    backgroundColor: '#ff0000',
    position: 'relative',
    zIndex: 9
  },
  picker : {
    backgroundColor:'#e7e7e7',
    color: '#6a6a6a',
    paddingTop: '29px',
    paddingBottom: '14px',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '16px',
    borderTopWidth: '0',
    borderLeftWidth: '0',
    borderRightWidth: '0',
    borderBottomWidth: '1px',
    borderBottomColor: '#ababab',
    //marginRight: '10px',
  },
  pickerItem : {
    fontSize: '16px'
  },
  smallLabel : {
    fontSize: '12px',
    top: '5px',
    position: 'absolute',
    color: '#6a6a6a',
    left: '12px'
  },
  bigLabel : {
    fontSize: '16px',
    top: '18px',
    position: 'absolute',
    color: '#6a6a6a',
    left: '12px'
  },
  textLabel : {
    backgroundColor: 'rgb(231, 231, 231)',
    color: '#6a6a6a',
    padding: '10px',
  },
  accountTableHeader : {
    backgroundColor: 'rgb(231, 231, 231)',
    color: '#6a6a6a',
  },
  levelTableHeader : {
    backgroundColor: 'rgb(231, 231, 231)',
    color: '#6a6a6a',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  buttonHolder : {
    flexDirection: 'row',
    paddingTop: '5px'
  },
  tableButtonHolder : {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    position: 'absolute',
    top: '10px',
    right: '15px'
  },
  button: {
    marginLeft: '4px'
  }
});
