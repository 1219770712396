import {addRoleCheckboxes, addRoleDefaultValues, addRoleDefaultValuesToNewComponent} from "../FoundationsPlugin";

const script = function() {

  //console.log('### Card Script ###');

  const initLib = function() {
    window.feather.replace();
  };

  if (typeof window.feather == 'undefined') {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/feather-icons/dist/feather.min.js';
    script.onload = initLib;
    document.body.appendChild(script);

  } else {
    initLib();
  }
}

export const CardMethods = (options, editor) => {

  console.log(options);

  let config = {
    // Model definition
    model: {
      // Default properties
      defaults: {
        script,
        tagName: 'div',
        droppable: false, // Can't drop other elements inside
        attributes: { // Default attributes
          componentId: 'add',
          //type: 'text',
          //name: 'default-name',
          //placeholder: 'Insert text here',
        },
        traits: [
          {
            type: 'text',
            label: 'Width',
            name: 'width',
          },
          {
            type: 'select',
            label: 'Image or Icon',
            name: 'imageOrIcon',
            options: [ // Array of options
              {id: 'image', name: 'image'},
              {id: 'icon', name: 'icon'},
            ]
          },
          {
            type: 'text',
            label: 'Icon Name',
            name: 'iconName',
          }
        ],

      },
      updated(property, value, prevValue) {
        //console.log('updated', property, value, prevValue);
        if (property === 'traits') {

          this.views[0].el.style.width = value.target.attributes.attributes.width;

          if (value.target.attributes.attributes.imageOrIcon === 'icon') {
            this.views[0].el.querySelector('.card-image').style.display = 'none';
            this.views[0].el.querySelector('.card-icon').style.display = 'block';

            if (value.target.attributes.attributes.iconName in document.getElementsByTagName('iframe')[0].contentWindow.feather.icons) {
              this.views[0].el.querySelector('.card-icon').innerHTML = iconHtml(value.target.attributes.attributes.iconName);
              document.getElementsByTagName('iframe')[0].contentWindow.feather.replace()
            } else {
              alert('That is not a valid icon name.');
            }
          }

          if (value.target.attributes.attributes.imageOrIcon === 'image') {
            this.views[0].el.querySelector('.card-icon').style.display = 'none';
            this.views[0].el.querySelector('.card-image').style.display = 'block';
          }

        }
      },
      removed(something){
        console.log('removed!!!!', something);
      }
    }
  };

  if(options.editorType === 'full'){
    let fullConfigAdditions = [
        {
        type: 'select',
        label: 'On Press Action',
        name: 'onPressAction',
        options: [ // Array of options
          {id: 'none', name: 'none'},
          {id: 'goToExternalLink', name: 'goToExternalLink'},
          {id: 'goToInternalLink', name: 'goToInternalLink'},
          {id: 'mailTo', name: 'mailTo'},
          {id: 'alertWeb', name: 'alertWeb'},
        ]
      },
        {
          type: 'text',
          label: 'OnPress Arg 1',
          name: 'onPressFunctionArg1',
        },
        {
          type: 'text',
          label: 'OnPress Arg 2',
          name: 'onPressFunctionArg2',
        }
    ];
    config.model.defaults.traits.push(...fullConfigAdditions);

    //Add the users roles as default attributes
    //Add the checkboxes
    config = addRoleCheckboxes(options.roles, config);
  }

  return config;
}



function iconHtml(iconName){
  return '<i ' +
    'data-feather="' + iconName + '"' +
    'style="' +
      'height:150px;' +
      'width:100%;' +
      'text-align:center;' +
      'padding:10px;' +
    '"></i>';
}

/* Label for sidebar*/

export const CardLabel = '<div style="font-size:20px;">' +
  '<div><b>Card</b></div>' +
  '<i class="fa fa-external-link-square" aria-hidden="true" style="padding-top:10px"></i></div>'

/* Component structure */
export const Card = () => {

  let json = {
    "type": "card",
    "attributes": {
      "onPressAction": "none",
      "width": "25%",
      "iconName": "circle",
      "imageOrIcon": "image",
      "style": "padding:5px;width:25%;"
    },
    "components": [
      {
        "draggable": false,
        "droppable": false,
        "highlightable": false,
        "selectable": false,
        "hoverable": false,
        "attributes": {
          "style": "height:100%;border-color: rgba(0, 0, 0, 0.12);border-style: solid;border-radius: 5px;border-width: 1px;box-shadow: rgb(0 0 0 / 24%) 0px 0.5px 0.75px;"
        },
        "components": [
          {
            "type": "text",
            "draggable": false,
            "selectable": false,
            "attributes": {
              "style": "font-size:20px;padding-left:16px;min-height:50px;align-items: center;display: flex;"
            },
            "components": [
              {
                "type": "textnode",
                "content": "This is a simple title"
              }
            ]
          },
          {
            "type": "image",
            "draggable": false,
            "editable": true,
            "resizable": false,
            "selectable": false,
            "classes": [
              "card-image"
            ],
            "attributes": {
              "src": "/media/smartsheet.png",
              "style": "max-height:175px; display:block; margin:auto; padding:10px;max-width:100%;"
            }
          },
          {
            "draggable": false,
            "droppable": false,
            "selectable": false,
            "classes": [
              "card-icon"
            ],
            "attributes": {
              "style": "display:none;"
            },
            "components": [
              {
                "tagName": "i",
                "attributes": {
                  "data-feather": "circle",
                  "style": "height:150px;width:100%;text-align:center;padding:10px;"
                }
              }
            ]
          },
          {
            "type": "text",
            "draggable": false,
            "selectable": false,
            "attributes": {
              "style": "padding:0px 16px 16px 16px;font-size:14px;"
            },
            "components": [
              {
                "type": "textnode",
                "content": "This is just a Lorem text: Lorem ipsum dolor sit amet"
              }
            ]
          }
        ]
      }
    ]
  };

  return json;

  /* We can also use JSX for components, but it's easier to keep it consistent
  with the ScreenEditorService object if it's JSON */

  /* return (

    <card
      onPressAction="none"
      width={'25'}
      iconName={'circle'}
      imageOrIcon={'image'}
      onPressFunctionArg1={''}
      onPressFunctionArg2={''}
      data-gjs-droppable={false}
      style={
        'font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;' +
        "float:left;" +
        'padding:5px;' +
        'width:25%;'
      }
    >
      <div
        data-gjs-selectable="false"
        data-gjs-highlightable="false"
        data-gjs-hoverable="false"
        data-gjs-draggable="false"
        data-gjs-droppable={false}
        style={
          'height: 100%;' +
          'border-color: rgba(0, 0, 0, 0.12);' +
          'border-style: solid;' +
          'border-radius: 5px;' +
          'border-width: 1px;' +
          'box-shadow: rgb(0 0 0 / 24%) 0px 0.5px 0.75px;'
        }
      >
        <text
          style={
            "font-size:20px;" +
            "padding-left:16px;" +
            "min-height:50px;" +
            "align-items: center;" +
            "display: flex;"
          }
          data-gjs-selectable="false"
          data-gjs-draggable="false"
          data-gjs-editable="true"
        >
          This is a simple title
        </text>
        <image
          src="/media/smartsheet.png"
          data-gjs-selectable="false"
          data-gjs-draggable="false"
          data-gjs-editable="true"
          style={'max-height:175px; display:block; margin:auto; padding:10px;'}
          class={"card-image"}
        />
        <div
          class={"card-icon"}
          style={'display:none;'}
          data-gjs-selectable="false"
          data-gjs-draggable="false"
          data-gjs-droppable="false"
          data-gjs-editable="true"
        >
          {Icon({iconName:'circle'})}
        </div>
        <text
          style={
            "padding:0px 16px 16px 16px;"
          }
          data-gjs-selectable="false"
          data-gjs-draggable="false"
          data-gjs-editable="true"
        >
          This is just a Lorem text: Lorem ipsum dolor sit amet
        </text>
      </div>
    </card>
  )*/
}

const Icon = ({iconName}) => {
  return (
    <i
      data-feather={iconName}
      style={
        "height:150px;" +
        "width:100%;" +
        "text-align:center;" +
        "padding:10px;"
      }
    ></i>
  );
}
