import * as React from 'react';
import {Pressable, Text, StyleSheet} from 'react-native';
import {View} from "react-native-web";
import { Feather } from '@expo/vector-icons';
import {useContext, useState} from "react";
import {PowerforceContext} from "../../helpers/context";
import {Desktop, Tablet, Mobile } from '../../helpers/responsive'
import MultiNav from "./MultiNav";
import SimpleNav from "./SimpleNav";
import MultiMobileNav from "./MultiMobileNav";
import SimpleMobileNav from "./SimpleMobileNav";

const BottomNavigationBar = (props) => {

  const powerforce = useContext(PowerforceContext);

    const styles = StyleSheet.create({
        logoContainer: {
          flex: 2,
          alignItems: "flex-start",
          justifyContent: 'left',
        },
        screensButton: {
          textAlign:'center',
          flex: 1,
          fontSize: '5px',
          backgroundColor: powerforce.colors.primary,
          maxWidth: '50px',
          borderRadius: '50%',
          height: '50px',
          marginRight: 'auto',
          marginLeft: '10px',
          justifyContent: 'center',
          alignItems: 'center',
        },
        modulesButton: {
          textAlign:'center',
          flex: 1,
          fontSize: '5px',
          backgroundColor: powerforce.colors.primary,
          maxWidth: '100px',
          borderRadius: '15px',
          height: '50px',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingRight: '15px',
          paddingLeft: '15px',
          marginRight: 'auto'
        },
        hideButton: {
          textAlign:'center',
          flex: 1,
          fontSize: '5px',
          backgroundColor: powerforce.colors.primary,
          marginLeft: 'auto',
          maxWidth: '50px',
          borderRadius: '50%',
          height: '50px',
          marginRight: '10px',
          justifyContent: 'center',
          alignItems: 'center',
        },
        showButton: {
          textAlign:'center',
          flex: 1,
          fontSize: '5px',
          justifyContent: 'center',
          //backgroundColor: powerforce.colors.primary,
          //marginLeft: 'auto',
          //maxWidth: '50px',
          //borderRadius: '50%',
          //height: '50px',
          //width: '50px',
        },
        navItemText: {
          textAlign:'center',
          fontSize: '5px',
          color:'#ffffff',
        },
        navBar: {
          flexDirection: "row",
          flexWrap:'wrap',
          flex: 8,
          alignItems: "center"
        },
        userArea: {
          flex: 2,
          flexDirection: "row",
          justifyContent: 'right',
          alignItems: "center"
        },
        notifications: {
          paddingRight: '20px',
        },
        showNav: {
          flex: 1,
          position: 'fixed',
          right: '10px',
          backgroundColor: powerforce.colors.primary,
          bottom: '2px',
          borderRadius: '50%',
          height: '50px',
          width: '50px',
          justifyContent: 'center',
          alignItems: 'center',
        }
    });

  const [menuVisible, setMenuVisible] = useState(true);
  const [moduleMenuVisible, setModuleMenuVisible] = useState(false);

  const toggleMenuVisibility = () => {
      setMenuVisible(current => !current);
  };

  const navPicker = () => {

    if(powerforce.companies && powerforce.companies.length > 1){
      return (
        <View nativeID={'botnav'}>

          <Tablet>
            <View style={{background: powerforce.colors.primary, borderRadius: '10px'}}>
              <MultiNav
                navItems={powerforce.topbar}
                companies={powerforce.companies ? powerforce.companies : []}
                navigation={props.navigation}
              />
            </View>
          </Tablet>

          <Mobile>
            <MultiMobileNav
              navItems={powerforce.topbar}
              companies={powerforce.companies ? powerforce.companies : []}
              navigation={props.navigation}
              show={moduleMenuVisible}
            />
            <Pressable
              onPress={() => setModuleMenuVisible(!moduleMenuVisible)}
              style={styles.modulesButton}
            >
              <Feather name={'layers'} size={20} color={moduleMenuVisible ? "rgb(240, 93, 42)" : "white"} />
              <Text style={moduleMenuVisible ? { color:'rgb(240, 93, 42)', fontSize: 9 } : { color:'#ffffff', fontSize: 9 }}>Modules</Text>
            </Pressable>
          </Mobile>

        </View>
      )
    }

    return (
      <View nativeID={'botnav'}>

        <Tablet>
          <View style={{background: powerforce.colors.primary, borderRadius: '10px'}}>
            <SimpleNav
              navItems={powerforce.topbar}
              navigation={props.navigation}
            />
          </View>
        </Tablet>

        <Mobile>
          <SimpleMobileNav
            navItems={powerforce.topbar}
            navigation={props.navigation}
            show={moduleMenuVisible}
          />
          <Pressable
            onPress={() => setModuleMenuVisible(!moduleMenuVisible)}
            style={styles.modulesButton}
          >
            <Feather name={'layers'} size={20} color={moduleMenuVisible ? "rgb(240, 93, 42)" : "white"} />
            <Text style={moduleMenuVisible ? { color:'rgb(240, 93, 42)', fontSize: 9 } : { color:'#ffffff', fontSize: 9 }}>Modules</Text>
          </Pressable>
        </Mobile>

      </View>
    )

  }

  const navBar = (menuVisible) => {
    return(
        <View style={{height:'50px'}}>
            <View style={{
                flexDirection:"row",
                //background: powerforce.colors.primary,
                position: 'fixed',
                bottom: '0px',
                width: '100%',
                //paddingBottom: '10px',
                paddingTop: '10px',
                marginBottom: '2px'
            }}
            >
                <View style={styles.navBar}>

                  <Pressable
                    onPress={props.onMenuButtonClick}
                    style={styles.screensButton}
                  >
                    <Feather name={'menu'} size={20} color={props.showMobileMenu ? "rgb(240, 93, 42)" : "white"} />
                    {/*<Text style={props.showMobileMenu ? { color:'rgb(240, 93, 42)', fontSize: 9 } : { color:'#ffffff', fontSize: 9 }}>Screens</Text>*/}
                  </Pressable>

                  {navPicker()}

                  <Pressable
                      onPress={toggleMenuVisibility}
                      style={styles.hideButton}
                  >
                    <Feather name={'arrow-right-circle'} size={20} color="white" />
                    {/*<Text style={{ color:'#ffffff', fontSize: 9 }}>Hide</Text>*/}
                  </Pressable>

                </View>
            </View>
        </View>
    )
  }

  const showMenuButton = () => {
      return(
          <Pressable
              onPress={toggleMenuVisibility}
              style={styles.showButton}
          >
              <Feather name={'arrow-left-circle'} size={20} color="white" />
            {/*<Text style={{ color:'#ffffff', fontSize: 9 }}>Show</Text>*/}
          </Pressable>
      )
  }

  return (
    <View style={ menuVisible ? null : styles.showNav}>


        {menuVisible ? navBar() : showMenuButton()}


    </View>
  )

}

export default BottomNavigationBar;



