import {addRoleCheckboxes, addRoleDefaultValues, addRoleDefaultValuesToNewComponent} from "../FoundationsPlugin";

const script = function() {

  //console.log('### Blank Script ###');

}

export const SpaceMethods = (options, editor) => {

  console.log(options);

  let config = {
    // Model definition
    model: {
      // Default properties
      defaults: {
        script,
        tagName: 'div',
        droppable: false, // Can't drop other elements inside
        attributes: { // Default attributes
          componentId: 'add',
          //type: 'text',
          //name: 'default-name',
          //placeholder: 'Insert text here',
        },
        traits: [
          {
            type: 'text',
            label: 'Height',
            name: 'height',
          },
        ],

      },
      updated(property, value, prevValue) {
        //console.log('updated', property, value, prevValue);
        if (property === 'traits') {
          this.views[0].el.style.height = value.target.attributes.attributes.height;
        }
      }
    },
    view: {
      updated(property, value, prevValue) {
        //console.log('updateView', property, value, prevValue);
      }
    }
  }

  if(options.editorType === 'full'){
    //Add role checkboxes
    config = addRoleCheckboxes(options.roles, config);
  }

  console.log(config);

  return config;
}



/* Label for sidebar*/

export const SpaceLabel = '<div style="font-size:20px;">' +
  '<div><b>Space</b></div>' +
  '<i class="fa fa-arrows-v" aria-hidden="true" style="padding-top:10px"></i></div>'

/* Component structure */
export const Space = () => {

  let json = {
    "type": "space",
    "attributes": {
      "width": "100%",
      "height": "32px",
      "style": "font-size:14px;width:100%;height:32px;"
    },
    "components": [
      {
        "type": "textnode",
        "content": " "
      }
    ]
  };

  return json;


}
