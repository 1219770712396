import * as React from 'react';
import {Pressable, Text, StyleSheet} from 'react-native';
import {View, Image} from "react-native-web";
import { Feather } from '@expo/vector-icons';
import {Desktop, Tablet, Mobile } from '../../helpers/responsive'




const SimpleNav = (props) => {

  return (


      <View style={styles.navBar} nativeID={'topnav'}>

        {
          props.navItems.map((navItem, index) => {
            return(
              <Pressable
                onPress={() => props.navigation.navigate(navItem.route, { screenName: navItem.screen})}
                style={styles.navItem}
                key={index}
                nativeID={'topnav-'+navItem.route}
              >
                <Desktop>
                  <Feather name={navItem.icon} size={24} color="white" />
                  <Text style={{ color:'#ffffff' }}>{navItem.text}</Text>
                </Desktop>

                <Tablet>
                  <Feather name={navItem.icon} size={20} color="white" />
                  <Text style={{ color:'#ffffff', fontSize: 9 }}>{navItem.text}</Text>
                </Tablet>

                <Mobile>
                  <Feather name={navItem.icon} size={20} color="white" />
                  <Text style={styles.mobileNavText}>{navItem.text}</Text>
                </Mobile>

              </Pressable>
            )
          })
        }

      </View>


  )

}

export default SimpleNav;

const styles = StyleSheet.create({
  navItem: {
    textAlign:'center',
    padding: '10px',
    flex: 1
  },
  navBar: {
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 8,
    alignItems: "center"
  },
  mobileNavText: {
    color:'#ffffff',
    fontSize: 9,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
});
