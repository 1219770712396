import {ScrollView, View} from "react-native-web";
import {Pressable, StyleSheet, Text} from "react-native";
import {Feather} from "@expo/vector-icons";
import * as React from "react";
import {Button, Checkbox, Dialog, Portal, TextInput} from "react-native-paper";
import {PowerforceContext} from "../../helpers/context";
import {randomKey} from "../../helpers/general";
import {isDesktop} from "../../helpers/responsive";
import {Picker} from "@react-native-picker/picker";


export const AddDataPopup = (props) => {

  const powerforce = React.useContext(PowerforceContext);

  const [isPopupVisible, setIsPopupVisible] = React.useState(props.visible);

  const [newParentCategoryId, setNewParentCategoryId] = React.useState(getParentCategory());
  const [accountId, setAccountId] = React.useState(props.dataTable.find((row) => row[props.hierarchyKey] === null) ? props.dataTable.find((row) => row[props.hierarchyKey] === null).id : null);
  const [mainAccountCode, setMainAccountCode] = React.useState("");
  const [subAccountCode, setSubAccountCode] = React.useState("");
  const [accountName, setAccountName] = React.useState("");
  const [checkedNew, setCheckedNew] = React.useState(false);
  const [checkedUnassigned, setCheckedUnassigned] = React.useState(true);

  function getParentCategory(){
    if(props.currentParentCategoryId){
      return props.currentParentCategoryId;
    }

    if(props.parentCategories && props.parentCategories.length > 1){
      return props.parentCategories[0].id;
    }

    return null;
  }

  //For Changing Props
  React.useEffect(() => {
    setIsPopupVisible(props.visible);
    setNewParentCategoryId(getParentCategory());
    selectUnassignedAccount(props.dataTable.find((row) => row[props.hierarchyKey] === null) ? props.dataTable.find((row) => row[props.hierarchyKey] === null).id : null);
  }, [props]);



  const showPopup = () => setIsPopupVisible(true);
  const hidePopup = () => {
    setIsPopupVisible(false);
    props.onClose();
  }

  const selectUnassignedAccount = (accountId) => {
    let selectedAccount = props.dataTable.find(account => account.id === accountId)
    if(selectedAccount){
      setAccountId(accountId);
      setMainAccountCode(selectedAccount.MainAccount);
      setSubAccountCode(selectedAccount.SubAccount ? selectedAccount.SubAccount : '');
      setAccountName(selectedAccount.Name);
    }

  }

  /**
   * Display
   */

  return (

    <Portal>
      <Dialog
        visible={isPopupVisible}
        onDismiss={hidePopup}
        style={{maxWidth:'500px', minWidth: '50%', marginLeft: 'auto', marginRight: 'auto'}}
      >
        <Dialog.Title>{props.title}</Dialog.Title>
        <Dialog.Content>
          {/* <View style={{flexDirection: 'row'}}>
            <Checkbox.Item
              status={checkedNew ? 'checked' : 'unchecked'}
              label={'Create New Account'}
              onPress={() => {
                setCheckedUnassigned(false)
                setCheckedNew(!checkedNew);
              }}
            />
            <Checkbox.Item
              status={checkedUnassigned ? 'checked' : 'unchecked'}
              label={'Find Unassigned Account'}
              onPress={() => {
                setCheckedNew(false);
                setCheckedUnassigned(!checkedUnassigned);
              }}
            />
          </View>*/}

          <UnassignedDropDown
            show={true}
            accountId={accountId}
            dataTable={props.dataTable}
            onSelect={selectUnassignedAccount}
            hierarchyKey={props.hierarchyKey}
          />

          <TextInput
            label="Main Account Code"
            value={mainAccountCode}
            onChangeText={text => setMainAccountCode(text)}
            editable={false}
            disabled={false}
            right={<TextInput.Icon icon="lock" color={'#6a6a6a'} />}
          />
          <TextInput
            label="Sub Account Code"
            value={subAccountCode}
            onChangeText={text => setSubAccountCode(text)}
            editable={false}
            disabled={false}
            right={<TextInput.Icon icon="lock" color={'#6a6a6a'} />}
          />
          <TextInput
            label="Account Name"
            value={accountName}
            onChangeText={text => setAccountName(text)}
            editable={false}
            disabled={false}
            right={<TextInput.Icon icon="lock" color={'#6a6a6a'} />}
          />

          <View style={{
            'position': 'relative',
            width: isDesktop() ? '50%' : '100%',
            marginTop: '20px'
          }}>
            <Text style={styles.smallLabel}>Lvl 3 Category Name</Text>
            <Picker
              selectedValue={newParentCategoryId}
              itemStyle={styles.pickerItem}
              mode={'dialog'}
              style={styles.picker}
              onValueChange={(itemValue, itemIndex) => {
                setNewParentCategoryId(itemValue);
              }
              }>
              {
                props.parentCategories.map((row) => {
                    return(
                      <Picker.Item
                        key={row.id}
                        label={row[props.labelKey]}
                        value={row.id}
                      />
                    );
                })
              }
            </Picker>
          </View>


        </Dialog.Content>

        <Dialog.Actions>

          <Button
            onPress={() => {
              if(checkedUnassigned){
                props.onSave('edit', [accountId, mainAccountCode, subAccountCode, accountName, newParentCategoryId]);
              }

              if(checkedNew){
                props.onSave('save', [mainAccountCode, subAccountCode, accountName, newParentCategoryId]);
              }

              hidePopup()
            } }
            style={styles.button}
            mode={'contained'}
          >
            <Text>Save</Text>
          </Button>

          <Button
            onPress={() => hidePopup()}
            style={styles.button}
            mode={'contained'}
          >
            <Text>Cancel</Text>
          </Button>

        </Dialog.Actions>

      </Dialog>
    </Portal>

  )

}

const UnassignedDropDown = (props) => {

  if(props.show){
    return (
      <View style={{
        'position': 'relative',
        width: isDesktop() ? '50%' : '100%',
        marginTop: '20px'
      }}>
        <Text style={styles.smallLabel}>Unassigned Account</Text>
        <Picker
          selectedValue={props.accountId}
          itemStyle={styles.pickerItem}
          mode={'dialog'}
          style={styles.picker}
          onValueChange={(itemValue, itemIndex) => {
            //setNewParentCategoryId(itemValue);
            props.onSelect(itemValue)
          }
          }>
          {
            props.dataTable.map((row, rowIndex) => {
              if(row[props.hierarchyKey] === null){
                return(
                  <Picker.Item
                    key={'unassignedDd' + row.id + '-' + rowIndex}
                    label={row['Name']}
                    value={row.id}
                  />
                );
              }
            })
          }
        </Picker>
      </View>);
  }
  return null;
}

const styles = StyleSheet.create({
  button: {
    marginLeft: '10px',
  },
  row: {
    display:'flex',
    flexGrow: 10,
    flexDirection:'row',
    alignContent:'center',
    paddingTop:'20px'
  },
  column: {
    width:'32%',
    marginRight: '1%'
  },
  picker : {
    backgroundColor:'#e7e7e7',
    color: '#000000',
    paddingTop: '29px',
    paddingBottom: '14px',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '16px',
    borderTopWidth: '0',
    borderLeftWidth: '0',
    borderRightWidth: '0',
    borderBottomWidth: '1px',
    borderBottomColor: '#ababab',
    //marginRight: '10px',
  },
  pickerItem : {
    fontSize: '16px'
  },
  smallLabel : {
    fontSize: '12px',
    top: '5px',
    position: 'absolute',
    color: '#6a6a6a',
    left: '12px'
  },
});
