import * as React from 'react';
import {Picker} from "@react-native-picker/picker";
import {View, Text, StyleSheet} from "react-native";
import ReportSelector from "./ReportSelector";
import {Button} from "react-native-paper";
import {getData, postData} from "../../helpers/api";
import {PowerforceContext} from "../../helpers/context";

export default function ReportManager(props){


    const powerforce = React.useContext(PowerforceContext);

    const [selectedCompany, setSelectedCompany] = React.useState(props.companies[0]);
    const [selectedWorkspace, setSelectedWorkspace] = React.useState('primary');
    const [selectedCompanyReports, setSelectedCompanyReports] = React.useState(null);
    const [selectedCompanyRoles, setSelectedCompanyRoles] = React.useState(null);
    const [selectedCompanyDatasets, setSelectedCompanyDatasets] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const getReports = () => {
        setLoading(true)
        getData(
          '/data/reportManager/' + selectedCompany.id + '/' + selectedWorkspace,
          powerforce.authToken
        ).then(response => {
          console.log(response);

          if(response.status !== 200){
            alert('Error fetching reports.');
          }else{
            setSelectedCompanyReports(response.reports);
            setSelectedCompanyRoles(response.roles);
            setSelectedCompanyDatasets(response.datasets);
          }
          setLoading(false)

        })
    }

    const updateReports = (updatedReports) => {
      console.log(updatedReports);
        setSelectedCompanyReports([...updatedReports]);
    }

    const saveReports = (reportChanges, callback) => {
        setLoading(true)

        console.log('reportChanges', reportChanges);
        postData(
            '/data/reportManager/' + selectedCompany.id + '/' + selectedWorkspace,
            powerforce.authToken,
            reportChanges
        ).then(response => {
            console.log(response);

            if(response.status !== 200){
                alert('Error saving reports. Details: ' + response.detail);
                callback()
                getReports()
            }else{
                callback()
                getReports()
            }


        })
    }

    return (
      <View style={{display: "block", width: "100%"}} dataSet={{'code': props.code}}>

        <View style={{flexDirection: "row", marginBottom:"50px"}}>
          <Text style={styles.textLabel}>Company: </Text>
          <Picker
            selectedValue={selectedCompany ? selectedCompany.id : 0}
            onValueChange={(itemValue, itemIndex) => {
                console.log(itemValue);
                console.log(props.companies);
                let company = props.companies.find(x => x.id.toString() === itemValue.toString())
                console.log(company);
                setSelectedCompany(company)
            }}
          >
            <Picker.Item label={'Select a company'} value={0} key={0} />

            {props.companies.map(company => {
              return(<Picker.Item label={company.title} value={company.id} key={company.id} />)
            })}

          </Picker>

          <Text style={styles.textLabel}>Workspace: </Text>
          <Picker
            selectedValue={selectedWorkspace}
            onValueChange={(itemValue, itemIndex) => {
              setSelectedWorkspace(itemValue)
            }}
          >

            <Picker.Item label="Primary" value="primary" />
            <Picker.Item label="Secondary" value="secondary" />

          </Picker>

          <Button
            icon={null}
            mode={"contained"}
            onPress={getReports}
            style={{marginLeft: '30px'}}
          >
            Get Reports
          </Button>
        </View>



        <ReportSelector
          reports={selectedCompanyReports}
          roles={selectedCompanyRoles}
          saveReports={saveReports}
          updateReports={updateReports}
          loading={loading}
          datasets={selectedCompanyDatasets}
        />

      </View>


    )

}

const styles = StyleSheet.create({
    textLabel: {
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '10px',
    },
})
