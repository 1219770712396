import React, {useState, createContext, useEffect} from "react";
import {ActivityIndicator, TextInput, Button, DefaultTheme, Provider as PaperProvider} from 'react-native-paper';
import * as WebBrowser from "expo-web-browser";
import {makeRedirectUri, useAuthRequest, useAutoDiscovery} from "expo-auth-session";
import {basicLogin, getAzureInfo, getB2CUser, getLoginDisplayInfo, getUserInfo} from "./auth";
import {Text, View, Image, Pressable} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {NavigationContainer} from "@react-navigation/native";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import * as Linking from "expo-linking";
import Constants from "expo-constants";
import Module from "../components/Module/Module";
import { isEqual, isEmpty } from "lodash";
import {Feather} from "@expo/vector-icons";


//RN Paper Theme
const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: '#00264d',
    accent: '#f05d2a',
  },
};

const sso_client_id = Constants.manifest.extra.ssoClientId;
const sso_tenant_id = Constants.manifest.extra.ssoTenantId;
const sso_discovery_url = Constants.manifest.extra.ssoDiscoveryUrl;
const b2c_url = Constants.manifest.extra.b2cUrl;
const b2c_client_id = Constants.manifest.extra.b2cClientId;
const b2c_signin_param = Constants.manifest.extra.b2cSignInParam;

const webUrl = Constants.manifest.extra.webUrl;

export const PowerforceContext = createContext();

function PowerforceContextProvider(props){

  const [appState, setAppState] = useState(null);
  const [loginDisplayInfo, setLoginDisplayInfo] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const [failedAuth, setfailedAuth] = useState(null);
  const [failedAuthMessage, setFailedAuthMessage] = useState(null);

  //For Basic Auth
  const [creds, setCreds] = useState({username: '', password: ''});
  //const [password, setPassword] = useState({value: '', error: ''});

  /******** Authentication  ************/

  //Need this for browser popup
  WebBrowser.maybeCompleteAuthSession();

  //Build ssoAuthConfig
  const ssoAuthConfig = {
    clientId: sso_client_id,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    redirectUri: makeRedirectUri(),
    tenantId: sso_tenant_id,
    discoveryLink: 'https://login.microsoftonline.com/' + sso_tenant_id + '/v2.0'
  }

  //Use EXPO built in tools for getting token code

  //SSO
  //if(loginDisplayInfo !== null && loginDisplayInfo.loginMethod === 'sso'){
    const discovery = useAutoDiscovery(ssoAuthConfig.discoveryLink);
    const [request, response, promptAsync] = useAuthRequest(
      {
        clientId: ssoAuthConfig.clientId,
        scopes: ssoAuthConfig.scopes,
        redirectUri: makeRedirectUri(),
      },
      discovery
    );
  //}

  //B2C
  const b2cAuthConfig = {
    clientId: b2c_client_id,
    scopes: ['openid', 'offline_access'],
    redirectUri: webUrl,
  }
  const b2cdiscovery = {
    authorizationEndpoint : b2c_url + '/oauth2/v2.0/authorize'
  };
  const [b2crequest, b2cresponse, b2cpromptAsync] = useAuthRequest(
    {
      clientId: b2cAuthConfig.clientId,
      scopes: b2cAuthConfig.scopes,
      responseType: 'code',
      redirectUri: b2cAuthConfig.redirectUri,
      extraParams: {
        p: b2c_signin_param,
        nonce: 'defaultNonce',
        prompt: 'login',
      }
    },
    b2cdiscovery
  );

  //B2C2B2B
  const DoubleLoginButton = () => {
    const [isSSO, setIsSSO] = useState(false);

    useEffect(() => {
      let lastThreeOfUrl = window.location.href.substr(window.location.href.length - 3);
      if(lastThreeOfUrl === 'sso'){
        setIsSSO(true);
      }
    }, [])

    if(isSSO){
      return(
        <View style={{alignItems:'center'}}>
          <Button
            disabled={loginLoading}
            mode="contained"
            onPress={handleSsoAuth}
            color={loginDisplayInfo.accentColor}
            style={{fontSize: '3rem', marginTop: '20px', marginBottom: '80px'}}
          >
            My Business Login
          </Button>
          <Text
            style={{fontSize:'12px', color: '#798795', textAlign: 'center'}}
            onPress={() => {setIsSSO(false)}}
          >
            Login with EAG Login
          </Text>
        </View>
      )
    }else{
      return(
        <View style={{alignItems:'center'}}>
          <Button
            disabled={loginLoading}
            mode="contained"
            onPress={handlB2C}
            color={loginDisplayInfo.accentColor}
            style={{fontSize: '3rem', marginTop: '20px', marginBottom: '80px'}}
          >
            Login
          </Button>
          <Text
            style={{fontSize:'12px', color: '#798795', textAlign: 'center'}}
            onPress={() => {setIsSSO(true)}}
          >
            Login with My Business Login
          </Text>
        </View>
      )
    }


  }

  //Handles B2C -> B2B Auth on button click
  const handlB2C2B2B = async() => {

    setLoginLoading(true);
    let b2cLoginResponse = await b2cpromptAsync();
    //console.log(b2cLoginResponse);

    // Is there an error with B2C?
    if(b2cLoginResponse.error){

      // Failed B2C - show fake message and try B2B
      setfakeUserFound(true);
      setLoginLoading(false);

    }else{

      // Successful auth B2C - now to auth with the app
      let b2cUserToken = await getB2CUser(b2cLoginResponse, b2cAuthConfig, b2crequest.codeVerifier);
      let userConfig = await getUserInfo(b2cUserToken);

      if(userConfig.error){

        //Error Authenticating
        setfailedAuth(true);
        if(b2cUserToken){
          setFailedAuthMessage(b2cUserToken.message);
        }else{
          setFailedAuthMessage('Something went wrong.');
        }

      }else{

        //Authenticated
        //Set new token in asyncstorage
        await AsyncStorage.setItem('foundationsUserConfig', JSON.stringify(userConfig))
        //Set app state
        setAppState(userConfig);
      }

    }



  }

  //Handles B2C Auth on button click
  const handlB2C = async() => {

    setLoginLoading(true);
    let b2cLoginResponse = await b2cpromptAsync();
    //console.log(b2cLoginResponse);

    if(b2cLoginResponse.type === 'dismiss'){
      setfailedAuth(true);
      setFailedAuthMessage('Authentication failed. Please try again, and do not close the authentication window.');
    }else{

      let b2cUserToken = await getB2CUser(b2cLoginResponse, b2cAuthConfig, b2crequest.codeVerifier);

      if(b2cUserToken.error === 'error'){

        setfailedAuth(true);
        setFailedAuthMessage(b2cUserToken.message);

      }else{

        let userConfig = await getUserInfo(b2cUserToken);

        if(userConfig.error){

          //Error Authenticating
          setfailedAuth(true);

          if(userConfig.message !== ''){
            setFailedAuthMessage(userConfig.detail);
          }else{
            setFailedAuthMessage('Something went wrong.');
          }


        }else{

          //Authenticated

          //Set new token in asyncstorage
          await AsyncStorage.setItem('foundationsUserConfig', JSON.stringify(userConfig))

          //Set app state
          //console.log(userConfig)
          setAppState(userConfig);

        }
      }


    }


    setLoginLoading(false);

  }

  //Handles SSO (B2B) authentication on login button click
  const handleSsoAuth = async() => {
    setLoginLoading(true);

    let loginResponse = await promptAsync();
    //console.log(loginResponse);

    if(loginResponse.type === 'dismiss'){
      setfailedAuth(true);
      setFailedAuthMessage('Authentication failed. Please try again, and do not close the authentication window.');
    }else{
      //If changing auth provider the variables below need to made extensible
      let newToken = await getAzureInfo(loginResponse, ssoAuthConfig, request.codeVerifier);
      let userConfig = await getUserInfo(newToken);

      if(userConfig.error){
        //Error Authenticating

        setfailedAuth(true);
        if(userConfig.messageKey){
          setFailedAuthMessage(userConfig.messageKey);
        }else{
          setFailedAuthMessage(userConfig);
        }

        //setfakeUserFound(false);

      }else{
        //Authenticated

        //Set new token in asyncstorage
        await AsyncStorage.setItem('foundationsUserConfig', JSON.stringify(userConfig))

        //Set app state
        setAppState(userConfig);
      }
    }



    setLoginLoading(false);

  }

  //Handles UN PW Login
  const handleBasicAuth = async() => {
    setLoginLoading(true);

    let basicLoginToken = await basicLogin(creds);
    //console.log(basicLoginToken);
    if(basicLoginToken.error){
      setfailedAuth(true);
      setFailedAuthMessage(basicLoginToken.error);
      return;
    }

    let userConfig = await getUserInfo(basicLoginToken);
    if(userConfig.error){
      //Error Authenticating

      setfailedAuth(true);
      setFailedAuthMessage(userConfig);

    }else{
      //Authenticated

      //Set new token in asyncstorage
      await AsyncStorage.setItem('foundationsUserConfig', JSON.stringify(userConfig))

      //Set app state
      setAppState(userConfig);
    }

    setLoginLoading(false)
  }

  //Check if logged in already on first load
  useEffect(async () => {

    console.log('Check display info');
    let displayInfo = await AsyncStorage.getItem('displayInfo');
    //console.log('DISPLAY INFO', displayInfo);

    console.log('Checking async storage for user config');
    let userConfig = await AsyncStorage.getItem('foundationsUserConfig');

    let newLoginDisplayInfo = null;
    if(displayInfo){
      newLoginDisplayInfo = JSON.parse(displayInfo);
      setLoginDisplayInfo(newLoginDisplayInfo);
    }else{
      newLoginDisplayInfo = await getLoginDisplayInfo();
      await AsyncStorage.setItem('displayInfo', JSON.stringify(newLoginDisplayInfo));
      setLoginDisplayInfo(newLoginDisplayInfo);
    }


    if(userConfig){

      //Config found in Async storage
      console.log('User config found in Async Storage.');
      userConfig = JSON.parse(userConfig);
      //console.log(userConfig);

      if(userConfig.error || isEmpty(userConfig) || !Object.hasOwn(userConfig, 'modules') ){

        console.log('Error in User config found in Async Storage. Removing async user config.');
        await AsyncStorage.removeItem('foundationsUserConfig');

        setAppState(null);
        let newLoginDisplayInfo = await getLoginDisplayInfo();
        setLoginDisplayInfo(newLoginDisplayInfo)

      }else{

        console.log('No error in async userconfig.');
        setAppState(userConfig);

        console.log('Checking for updates.');
        let updatedUserConfig = await getUserInfo(userConfig.authToken);
        if(!isEqual(updatedUserConfig, userConfig)){

          console.log('Userconfig needs updating.');
          //console.log('updatedUserConfig', updatedUserConfig);
          //console.log('userConfig', userConfig);

          setAppState({...updatedUserConfig});
          await AsyncStorage.setItem('foundationsUserConfig', JSON.stringify(updatedUserConfig));

        }

      }

    }

    // Double check login info
    newLoginDisplayInfo = await getLoginDisplayInfo();
    await AsyncStorage.setItem('displayInfo', JSON.stringify(newLoginDisplayInfo));
    setLoginDisplayInfo(newLoginDisplayInfo);


    //Cleanup
    return () => {
      setLoginDisplayInfo(newLoginDisplayInfo);
    }

  }, []);


  //Login Page
  const LoginPage = () =>  {
    if(loginDisplayInfo.loginMethod === 'sso'){

      return (
        <View style={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: loginDisplayInfo.primaryColor,
          height: '100vh'
        }}>
          <Image
            source={{uri: loginDisplayInfo.logoUrl}}
            style={{
              width: 179,
              height: 75,
            }}
          />
          <Button
            disabled={loginLoading}
            mode="contained"
            onPress={handleSsoAuth}
            color={loginDisplayInfo.accentColor}
            style={{fontSize: '3rem', marginTop: '20px', marginBottom: '20px'}}
          >
            Login
          </Button>
          <ActivityIndicator
            size="large"
            color={loginDisplayInfo.accentColor}
            style={ loginLoading ? {} : {display:'none'} }
          />
        </View>
      )

    }else if(loginDisplayInfo.loginMethod === 'b2c'){
      return (
        <View style={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: loginDisplayInfo.primaryColor,
          height: '100vh'
        }}>
          <Image
            source={{uri: loginDisplayInfo.logoUrl}}
            style={{
              width: 179,
              height: 75,
            }}
          />
          <Button
            disabled={loginLoading}
            mode="contained"
            onPress={handlB2C}
            color={loginDisplayInfo.accentColor}
            style={{fontSize: '3rem', marginTop: '20px', marginBottom: '20px'}}
          >
            Login
          </Button>
          <ActivityIndicator
            size="large"
            color={loginDisplayInfo.accentColor}
            style={ loginLoading ? {} : {display:'none'} }
          />
        </View>
      )
    }else if(loginDisplayInfo.loginMethod === 'b2c2b2b'){

      return (
        <View style={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: loginDisplayInfo.primaryColor,
          height: '100vh'
        }}>
          <Image
            source={{uri: loginDisplayInfo.logoUrl}}
            style={{
              width: 179,
              height: 75,
            }}
          />
          <DoubleLoginButton />
          <ActivityIndicator
            size="large"
            color={loginDisplayInfo.accentColor}
            style={ loginLoading ? {} : {display:'none'} }
          />
        </View>
      )
    }else{

      return (
        <View style={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: loginDisplayInfo.primaryColor,
          color: loginDisplayInfo.accentColor,
          height: '100vh'
        }}>
          <Image
            source={{uri: loginDisplayInfo.logoUrl}}
            style={{
              width: 179,
              height: 75,
            }}
          />
          <TextInput
            value={creds.username}
            onChangeText={(text) => {
              let newCreds = creds;
              newCreds.username = text;
              setCreds(newCreds)
            }}
            placeholder={'Username'}
            style={{
              backgroundColor: loginDisplayInfo.primaryColor,
              marginTop: '20px',
            }}
            theme={{ colors: { text: loginDisplayInfo.accentColor } }}
            underlineColor={loginDisplayInfo.accentColor}
            cursorColor={loginDisplayInfo.accentColor}
            activeUnderlineColor={loginDisplayInfo.accentColor}
            placeholderTextColor={loginDisplayInfo.accentColor}
          />
          <TextInput
            value={creds.password}
            onChangeText={(text) => {
              let newCreds = creds;
              newCreds.password = text;
              setCreds(newCreds)
            }}
            placeholder={'Password'}
            secureTextEntry={true}
            style={{
              backgroundColor: loginDisplayInfo.primaryColor,
              marginTop: '20px',
            }}
            theme={{ colors: { text: loginDisplayInfo.accentColor } }}
            underlineColor={loginDisplayInfo.accentColor}
            activeUnderlineColor={loginDisplayInfo.accentColor}
            cursorColor={loginDisplayInfo.accentColor}
            placeholderTextColor={loginDisplayInfo.accentColor}
            onSubmitEditing={handleBasicAuth}
          />
          <Button
            disabled={loginLoading}
            mode="contained"
            onPress={handleBasicAuth}
            color={loginDisplayInfo.accentColor}
            style={{fontSize: '3rem', marginTop: '20px', marginBottom: '20px'}}
          >
            Login
          </Button>
          <ActivityIndicator
            size="large"
            color={loginDisplayInfo.accentColor}
            style={ loginLoading ? {} : {display:'none'} }
          />
        </View>
      )

    }
  };

  const LoadingPage = () => {
    return (
        <View style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}>
          <ActivityIndicator size="large" color="#00264d" />
        </View>
    );
  }

  //Let components remove appState
  const removeAppState = () => {
    console.log('removeAppState from context');
    setAppState(null);
  }

  //RETURNS

  if(failedAuth){
    return (
      <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: loginDisplayInfo.primaryColor,
        color: loginDisplayInfo.accentColor,
        height: '100vh'
      }}>
        <Image
          source={{uri: loginDisplayInfo.logoUrl}}
          style={{
            width: 179,
            height: 75,
            paddingBottom: '4%'
          }}
        />
        <Text style={{fontSize:20, paddingBottom: '1%', color: loginDisplayInfo.accentColor,}}>
          Authentication Failed
        </Text>
        <Text style={{fontSize:14, paddingBottom: '1%', color: loginDisplayInfo.accentColor,}}>
          {JSON.stringify(failedAuthMessage)}
        </Text>
        <Button
          mode="contained"
          onPress={ () => {setfailedAuth(null); setLoginLoading(false); }}
          color={loginDisplayInfo.accentColor}
          style={{fontSize: '3rem', marginTop: '20px', marginBottom: '20px'}}
        >
          Retry
        </Button>
      </View>
    )
  }

  if(!appState){

      if(!loginDisplayInfo){

        return <LoadingPage />;

      }else{
        //
        return ( <LoginPage /> );

      }

  }else{

    //App has state - Initiate app
    //console.log(appState);

    //If appstate has an error, or is empty, show login
    if(appState.error || isEmpty(appState)){
      return ( <LoginPage /> );
    }

    if(isEmpty(appState.routeConfig.screens)){
        return (
          <View style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}>
            <Text>
              Your User account has not been granted access yet. Please contact an administrator.
            </Text>
            <Pressable
              onPress={ async () => {
                await AsyncStorage.removeItem('foundationsUserConfig');
                window.location.reload();
              } }
              style={{marginTop:'20px', display:'flex', flexDirection:'row', backgroundColor: loginDisplayInfo.accentColor, padding: '5px', borderRadius: '10px'}}
            >
              <Text>
                Logout
              </Text>
            </Pressable>
          </View>
        )
    }

    const Stack = createNativeStackNavigator();
    const config = appState.routeConfig;

    const linking = {
      prefixes: [Linking.createURL('/'), Constants.manifest.extra.webUrl],
      config
    };

    //Add ability to remove app state from screens
    appState.removeAppState = removeAppState;

    return (
      <PowerforceContext.Provider value={appState}>
        <PaperProvider theme={theme}>
          <NavigationContainer
            linking={linking}
            fallback={<Text>Loading...</Text>}
            documentTitle={{
              formatter: (options, route) =>
                route.name.toUpperCase() + ' - EAG Foundations'
            }}
          >
            <Stack.Navigator
              initialRouteName="home"
              screenOptions={{
                headerShown: false
              }}
            >

              { //Set up Modules

                appState.modules.map((module, index) => {

                  return(
                    <Stack.Screen
                      key={index}
                      name={module.url}
                      component={Module}
                    />

                  )

                })
              }

              {/* Include Admin
              <Stack.Screen
                key={999}
                name={'superadmin'}
                component={Admin}
              />*/}

            </Stack.Navigator>
          </NavigationContainer>
        </PaperProvider>
      </PowerforceContext.Provider>
    );

  }



};

export default PowerforceContextProvider;
