import {ScrollView, View} from "react-native-web";
import {StyleSheet, Text} from "react-native";
import {Feather} from "@expo/vector-icons";
import * as React from "react";
import {Button, Dialog, Portal, List, DataTable, ActivityIndicator} from "react-native-paper";
import * as DocumentPicker from "expo-document-picker";
import * as Papa from "papaparse/papaparse"
import alasql from "alasql";
import * as XLSX from 'xlsx';
import {PowerforceContext} from "../../helpers/context";
import {randomKey} from "../../helpers/general";

alasql.utils.isBrowserify = false;
alasql.utils.global.XLSX = XLSX;

export const UploadPopup = (props) => {

  const powerforce = React.useContext(PowerforceContext);

  //State for Filter popup
  const [isUploadPopupVisible, setIsUploadPopupVisible] = React.useState(props.visible);
  const [hasFileBeenUploaded, setHasFileBeenUploaded] = React.useState(false);
  const [updates, setUpdates] = React.useState([]);
  const [additions, setAdditions] = React.useState([]);
  const [deletions, setDeletions] = React.useState([]);

  const [hasImportButtonBeenClicked, setHasImportButtonBeenClicked] = React.useState(false);
  const [isImportDone, setIsImportDone] = React.useState(props.isImportDone);

  //For Changing Props
  React.useEffect(() => {
    setIsUploadPopupVisible(props.visible);
    setIsImportDone(props.isImportDone);
  }, [props]);

  const showFilterPopup = () => setIsUploadPopupVisible(true);
  const hideFilterPopup = () => {
    setIsUploadPopupVisible(false);
    setHasFileBeenUploaded(false);
    setHasImportButtonBeenClicked(false);
    setIsImportDone(false);
    setUpdates([]);
    setAdditions([]);
    setDeletions([]);
    props.onClose();
  }
  const importDoneAndHideFilterPopup = () => {
    props.onImportDone();
    hideFilterPopup();
  }

  const executeImport = () => {
    setHasImportButtonBeenClicked(true)
    props.executeImport(updates, additions, deletions);
  }

  //Document State
  const [file, setFile] = React.useState();
  const allowedMimeTypes = [
    "text/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel"
  ];

  const chooseFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({ type: allowedMimeTypes, copyToCacheDirectory: true }).then(response => {
      if (response.type === 'success') {
        let nameParts = response.name.split('.');
        let fileType = nameParts[nameParts.length - 1];

        //Check if we allow the type of file that has been uploaded
        if(allowedMimeTypes.indexOf(response.mimeType) === -1){
          //console.log('NOT ALLOWED FILETYPE')
          return;
        }

        //Parse CSV with Papa - It's REAL fast
        if(fileType === 'csv'){
          Papa.parse(response.file, {
            header: true,
            complete: function(results) {
              createChangesList(results.data);
            }
          });
        }
        //Accept XLSX with alasql and xlsx
        if(fileType === 'xlsx'){
          alasql.promise('SELECT * FROM xlsx(?, {autoExt: false})', [response.uri]).then(function(results){
            createChangesList(results);
          }).catch(console.error);
        }

        //Accept XLS with alasql and xlsx
        if(fileType === 'xls'){
          alasql.promise('SELECT * FROM xls(?, {autoExt: false})', [response.uri]).then(function(results){
            createChangesList(results);
          }).catch(console.error);
        }


      }else{
        //ERROR Upon uploading here
        console.log('UPLOAD ERROR')
      }
    });
  }

  function createChangesList(fileData){
    let result = {updates: [], additions: [], deletions: []};
    fileData.map(record => {

      for (const [key, value] of Object.entries(record)) {
        if(key.toLowerCase().includes('date')){
          record[key] = ExcelDateToJSDate(value);
        }
      }

      if(record.changes){
        if(record.changes.toLowerCase() === 'update'){
          result.updates.push(record);
        }

        if(record.changes.toLowerCase() === 'add'){
          result.additions.push(record);
        }

        if(record.changes.toLowerCase() === 'delete'){
          result.deletions.push(record);
        }
      }

      if(record['Changes']){
        if(record['Changes'].toLowerCase() === 'update'){
          result.updates.push(record);
        }

        if(record['Changes'].toLowerCase() === 'add'){
          result.additions.push(record);
        }

        if(record['Changes'].toLowerCase() === 'delete'){
          result.deletions.push(record);
        }
      }

      if(record['CHANGES']){
        if(record['CHANGES'].toLowerCase() === 'update'){
          result.updates.push(record);
        }

        if(record['CHANGES'].toLowerCase() === 'add'){
          result.additions.push(record);
        }

        if(record['CHANGES'].toLowerCase() === 'delete'){
          result.deletions.push(record);
        }
      }

    })

    //Set Data
    setUpdates([...result.updates])
    setAdditions([...result.additions])
    setDeletions([...result.deletions])
    setHasFileBeenUploaded(true);

    return result;
  }

  function ExcelDateToJSDate(serial) {
    let utc_days  = Math.floor(serial - 25569);
    let utc_value = utc_days * 86400;
    let date_info = new Date(utc_value * 1000);

    let fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    let seconds = total_seconds % 60;

    total_seconds -= seconds;

    let hours = Math.floor(total_seconds / (60 * 60));
    let minutes = Math.floor(total_seconds / 60) % 60;

    let returnDate = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds).toLocaleDateString();

    //If return date is invalid, try regular JS date functionality
    if(returnDate === 'Invalid Date'){
      returnDate = new Date(serial);
    }

    return returnDate;
  }

  //Dependent Views
  const content = () => {

    if(isImportDone){
      return (
        <View>
          <Text style={{textAlign:'center'}}>{props.importDoneText}</Text>
          <Button
            onPress={() => importDoneAndHideFilterPopup()}
            style={styles.button}
            mode={'contained'}
          >
            <Text>Close Popup</Text>
          </Button>
        </View>
      );
    }

    if(hasImportButtonBeenClicked){
      return (
        <View>
          <Text style={{textAlign:'center'}}>Importing Data... Do not leave this page.</Text>
          <ActivityIndicator animating={true} color={powerforce.colors.primary} />
        </View>
      );
    }

    if(!hasFileBeenUploaded){
      return(
        <View>
          <Text style={{paddingBottom: '15px'}}>
            Upload a file to make mass changes. Accepted file types are CSV, XLSX, & XLS.
          </Text>
          <Button
            onPress={() => chooseFile()}
            style={{marginBottom:'20px'}}
            mode={'contained'}
          >
            <Feather name="file-plus" size={16} color="#ffffff" />
            <Text>Choose File</Text>
          </Button>

        </View>
      );
    }

    return(
      <View>
        <Text style={{paddingBottom: '15px'}}>
          Upload a file to make mass changes. Accepted file types are CSV, XLSX, & XLS.
        </Text>
        <Button
          onPress={() => chooseFile()}
          style={{marginBottom:'20px'}}
          mode={'contained'}
        >
          <Feather name="file-plus" size={16} color="#ffffff" />
          <Text>Choose a different file</Text>
        </Button>

        <ChangesAccordion
          title={"Updates"}
          icon={'square-edit-outline'}
          items={updates}
        />
        <ChangesAccordion
          title={"Additions"}
          icon={'plus-circle-outline'}
          items={additions}
        />
        <ChangesAccordion
          title={"Deletions"}
          icon={'delete-forever'}
          items={deletions}
        />
        <Button
          onPress={() => executeImport()}
          style={{marginBottom:'20px', marginTop: '30px'}}
          color={'#FF0000'}
          mode={'contained'}
        >
          <Feather name="file-plus" size={16} color="#ffffff" />
          <Text>Execute Import</Text>
        </Button>
      </View>
    )

  }

  /**
   * Display
   */

  return (

    <Portal>
      <Dialog
        visible={isUploadPopupVisible}
        onDismiss={hideFilterPopup}
        style={{maxWidth:'500px', minWidth: '50%', marginLeft: 'auto', marginRight: 'auto'}}
      >
        <Dialog.Title>Import Data</Dialog.Title>
        <Dialog.Content>

          {content()}


        </Dialog.Content>

        <Dialog.Actions>

          <Button
            onPress={() => hideFilterPopup()}
            style={styles.button}
            mode={'contained'}
          >
            <Text>Cancel</Text>
          </Button>

        </Dialog.Actions>

      </Dialog>
    </Portal>

  )

}

const ChangesAccordion = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handlePress = () => setExpanded(!expanded);

  return (

      <List.Accordion
        title={props.title ? props.title + ' - ' + props.items.length : 'Changes'}
        left={() => <List.Icon icon={props.icon} />}
        //right={() => <Text>{props.items.length}</Text>}
        expanded={expanded}
        onPress={handlePress}
        //style={{marginTop: '20px', marginBottom: '20px'}}
      >
        <ChangesTable
          data={props.items}
        />
      </List.Accordion>
  );
};

const ChangesTable = (props) => {

  const [allData, setAllData] = React.useState(props.data);
  const [headerData, setheaderData] = React.useState(allData[0] ? Object.keys(allData[0]) : []);


  //State For Pagination
  const numberOfItemsPerPageList = [10, 20, 50, 100];
  const [tableData, setTableData] = React.useState(allData.slice(0, numberOfItemsPerPageList[0]));
  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, allData.length);

  //Change Page
  const changePage = (page) => {
    setPage(page);
  }
  React.useEffect(() => {
    let newData = allData.slice(from, from+numberOfItemsPerPage);
    setTableData(newData);
  }, [page]);

  //Change Items Per Page
  const changeItemsPerPage = (numberOfItemsPerPage) => {
    onItemsPerPageChange(numberOfItemsPerPage);
  }
  React.useEffect(() => {
    setPage(0);
    let newData = allData.slice(from, from+numberOfItemsPerPage);
    setTableData(newData);
  }, [numberOfItemsPerPage]);



  return (
    <DataTable>
      <DataTable.Header>
        {
          headerData.map(header => {
            return(
              <DataTable.Title
                key={header}
                //onPress={(e) => {clickGridHead(e)}}
              >
                {header}
              </DataTable.Title>
            )
          })
        }
      </DataTable.Header>

      <ScrollView>
        {
          tableData.map(datum => {
            return (

              <DataTable.Row
                key={randomKey(22)}
                //onPress={() => console.log('row pressed')}
              >
                {
                  headerData.map(header => {
                    let cellId = datum.id + header;
                    let value;

                    //Make sure value isn;t null
                    if(datum[header] === null){
                      value = '';
                    }else{
                      value = datum[header];
                    }

                    return(
                      <DataTable.Cell
                        key={cellId}
                        //onPress={() => editCell(datum['id'], value)}
                      >
                        {JSON.stringify(value)}
                      </DataTable.Cell>
                    )
                  })
                }
              </DataTable.Row>
            )
          })
        }
      </ScrollView>

      <DataTable.Pagination
        page={page}
        numberOfPages={Math.ceil(allData.length / numberOfItemsPerPage)}
        onPageChange={page => changePage(page)}
        label={`${from + 1}-${to} of ${allData.length}`}
        numberOfItemsPerPageList={numberOfItemsPerPageList}
        numberOfItemsPerPage={numberOfItemsPerPage}
        onItemsPerPageChange={changeItemsPerPage}
        selectPageDropdownLabel={'Rows per page'}
      />
    </DataTable>
  );


}


const styles = StyleSheet.create({
  button: {
    marginLeft: '10px',
  },
  row: {
    display:'flex',
    flexGrow: 10,
    flexDirection:'row',
    alignContent:'center',
    paddingTop:'20px'
  },
  column: {
    width:'32%',
    marginRight: '1%'
  }
});
