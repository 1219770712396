import {Pressable, ScrollView, StyleSheet, Text, View} from 'react-native';
import React, {useContext, useState} from "react";
import TopNavigationBar from "../../components/TopNavigationBar/TopNavigationBar";
import SideNavigationBar from "../../components/SideNavigationBar/SideNavigationBar";
import {PowerforceContext} from "../../helpers/context";
import Screen from "../Screen/Screen";
import BottomNavigationBar from "../TopNavigationBar/BottomNavigationBar";
import {Desktop, Tablet, Mobile } from '../../helpers/responsive'
import { useMediaQuery } from 'react-responsive'
import {Feather} from "@expo/vector-icons";

export default function Module(props) {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      height: isTabletOrMobile ? 'calc(100vh - 57px)' : '90vh'
    },
    menuButton: {
      position:'fixed',
      bottom: '100px',
      left: '0px'
    },
    menuButtonContent: {
      width: '45px',
      height: '50px',
      flexDirection: 'row-reverse'
    },
    mobileMenu: {
      position: 'fixed',
      left: '0px',
      top: '0px',
    }
  });

  const powerforce = useContext(PowerforceContext);
  let module = powerforce.modules.find(x => x.url === props.route.name);

  //This uses screens as a default if there is no set sidebar
  let sideNavItems = module.sideNav.length > 0 ? module.sideNav : module.screens;

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const toggleMobileMenu = () => {
    setShowMobileMenu(current => !current);
  };

  const [showTopMenu, setShowTopMenu] = useState(true);
  const toggleTopMenu = () => {
    setShowTopMenu(current => !current);
  };

  const [showSideMenu, setShowSideMenu] = useState(true);
  const toggleSideMenu = () => {
    setShowSideMenu(current => !current);
  };


  const MobileMenu = () => {
    if(showMobileMenu){
      return(
        <View style={styles.mobileMenu}>
          <SideNavigationBar
            navigation={props.navigation}
            sideNavItems={sideNavItems}
            showSideMenu={showSideMenu}
          />
        </View>
      )
    }else{
      return(<View style={{display:'none'}}></View>)
    }
  }

  return (

    <View>

      <Desktop>
        <TopNavigationBar navigation={props.navigation} showTopMenu={showTopMenu} />
      </Desktop>

      <View style={{ flexDirection:"row" }}>

        <Desktop>
          <SideNavigationBar
            navigation={props.navigation}
            sideNavItems={sideNavItems}
            showSideMenu={showSideMenu}
          />
        </Desktop>

        <ScrollView style={styles.container}>

          <Screen moduleData={module}
                  route={props.route}
                  navigation={props.navigation}
                  toggleSideMenu={toggleSideMenu}
                  toggleTopMenu={toggleTopMenu}
          />

        </ScrollView>

      </View>

      <Mobile>
        <MobileMenu />
        <BottomNavigationBar
          navigation={props.navigation}
          showMobileMenu={showMobileMenu}
          onMenuButtonClick={() => toggleMobileMenu()}
        />
      </Mobile>

      <Tablet>
        <MobileMenu />
        <BottomNavigationBar
          navigation={props.navigation}
          showMobileMenu={showMobileMenu}
          onMenuButtonClick={() => toggleMobileMenu()}
        />
      </Tablet>

    </View>

  );
}


