import * as React from 'react';
import {Pressable, Text, StyleSheet} from 'react-native';
import {View, Image} from "react-native-web";
import { Feather } from '@expo/vector-icons';
import {Desktop, Tablet, Mobile } from '../../helpers/responsive'




const SimpleMobileNav = (props) => {

  if(!props.show){
    return null;
  }

  return (


      <View style={styles.moduleContainer} nativeID={'botnav'}>
        <View style={styles.navBar}>

          {
            props.navItems.map((navItem, index) => {
              return(
                <Pressable
                  onPress={() => props.navigation.navigate(navItem.route, { screenName: navItem.screen})}
                  style={styles.navItem}
                  key={index}
                  nativeID={'botnav-'+navItem.route}
                >
                    <Feather name={navItem.icon} size={20} color="white" />
                    <Text style={styles.navText}>{navItem.text}</Text>

                </Pressable>
              )
            })
          }

        </View>
      </View>


  )

}

export default SimpleMobileNav;

const styles = StyleSheet.create({
  navItem: {
    textAlign:'center',
    padding: '10px',
    flex: 1
  },
  navText: {
    color:'#ffffff',
    fontSize: 9,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  navBar: {
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 8,
    alignItems: "center"
  },
  moduleContainer: {
    flexDirection:"row",
    position:'fixed',
    bottom: '70px',
    backgroundColor: 'rgb(0, 38, 77)',
    left: '2px',
    right: '2px',
    borderRadius: '20px'
  },
});
