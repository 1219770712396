import { List } from 'react-native-paper';
import React from "react";
import {View} from "react-native";
import Constants from "expo-constants";


export const AccordionList = (props) => {

  const [items, setItems] = React.useState(props.items);
  const [icon, setIcon] = React.useState(props.icon);

  const apiUrl = Constants.manifest.extra.apiUrl;

  const onPress = (cwid) => {
    let url = '';
    if( apiUrl === 'https://eagpowerforce.com/api'){
      url = 'https://connect.eag1source.com/';
    }else{
      url = 'https://cwtest.eag1source.com/';
    }
    url += 'v4_6_release/services/system_io/Service/fv_sr100_request.rails?';
    url += 'service_recid='+cwid;
    url += '&companyName=eag1source';
    window.open(url, '_blank')
  }
  //No list items, item
  const listItem = <List.Item
    title={'There are no items in this list.'}
    description={''}
    left={props => <List.Icon {...props} icon={'close'} />}
  />;

  return (
    <View>
      <List.Accordion
        title={props.label}
        description={props.text}
        left={props => <List.Icon {...props} icon={icon} />}
      >
      {
        items.map(item => {

          return(
            <List.Item
              key={item.id}
              title={item.summary}
              description={item.type}
              left={props => <List.Icon {...props} icon={'open-in-new'} />}
              onPress={() => onPress(item.cwid)}
            />
          )

        })
      }

        {items.length === 0 ? listItem : null}
      </List.Accordion>
    </View>

  )

}
