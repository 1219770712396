import * as React from 'react';
import { Checkbox } from 'react-native-paper';

const CheckboxInput = React.forwardRef((props,ref) => {

  const [checked, setChecked] = React.useState(props.value);

  return (
    <Checkbox
      status={checked ? 'checked' : 'unchecked'}
      forwardedRef={ref}
      color={props.color}
      disabled={props.disabled}
      onPress={() => {
        if(!props.disabled){
          setChecked(!checked);
          props.onChange(props.dataSource, !checked, props.sectionIndex )
        }
      }}
    />
  );
});

export default CheckboxInput;
