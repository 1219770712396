import Constants from 'expo-constants';


//Variables needed
const loginUrl = Constants.manifest.extra.webUrl + '/login';
const b2cLoginUrl = Constants.manifest.extra.webUrl + '/login/b2c';
const basicLoginUrl = Constants.manifest.extra.webUrl + '/login/basic';
const loginDisplayInfoUrl = Constants.manifest.extra.webUrl + '/login/display';


// TODO REDO SSO
//Initiates popup and logs in
//Then initiates getAuthToken if successful
export const getAzureInfo = async(loginResponse, config, codeVerifier) => {

  if(loginResponse.type === 'success'){

    return getAuthToken(loginResponse.params.code, config, codeVerifier);

  }else{
    return {
      "error": "Authentication unsuccessful",
      ...loginResponse
    }
  }
}

const getAuthToken = async(code, config, codeVerifier) => {

  //Get Params for URL
  let tokenParameters = {
    client_id: config.clientId,
    //scope: config.scopes,
    code: code,
    redirect_uri: config.redirectUri,
    grant_type: 'authorization_code',
    code_verifier: codeVerifier
  }

  //Create request body
  let requestBody = [];
  for (let param in tokenParameters) {
    let encodedKey = encodeURIComponent(param);
    let encodedValue = encodeURIComponent(tokenParameters[param]);
    requestBody.push(encodedKey + '=' + encodedValue);
  }
  requestBody = requestBody.join('&');

  //Send request to MS for User Info
  let tokenResponse = null;
  await fetch('https://login.microsoftonline.com/'+config.tenantId+'/oauth2/v2.0/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: requestBody,
  })
    .then((response) => response.json())
    .then((response) => {
      tokenResponse = response;
    })
    .catch((error) => {
      console.error(error);
    });

  //return await getUserInfo(tokenResponse.access_token);
  return tokenResponse.access_token;

}

export const basicLogin = async(creds) => {

  let body = {
    "username": creds.username,
    "password": creds.password
  };

  let tokenResponse = null;
  await fetch(basicLoginUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      if(response.error){
        tokenResponse = response;
      }else{
        tokenResponse = response.token;
      }
    })
    .catch((error) => {
      tokenResponse = error;
    });

  return tokenResponse;
}

export const getB2CUser = async(b2cLoginResponse, config, codeVerifier) => {

  let tokenResponse = null;

  await fetch(b2cLoginUrl, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      b2cLoginResponse: b2cLoginResponse,
      codeVerifier: codeVerifier,
      redirectUri: config.redirectUri,
    })
  })
    .then((response) => response.json())
    .then((json) => {
      if(json.error){
        tokenResponse = json;
      }else{
        tokenResponse = json.token;
      }
    })
    .catch((error) => {
      tokenResponse = error;
    });

  return tokenResponse;

}

export const getUserInfo = async(token) => {

  let powerforceLoginResponse = null;
  await fetch(loginUrl, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'X-AUTH-TOKEN': token,
    }
  })
    .then((response) => response.json())
    .then((json) => {
      powerforceLoginResponse = json;
    })
    .catch((error) => {
      powerforceLoginResponse = error;
    });

  return powerforceLoginResponse;
}

export const getLoginDisplayInfo = async() => {

  let loginDisplayInfoResponse = null;
  await fetch(loginDisplayInfoUrl, {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then((response) => response.json())
    .then((json) => {
      loginDisplayInfoResponse = json;
    })
    .catch((error) => {
      loginDisplayInfoResponse = error;
    });

  return loginDisplayInfoResponse;
}


