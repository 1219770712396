import {ScrollView, View} from "react-native-web";
import {StyleSheet, Text} from "react-native";
import {Feather} from "@expo/vector-icons";
import * as React from "react";
import {Button, Checkbox, Dialog, Portal, TextInput} from "react-native-paper";
import {PowerforceContext} from "../../helpers/context";
import {randomKey} from "../../helpers/general";
import {isDesktop} from "../../helpers/responsive";
import {Picker} from "@react-native-picker/picker";


export const EditDataPopup = (props) => {

  const powerforce = React.useContext(PowerforceContext);

  const [isPopupVisible, setIsPopupVisible] = React.useState(props.visible);

  const [parentCategoryId, setParentCategoryId] = React.useState(props.data ? props.data[props.hierarchyKey] : '');
  const [mainAccountCode, setMainAccountCode] = React.useState(props.data ? props.data['MainAccount'] : '');
  const [subAccountCode, setSubAccountCode] = React.useState(props.data ? props.data['SubAccount'] : '');
  const [accountName, setAccountName] = React.useState(props.data ? props.data['Name'] : '');

  //For Changing Props
  React.useEffect(() => {
    setIsPopupVisible(props.visible);
    setParentCategoryId(props.data ? props.data[props.hierarchyKey] : '');
    setMainAccountCode(props.data ? props.data['MainAccount'] : '');
    setSubAccountCode(props.data ? props.data['SubAccount'] : '');
    setAccountName(props.data ? props.data['Name'] : '');
  }, [props]);

  const showPopup = () => setIsPopupVisible(true);
  const hidePopup = () => {
    setIsPopupVisible(false);
    props.onClose();
  }


  /**
   * Display
   */

  return (

    <Portal>
      <Dialog
        visible={isPopupVisible}
        onDismiss={hidePopup}
        style={{maxWidth:'500px', minWidth: '50%', marginLeft: 'auto', marginRight: 'auto'}}
      >
        <Dialog.Title>{props.title}</Dialog.Title>
        <Dialog.Content>

          <TextInput
            label="Main Account Code"
            value={mainAccountCode}
            onChangeText={text => setMainAccountCode(text)}
            editable={false}
            disabled={false}
            right={<TextInput.Icon icon="lock" color={'#6a6a6a'} />}
          />
          <TextInput
            label="Sub Account Code"
            value={subAccountCode}
            onChangeText={text => setSubAccountCode(text)}
            editable={false}
            disabled={false}
            right={<TextInput.Icon icon="lock" color={'#6a6a6a'} />}
          />
          <TextInput
            label="Account Name"
            value={accountName}
            onChangeText={text => setAccountName(text)}
            editable={false}
            disabled={false}
            right={<TextInput.Icon icon="lock" color={'#6a6a6a'} />}
          />

          <View style={{
            'position': 'relative',
            width: isDesktop() ? '50%' : '100%',
            marginTop: '20px'
          }}>
            <Text style={styles.smallLabel}>Lvl 3 Category Name</Text>
            <Picker
              selectedValue={parentCategoryId}
              itemStyle={styles.pickerItem}
              mode={'dialog'}
              style={styles.picker}
              onValueChange={(itemValue, itemIndex) => {
                setParentCategoryId(itemValue);
              }
              }>
              {
                props.parentCategories.map((row) => {
                    return(
                      <Picker.Item
                        key={row.id}
                        label={row[props.labelKey]}
                        value={row.id}
                      />
                    );
                })
              }
            </Picker>
          </View>


        </Dialog.Content>

        <Dialog.Actions>

          <Button
            onPress={() => {
              props.onSave(props.data.id, mainAccountCode, subAccountCode, accountName, parentCategoryId)
              hidePopup()
            } }
            style={styles.button}
            mode={'contained'}
          >
            <Text>Save</Text>
          </Button>

          <Button
            onPress={() => hidePopup()}
            style={styles.button}
            mode={'contained'}
          >
            <Text>Cancel</Text>
          </Button>

        </Dialog.Actions>

      </Dialog>
    </Portal>

  )

}

const styles = StyleSheet.create({
  button: {
    marginLeft: '10px',
  },
  row: {
    display:'flex',
    flexGrow: 10,
    flexDirection:'row',
    alignContent:'center',
    paddingTop:'20px'
  },
  column: {
    width:'32%',
    marginRight: '1%'
  },
  picker : {
    backgroundColor:'#e7e7e7',
    color: '#000000',
    paddingTop: '29px',
    paddingBottom: '14px',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '16px',
    borderTopWidth: '0',
    borderLeftWidth: '0',
    borderRightWidth: '0',
    borderBottomWidth: '1px',
    borderBottomColor: '#ababab',
    //marginRight: '10px',
  },
  pickerItem : {
    fontSize: '16px'
  },
  smallLabel : {
    fontSize: '12px',
    top: '5px',
    position: 'absolute',
    color: '#6a6a6a',
    left: '12px'
  },
});
