import * as React from 'react';
import { Card, Paragraph } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import {Text, View} from "react-native";
import {isDesktop} from '../../helpers/responsive'

export default function TextComponent(props){

  function convertToRnText(textComponent, i, keyName){
    console.log(i);
    console.log(keyName);
    console.log(textComponent);

    if(textComponent.type === 'textnode'){
      return (
        <Text key={i}>{textComponent.content}</Text>
      )
    }else if(textComponent.tagName === 'br'){
      return (
        <Text key={keyName + i}>{' '}</Text>
      )
    }else if(textComponent.type === 'text'
              || textComponent.tagName === 'b'
              || textComponent.tagName === 'i'
              || textComponent.tagName === 'u'
              || textComponent.tagName === 'strike'
              || textComponent.tagName === 'font'
    ){
      // Get style from tagName
      let textStyle = {};
      if(textComponent.tagName === "b"){
        textStyle = {fontWeight:'bold'};
      }
      if(textComponent.tagName === "i"){
        textStyle = {fontStyle:'italic'};
      }
      if(textComponent.tagName === "u"){
        textStyle = {textDecorationLine:'underline'};
      }
      if(textComponent.tagName === "strike"){
        textStyle = {textDecorationLine:'line-through'};
      }
      if(textComponent.tagName === "font"){
        textStyle = {color:textComponent.attributes.color};
      }

      if(textComponent.components.length === 1 && textComponent.components[0].type === 'textnode'){
        return (
          <Text style={textStyle} key={keyName + i}>{textComponent.components[0].content}</Text>
        )
      }else{
        console.log('GOING FURTHER', keyName, textComponent.components);
        let content = textComponent.components.map((subComponent, i, array) => {
          return convertToRnText(subComponent, i, 'sub' + keyName)
        })
        console.log(content);
        return (
          <Text style={textStyle} key={keyName + i}>{content}</Text>
        )
      }


    }
  }

  return (
    <Text dataSet={{'code': props.code}} style={props.style}>
      {props.text}
      {
        props.nestedTextComponents ? props.nestedTextComponents.map((textComponent, i, array) => {
          return convertToRnText(textComponent, i, 'key');
        }) : null
      }
    </Text>

  )

}
