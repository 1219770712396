import {addRoleCheckboxes, addRoleDefaultValues, addRoleDefaultValuesToNewComponent} from "../FoundationsPlugin";

const script = function() {

  //console.log('### Blank Script ###');

}

export const PowerBiEmbedMethods = (options, editor) => {

  console.log(options);

  let companies = options.companies;
  companies.unshift({id: null, name: 'None'})

  let config = {
    // Model definition
    model: {
      // Default properties
      defaults: {
        script,
        tagName: 'div',
        droppable: false, // Can't drop other elements inside
        attributes: { // Default attributes
          componentId: 'add',
          //type: 'text',
          //name: 'default-name',
          //placeholder: 'Insert text here',
        },
        traits: [
          {
            type: 'text',
            label: 'Title',
            name: 'title',
          },
          {
            type: 'select',
            label: 'Company',
            name: 'company',
            options: companies
          },
          {
            type: 'text',
            label: 'Workspace Id',
            name: 'workspaceId',
          },
          {
            type: 'text',
            label: 'Report Id',
            name: 'reportId',
          },
          {
            type: 'text',
            label: 'Report Group',
            name: 'reportGroup',
          },
          {
            type: 'text',
            label: 'Dataset Id',
            name: 'datasetId',
          },
          {
            type: 'checkbox',
            label: 'Is Dashboard?',
            name: 'isDashboard',
          },
          {
            type: 'checkbox',
            label: 'Is Tile?',
            name: 'isTile',
          },
          {
            type: 'text',
            label: 'Tile Id',
            name: 'tileId',
          },
          {
            type: 'text',
            label: 'Width',
            name: 'width',
          },
          {
            type: 'text',
            label: 'Height',
            name: 'height',
          },
        ],

      },
      updated(property, value, prevValue) {
        //console.log('updated', property, value, prevValue);
        if (property === 'traits') {
          this.views[0].el.style.width = value.target.attributes.attributes.width;
          this.views[0].el.style.height = value.target.attributes.attributes.height;
        }
      }
    },
    view: {
      updated(property, value, prevValue) {
        console.log('updateView', property, value, prevValue);
      },
      events: {
        click: 'getCompanySelectValue',
      },
      getCompanySelectValue(event){
        if(event.target.attributes.company !== undefined){
          this.model.addAttributes({'company':event.target.attributes.company.value})
        }
      }
    }
  }

  if(options.editorType === 'full'){
    //Add role checkboxes
    config = addRoleCheckboxes(options.roles, config);
  }

  console.log(config);

  return config;
}



/* Label for sidebar*/

export const PowerBiEmbedLabel = '<div style="font-size:20px;">' +
  '<div><b>PowerBi Embed</b></div>' +
  '<i class="fa fa-bar-chart" aria-hidden="true" style="padding-top:10px"></i></div>'

/* Component structure */
export const PowerBiEmbed = () => {

  let json = {
    "type": "powerBiEmbed",
    "attributes": {
      "title": "",
      "workspaceId": "",
      "reportId": "",
      "reportGroup": "",
      "datasetId": "",
      "isDashboard": false,
      "isTile": false,
      "tileId": "",
      "width": "100%",
      "height": "80vh",
      "style": "font-size:14px;width:100%;height:80vh;background-size:contain;background-repeat: no-repeat;background-position: center;background-image:url('/media/pbi_logo.jpg');"
    },
    "components": [
      {
        "type": "textnode",
        "content": " "
      }
    ]
  };

  return json;


}
