import * as React from 'react';
import {useMediaQuery} from "react-responsive";

export default function VideoComponent(props){

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const width = isTabletOrMobile ? '100%' : props.data.width;

  if(props.data.source.includes('http')){
    return (
      <div data-code={props.code} style={{width: '100%', textAlign: 'center', height: props.data.height, paddingTop: '10px', paddingBottom:'10px'}}>
        <iframe src={props.data.source} height={props.data.height} width={width} frameBorder="0" allowFullScreen/>
      </div>
    )
  }

  return (
    <div data-code={props.code} style={{width: '100%', textAlign: 'center', height: props.data.height}}>
      <div style={{fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif', paddingBottom:'10px', paddingTop: '20px'}}>{props.data.title}</div>
      <video controls width={width}>

        <source src={props.data.source} type="video/mp4"/>

        Download the
        <a href={props.data.source}>MP4</a>
        video.
      </video>
    </div>
  )


}
