import {addRoleCheckboxes, addRoleDefaultValues, addRoleDefaultValuesToNewComponent} from "../FoundationsPlugin";

const script = function() {

  //console.log('### Blank Script ###');

}

export const BlankComponentMethods = (options, editor) => {

  console.log(options);

  let config = {
    // Model definition
    model: {
      // Default properties
      defaults: {
        script,
        tagName: 'div',
        droppable: false, // Can't drop other elements inside
        attributes: { // Default attributes
          componentId: 'add',
          //type: 'text',
          //name: 'default-name',
          //placeholder: 'Insert text here',
        },
        traits: [],

      },
      updated(property, value, prevValue) {
        //console.log('updated', property, value, prevValue);
        if (property === 'traits') {

        }
      }
    },
    view: {
      updated(property, value, prevValue) {
        //console.log('updateView', property, value, prevValue);
      }
    }
  }


  return config;
}



/* Label for sidebar*/

export const BlankComponentLabel = '<div style="font-size:20px;">' +
  '<div><b>Blank</b></div>' +
  '<i class="fa fa-text-width" aria-hidden="true" style="padding-top:10px"></i></div>'

/* Component structure */
export const BlankComponent = () => {

  let json = {
    "type": "text",
    "attributes": {
      "fontSize": "14px",
      "textAlign": "left",
      "width": "100%",
      "style": "font-size:14px;width:100%;"
    },
    "components": [
      {
        "type": "textnode",
        "content": " "
      }
    ]
  };

  return json;


}
