import * as React from 'react';
import {
  DataTable,
  ActivityIndicator,
  Button,
  Searchbar,
  Snackbar,
} from 'react-native-paper';
import { StyleSheet, ScrollView} from 'react-native';
import alasql from 'alasql';
import {View, Text} from "react-native-web";
import { TextInput } from 'react-native-web';
import {Feather} from "@expo/vector-icons";
import { deleteData, postData, patchData} from "../../helpers/api";
import {GridControls} from "./GridControls";
import { useNavigation } from '@react-navigation/native';
import {PowerforceContext} from "../../helpers/context";
import {FilterPopup} from "./FilterPopup";
import {randomKey, formatFromCamelCase, formatToCamelCase} from "../../helpers/general";
import {UploadPopup} from "./UploadPopup";
import {debounce} from "lodash";

//Actual Export
const Grid = (props) => {

  const powerforce = React.useContext(PowerforceContext);
  const navigation = useNavigation();

  const styles = StyleSheet.create({
    pagination: {
      justifyContent: 'left',
      flex: 1
    },
    gridTitle: {
      flex:1,
      fontSize: '1.4rem',
      alignSelf: 'center'
    },
    grid: {
      position: 'relative',
      backgroundColor: '#ffffff',
      borderRadius:'15px',
      margin: '5px',
      flexGrow: 1,
      boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 4px;',
      width: '98%',
      marginLeft: '1%',
      marginBottom: '1%',
      padding:0
    },
    tableHeader: {
      backgroundColor: '#eeeeee',
      cursor: 'pointer'
    },
    cell: {
      //minWidth: '160px',
      maxWidth: '500px',
      //maxHeight: '100px',
      whiteSpace: 'normal',
      textOverflow: 'ellipses',
      overflow: 'hidden',
      //border: '1px solid rgba(0, 0, 0, 0.6)',
      borderLeftWidth: 0,
      borderTopWidth: 0,
      paddingLeft: '5px',
    },
    iconCell: {
      minWidth: '50px',
      maxWidth: '50px',
      borderLeftWidth: 0,
      borderTopWidth: 0,
      paddingLeft: '5px'
    },
    iconEndCell: {
      minWidth: '50px',
      maxWidth: '50px',
      marginLeft: 'auto',
      borderLeftWidth: 0,
      borderTopWidth: 0,
      paddingLeft: '5px'
    },
    textInCell:{
      whiteSpace: 'normal'
    },
    scrollView: {
      maxHeight: '50vh',
      height: '100%',
      minHeight: 400,
      //minWidth: '70vw'
    },
    searchBar: {
      flex: 2,
      border: '2px solid grey',
      borderRadius: '50px',
      boxShadow:'none'
    },
    container: {
       flex: 1,
       backgroundColor: '#fff',
       alignItems: 'center',
       marginTop: '10vh'
    },
    snackbar:{
      backgroundColor: powerforce.colors.primary,
      color: '#ffffff'
    }
  });

  /**
   * Loading, errors, or no data
   * */

  //Loading
  if(!props.data){
    return (
      <ActivityIndicator animating={true} color={powerforce.colors.primary} />
    )
  }

  //Error Catch
  if(props.data.error){
    return (
      <Text>ERROR</Text>
    )
  }

  console.log(props);

  //Add New Record - this needs to be above function below
  const addRecord = () => {
    navigation.setParams({
      recordID : 'add',
      tableName : props.baseTable,
    })
  }

  //If there is no data
  if(props.data.length === 0){
    return (
      <View style={styles.container}>
        <Text style={{width:'50%', textAlign:'center'}}>No results for this table</Text>
        <Button
          onPress={() => addRecord()}
          style={{opacity: 1, marginTop: '20px'}}
          mode={'contained'}
          color={powerforce.colors.primary}
          icon={'plus'}
        >
          Add a record to this table
        </Button>
      </View>

    )
  }

  //If we're coming from an Individual Record, refresh the information
  React.useEffect(() => {
    //props.refresh();
  }, [])

  /**
   * References
   * */
  //const deleteButtonRefs = React.useRef([]);

  /**
   * State
   * */

  //For Changing Props
  React.useEffect(() => {
    setAllData(props.data);
    setheaderData( Object.keys(props.data[0]));
  }, [props.data]);


  //State Data
  const [allData, setAllData] = React.useState(props.data);
  const [allDataBak, setAllDataBak] = React.useState(props.data);
  const [headerData, setheaderData] = React.useState(allData[0] ? Object.keys(allData[0]) : []);

  React.useEffect(() => {
    setPage(0);
    setTableData(allData.slice(0, numberOfItemsPerPage));
  }, [allData]);

  //State For Pagination
  const numberOfItemsPerPageList = [50, 100, 250, 500];
  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, allData.length);

  //State For ALASQL
  const [currentCommand, setCurrentCommand] = React.useState('');
  const [tableData, setTableData] = React.useState(allData.slice(0, numberOfItemsPerPageList[0]));

  //State for banner
  const [snackbarVisible, setSnackbarVisible] = React.useState(false);
  const [snackbarText, setSnackbarText] = React.useState('Snackbar Text');
  const onDismissSnackBar = () => setSnackbarVisible(false);

  //State for Filter popup
  const [filterPopupVisible, setFilterPopupVisible] = React.useState(false);
  const [filters, setFilters] = React.useState(props.filters);
  const [activeFilter, setActiveFilter] = React.useState({});

  //State for Upload popup
  const [uploadPopupVisible, setUploadPopupVisible] = React.useState(false);
  const [isImportDone, setIsImportDone] = React.useState(false);
  const [importDoneText, setImportDoneText] = React.useState('Importing Done!');

  //State for Deleting
  const [currentlyDeletingId, setCurrentlyDeletingId] = React.useState(null);

  //State for browser reorganizing data
  const [reorganizing, setReorganizing] = React.useState(false);


  /**
   * Search Bar
   * */

  //Search Bar
  const changeSearchText = (text) => {

    if(text === ''){
      setAllData(props.data);
      return;
    }

    const textsAsArray = text.toLowerCase().split(' ');

    const searchResults = props.data.filter(obj => {

      let results = Object.values(obj).some(val => {
        let value = String(val);
        if(value.toLowerCase().includes(text.toLowerCase()) || textsAsArray.includes(value.toLowerCase())){
          return true;
        }
      })

      return results;
    });

    if(searchResults === undefined || searchResults.length == 0){
      setAllData([{}]);
      //setTableData([{}]);
    }else{
      setAllData(searchResults);
      //setTableData(searchResults);
    }

  }

  const debounceChangeSearchText = debounce(changeSearchText, 300);

  /**
   * Right side Grid Controls
   * */

  /**
   * FILTER - Right side Grid Controls
   * */
  const toggleFilterPopup = () => {
      setFilterPopupVisible(!filterPopupVisible);
  }

  const selectFilterFromDropdown = (filter) => {
    let query;

    if(filter){

      if(filter === 'create'){
        setActiveFilter({})
        setFilterPopupVisible(!filterPopupVisible);
        return;
      }

      if(filter === 'edit'){
        setFilterPopupVisible(!filterPopupVisible);
        return;
      }

      //Not creating or editing - apply filter
      query = filter.query;
      setActiveFilter({...filter})

    }else{

      query = 'SELECT * FROM ?';
      setActiveFilter({})

    }
    applyFilter(query)

  }

  //Save (Create or Edit) and Apply Filter
  const saveAndApplyFilter = (newFilterData) => {

    let newFilters = filters;

    if(newFilterData.id){

      //Edit Filter
      let filterIndexToEdit = newFilters.findIndex(x => x.id === newFilterData.id);
      newFilters[filterIndexToEdit] = newFilterData;

      //Ajax PATCH to Save
      patchData(
        '/data/gridfilter/'+props.code,
        powerforce.authToken,
        newFilterData
      ).then(response => {
        //console.log(response); //recordId
      })

    }else{

      //Create Filter
      const tempId = 'TEMPID_'+randomKey(12);
      newFilterData.id = tempId;
      newFilters.push(newFilterData);

      //Ajax POST to create
      postData(
        '/data/gridfilter/'+props.code,
        powerforce.authToken,
        newFilterData
      ).then(response => {
        //console.log(response); //recordId
        let filterIndexToEdit = filters.findIndex(x => x.id === response.detail.tempId);
        let newFilters = filters;
        newFilters[filterIndexToEdit].id = response.detail.id;
        setFilters(newFilters);
      })

    }

    //Apply to State and To Grid
    setFilters(newFilters)
    setActiveFilter(newFilterData)
    applyFilter(newFilterData.query)
  }

  const deleteFilter = (newFilterData) => {

    if(newFilterData.id) {

      let newFilters = filters;
      let filterIndexToDelete = newFilters.findIndex(x => x.id === newFilterData.id);
      newFilters.splice(filterIndexToDelete, 1)

      deleteData(
        '/data/gridfilter/' + props.code,
        powerforce.authToken,
        newFilterData
      ).then(response => {
        //console.log(response); //recordId
      })

      setFilters(newFilters)
      setActiveFilter({})
      applyFilter('SELECT * FROM ?')

    }
  }

  //Execute AlaSQL from filter
  const applyFilter = (filterQuery) => {
    let result = alasql(filterQuery, [allDataBak]);
    setAllData(result);
    setCurrentCommand(filterQuery);
  }

  /**
   * DOWNLOAD - Right side Grid Controls
   * */

  //Download as CSV
  const handleDownload = () => {
    let csv = '';

    for(let row = 0; row < tableData.length; row++){
      let keysAmount = Object.keys(tableData[row]).length
      let keysCounter = 0

      // If this is the first row, generate the headings first
      if(row === 0){

        // Loop each property of the object
        for(let key in tableData[row]){
          // This is to not add a comma at the last cell
          // The '\r\n' adds a new line
          csv += formatToCamelCase(key) + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
          keysCounter++
        }

        keysCounter = 0;

        for(let key in tableData[row]){
          if(tableData[row][key] !== ''){
            csv += '"' + tableData[row][key] + '"' + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
          }else{
            csv += 'null' + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
          }
          keysCounter++
        }

      }else{
        for(let key in tableData[row]){
          if(tableData[row][key] !== ''){
            csv += '"' + tableData[row][key] + '"' + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
          }else{
            csv += 'null' + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
          }
          keysCounter++
        }
      }

      keysCounter = 0
    }

    //This will only work on the web
    let link = document.createElement('a')
    link.id = 'download-csv'
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
    link.setAttribute('download', props.title+'.csv');
    document.body.appendChild(link)
    document.querySelector('#download-csv').click()
    document.querySelector('#download-csv').remove() //remove it to be able to download again

  }

  /**
   * UPLOAD - Right side Grid Controls
   * */
  const toggleUploadPopup = () => {
    setUploadPopupVisible(!uploadPopupVisible);
  }

  const executeImport = (updates, additions, deletions) => {
    postData(
      '/data/gridupload/'+props.code,
      powerforce.authToken,
      {
        updates: updates,
        additions: additions,
        deletions: deletions,
      }
    ).then(response => {
      //Set isImportDone
      setIsImportDone(true);

      if(response.status > 200){
        setImportDoneText('Error Importing: ' + response.detail);
      }
    })
  }

  const onImportDone = () => {
    props.refresh();
  }

  /**
   * ADD RECORD - Right side Grid Controls
   * */
  //Add Record
  //Located above 'No Data' display


  /**
   * Controls on Grid
   * */

  //Click on grid header to sort ASC or DESC
  const clickGridHead = (e, header) => {
    let command = 'select * from ? order by ' + header + ' ASC';
    if(currentCommand === 'select * from ? order by '+ header +' ASC'){
      command = 'select * from ? order by '+ header +' DESC';
    }
    //let result = alasql(command, [allData]);

    setReorganizing(header);

    setTimeout(() => {
      let result = alasql(command, [allData]);
      setAllData(result);
      setCurrentCommand(command);
      setReorganizing(false);
      //console.log(result);
      //console.log(allData);
    }, 250)

    //setReorganizing(false);
  }

  //Edit Individual Record
  const editRecord = (id) => {
    navigation.setParams({
      recordID : id,
      tableName : props.baseTable,
    })
  }

  //Edit cell
  const editCell = (doOnPress, props, value) => {
    if(! doOnPress){
      return;
    }

    console.log(props);
    let url = value;
    if(props.urlPrefix){
      url = props.urlPrefix + value;
    }
    window.open(url, '_blank').focus();

  }

  //Delete record
  const deleteRecord = (id) => {
    setCurrentlyDeletingId(id);
    deleteData(
      '/data/record/'+props.baseTable+'/'+id,
      powerforce.authToken,
      {gridCode: props.code}
    ).then(response => {
      setSnackbarVisible(true);

      //Remove from alldata
      if(response.status === 200){
        setSnackbarText(response.detail);
        let itemIndex = allData.findIndex(x => x.id === id);
        let newData = allData;
        newData.splice(itemIndex, 1);
        setAllData([...newData]);
        setCurrentlyDeletingId(null);
      }else{
        //ERR
        setSnackbarText(JSON.stringify(response.detail));
        setCurrentlyDeletingId(null);
      }

    })
  }

  //Clone Individual Record
  const cloneRecord = (id) => {
    navigation.setParams({
      recordID : id,
      tableName : props.baseTable,
      cloneRecord: true
    })
  }

  /**
   * Grid Bottom Controls
   * */

  //Change Page
  const changePage = (page) => {
      setPage(page);
  }
  React.useEffect(() => {
    let newData = allData.slice(from, from+numberOfItemsPerPage);
    setTableData(newData);
  }, [page]);

  //Change Items Per Page
  const changeItemsPerPage = (numberOfItemsPerPage) => {
      onItemsPerPageChange(numberOfItemsPerPage);
  }
  React.useEffect(() => {
    setPage(0);
    let newData = allData.slice(from, from+numberOfItemsPerPage);
    setTableData(newData);
  }, [numberOfItemsPerPage]);

  //Execute ALASql from debug bar
  const execSQL = (el) => {
    let query = el.target.value;
    if(query === 'query' || query === ''){
      query = 'SELECT * FROM ?'
    }
    let result = alasql(query, [allDataBak]);
    setAllData(result);
  }

  /**
   * Delete & Clone Columns on Grid
   * */

  function cloneColumnHead(){
    if(props.permission === 'write' && props.canCreate) {
      return (
        <DataTable.Title
          key={'headerClone'}
          style={styles.iconEndCell}
        >
          Clone
        </DataTable.Title>
      )
    }
    return null;
  }

  function cloneColumn(datum){
    if(props.permission === 'write' && props.canCreate) {
      return (
        <DataTable.Cell
          key={datum[headerData[0]]+'clone'}
          onPress={() => cloneRecord(datum['id'])} //Make cloneRecord
          style={styles.iconEndCell}
        >
          <Feather name="copy" size={24} color={powerforce.colors.primary} dataSet={{'code': 'grid_clone_'+datum['id']}} />
        </DataTable.Cell>
      )
    }
    return null;
  }

  function cloneColumnFoot(){
    if(props.permission === 'write' && props.canCreate) {
      return (
        <DataTable.Cell
          style={styles.iconEndCell}
          key={'cloneSpacer'}>

        </DataTable.Cell>
      )
    }
    return null;
  }

  function deleteColumnHead(){
    if(props.permission === 'write' && props.canDelete) {
      return (
        <DataTable.Title
          key={'headerDelete'}
          style={styles.iconEndCell}
        >
          Delete
        </DataTable.Title>
      )
    }
    return null;
  }

  function deleteColumn(datum, isCurrentlyDeleting){

    if(props.permission === 'write' && props.canDelete) {

      if(isCurrentlyDeleting){
        return (
          <DataTable.Cell
            key={datum[headerData[0]]+'delete'}
            style={styles.iconEndCell}
          >
            <ActivityIndicator animating={true} color={powerforce.colors.primary} />
          </DataTable.Cell>

        )
      }else{
        return (
          <DataTable.Cell
            key={datum[headerData[0]]+'delete'}
            onPress={() => deleteRecord(datum['id'])}
            style={styles.iconEndCell}
          >
            <Feather name="x-circle" size={24} color="#FF0000" dataSet={{'code': 'grid_delete_'+datum['id']}} />
          </DataTable.Cell>
        )
      }

    }
    return null;
  }

  function deleteColumnFoot(){
    if(props.permission === 'write' && props.canDelete) {
      return (
        <DataTable.Cell
          style={styles.iconEndCell}
          key={'deleteSpacer'}>

        </DataTable.Cell>
      )
    }
    return null;
  }

  /**
   * SHOW TABLE
   * */

  return (
    <ScrollView>
      <DataTable style={styles.grid} dataSet={{'code': props.code}}>

        <View style={{flexDirection:"column", padding:"10px"}}>
          <View style={{flexDirection:"row", padding:"10px"}}>
            <Text style={styles.gridTitle}>{props.title}</Text>

            {
              //Grid Controls
            }
            <GridControls
              {...props}
              onDownload={handleDownload}
              onAdd={addRecord}
              onFilter={toggleFilterPopup}
              onUpload={toggleUploadPopup}
              filters={filters}
              activeFilter={activeFilter}
              onSelectFilter={(filter) => selectFilterFromDropdown(filter)}
            />
          </View>

          <Searchbar
            style={ styles.searchBar }
            placeholder={'Search...'}
            onChangeText={debounceChangeSearchText}
            dataSet={{'code': 'grid_search_bar'}}
          />

          {
            //Filter Popup
          }
          <FilterPopup
            visible={filterPopupVisible}
            columns={headerData}
            filterData={activeFilter}
            saveAndApplyFilter={saveAndApplyFilter}
            deleteFilter={deleteFilter}
            onClose={toggleFilterPopup}
          />

          {
            //Upload Popup
          }
          <UploadPopup
            visible={uploadPopupVisible}
            onClose={toggleUploadPopup}
            executeImport={executeImport}
            isImportDone={isImportDone}
            onImportDone={onImportDone}
            importDoneText={importDoneText}
          />

        </View>

        <ScrollView
          horizontal={true}
          style={{minWidth: '100%'}}
          contentContainerStyle={{minWidth: '100%'}}
        >
          <View style={{minWidth: '100%'}}>
            <DataTable.Header style={styles.tableHeader}>
              <DataTable.Title
                key={'headerEdit'}
                style={styles.iconCell}
              >
                {props.permission === 'write' ? 'Edit' : 'View'}
              </DataTable.Title>
              {
                headerData.map(header => {
                  if(header === 'id'){
                    return;
                  }
                    return(
                        <DataTable.Title
                          key={header}
                          onPress={(e) => {clickGridHead(e, header)}}
                          style={styles.cell}
                        >
                          {formatFromCamelCase(header)}
                          { /*reorganizing === header ? <ActivityIndicator size={10} color={powerforce.colors.primary} /> : '' */}
                          { reorganizing === header ? <Text style={{fontSize: '8px', paddingLeft: '10px'}}>Loading...</Text> : '' }
                        </DataTable.Title>
                    )
                })
              }

              {cloneColumnHead()}
              {deleteColumnHead()}

            </DataTable.Header>

            <ScrollView style={styles.scrollView}>
              {
              tableData.map(datum => {

                let isDeleting = false;
                if(currentlyDeletingId === datum.id){
                  isDeleting = true;
                }

                  return (

                      <DataTable.Row
                          key={'row' + datum['id']}
                          onPress={() => console.log('row pressed')}
                      >
                        <DataTable.Cell
                          key={datum['id']+'edit'}
                          onPress={() => editRecord(datum['id'])}
                          style={styles.iconCell}
                        >
                          <Feather
                            name={props.permission === 'write' ? 'edit' : 'eye'}
                            dataSet={{'code': 'grid_edit_'+datum['id']}}
                            size={24}
                            color={powerforce.colors.primary}
                          />
                        </DataTable.Cell>

                        {
                          headerData.map(header => {
                            if(header === 'id'){
                              return;
                            }

                            let cellId = header;
                            if(datum.id){
                              cellId = datum.id + header;
                            }
                            let value;

                            if(datum[header] === null){
                              value = '';
                            }else if(datum[header] !== undefined && datum[header].constructor === Array){

                              //Cell value is an array, is it empty?
                              if(datum[header].length === 0){
                                value = '';
                              }

                              //See if its an IRI
                                /*
                              else if(datum[header][0].includes('/api/')){
                                value = '';
                                //Its an IRI. Loop through and fetch
                                datum[header].map(arrayItem => {
                                  getIRI(arrayItem).then(fetchedData => {
                                    console.log(fetchedData);
                                    value += fetchedData.title + ', ';
                                  });
                                })

                              }*/
                              else{
                                //Not an IRI. Simply join with columns
                                value = datum[header].join(', ');

                              }


                            }else{
                              //Cell Value is not an array
                              value = datum[header];
                            }

                            // If value is bool
                            if(typeof value === "boolean"){
                              if(value){
                                value = 'Yes';
                              }else{
                                value = 'No';
                              }
                            }

                            //If header is equal to button data source make it a button
                            let doOnPress = false;
                            if(props.urlDataSource === header){
                              value = <Text style={{ backgroundColor: powerforce.colors.secondary, padding: '5px', color: '#ffffff'}}>Go To</Text>;
                              doOnPress = true;
                            }

                            return(
                                <DataTable.Cell
                                  key={cellId}
                                  style={styles.cell}
                                  onPress={() => editCell(doOnPress, props, datum[header])} // Possibly do the onclick here
                                >
                                  <Text style={styles.textInCell}>{value}</Text>
                                </DataTable.Cell>
                            )
                          })
                        }

                        {cloneColumn(datum)}
                        {deleteColumn(datum, isDeleting)}

                      </DataTable.Row>
                  )
                })
              }
              <DataTable.Row>
                <DataTable.Cell
                  style={styles.iconCell}
                  key={'editSpacer'}>

                </DataTable.Cell>
                {
                  headerData.map(header => {
                    if(header === 'id'){
                      return;
                    }
                    return(
                      <DataTable.Cell
                        key={header}
                        style={styles.cell}
                      >
                        {
                          tableData.reduce(function(a, b, index){
                            if(isFinite(b[header])){
                              let prev = new Number(a);
                              let next = new Number(b[header]);

                              if(index === tableData.length - 1){
                                let total = prev + next;
                                if(isNaN(total)){
                                  return;
                                }else{
                                  return 'Σ: ' + total;
                                }

                              }else{
                                return prev + next;
                              }
                            }
                          }, 0)
                        }
                      </DataTable.Cell>

                    )
                  })
                }
                {cloneColumnFoot()}
                {deleteColumnFoot()}
              </DataTable.Row>
            </ScrollView>


          </View>
        </ScrollView>

        <View style={{flexDirection:"row"}}>
          <DataTable.Pagination
              page={page}
              numberOfPages={Math.ceil(allData.length / numberOfItemsPerPage)}
              onPageChange={page => changePage(page)}
              label={`${from + 1}-${to} of ${allData.length}`}
              //showFastPaginationControls
              numberOfItemsPerPageList={numberOfItemsPerPageList}
              numberOfItemsPerPage={numberOfItemsPerPage}
              onItemsPerPageChange={changeItemsPerPage}
              selectPageDropdownLabel={'Rows per page'}
              style={styles.pagination}
          />

          <View style={{ flexDirection:"row", flex: '2 1 0%'}}>
            <TextInput
              style={{
                flex: 1,
                //border: '1px solid black',
                padding:'10px',
                textAlign:'right'
              }}
              //placeholder={currentCommand}
              //defaultValue={currentCommand}
              onSubmitEditing={execSQL}
            />
            {/*
            <Button
              icon="table-refresh"
              style={{ flex: 1}}
              onPress={execSQL}
            />
            */}

          </View>

        </View>

      </DataTable>

      {
        //Snackbar notifications
      }
      <View>
        <Snackbar
          visible={snackbarVisible}
          onDismiss={onDismissSnackBar}
          style={styles.snackbar}
          action={{
            label: 'Dismiss',
            onPress: onDismissSnackBar,
          }}>
          {snackbarText}
        </Snackbar>
      </View>


    </ScrollView>
  );
}

export default Grid;



