import {Pressable, StyleSheet, Text, View} from "react-native";
import React, {useContext} from "react";
import {PowerforceContext} from "../../helpers/context";
import {PowerBIEmbed} from "powerbi-client-react";
import {models} from "powerbi-client";
import './pbi.css';
import { Mobile } from '../../helpers/responsive'
import BookmarksMenu from "./BookMarksMenu";
import {Button} from "react-native-paper";
import BookmarkModal from "./BookMarkModal";
import {getData, postData} from "../../helpers/api";
import { useMediaQuery } from 'react-responsive'
import {Feather} from "@expo/vector-icons";
import BookmarkManagerModal from "./BookMarkManagerModal";
import InfoModal from "./InfoModal";


export default function PowerBiCustomEmbed(props) {

  // For displaying "Best in landscape mode."
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const powerforce = useContext(PowerforceContext);
  const [report, setReport] = React.useState();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [managerModalVisible, setManagerModalVisible] = React.useState(false);
  const [infoModalVisible, setInfoModalVisible] = React.useState(false);

  let fakeBookmarks = [
    {"label": "test 1", "value": "H4sIAAAAAAAAA+1aW0/rOBD+K1VeeAkol155K21hzx4O9ECXFVqhykkmrc9x46zjFLoo/31tJ4G0TS/cCkJIVUl8GX/zzYw9nnKveTgKCZqdoQloh9oRpb8niP2umJquBfNtvuM6jVbdrdp21W81oGqbjhhFQ45pEGmH9xpHbAT8CkcxIlKgaPznRtcQIX00km8+IhHoWggsogEi+D9IB4suzmJIdA3uQkIZkiIvOeIgxU7FcPEuoJgHtlgRuRxP4RJcnrZeQEgZz991LUqfFKT5PtHgY8KFPPnoZFrfa8eqEYHrVA2/0bAt8BE4ddOuyt6MhzWDdI3PQjmmIyCPKMMuIqIxXUuKuMpVsHTtmNGJIOZeyzhHYmQv4JjPxEvbdWkc8Ei0DZRIIxEM/j0GBmpOhwYeznX5pr57dyGDKEo1VkNIPFnokW+XNGYuXID/+KJWTwTtfUaFURQCuaxClsiVrxCJlR2F4FMs9SdKH9ksBu8dIYICFyqXYwC+J+fcJOIrtWRh8a1ALbOwGpuujelth4Eg3NMOzUS2TJUzCYo4wkFmZKsKyGy0oNFoNpHXsDyraS/5gcSkuJszdc13arZh2k4Tmk1H+L1ThZeZ2i+a+hgHmZ4njMbhdiY/o3yZxNfwA3+R63anc/7X2WA4uO73tvKFICYkd4CXu8ASO+vhzbuDkej35XZ6C6/M2o9T/VcjaXtTGSveEoz2aMRghHIb916A8eQ0i8gOIu4i0DOxOStjyfbjOMg2RWM39G2y6I/2n+cXw0570Ds5v7jekU03gvp2ti2oleb9A4tQYe54dgpTIMvIHvqXu3IkV4jh9FDM4nU77YbdQSVTDwejSlceqFKFbDt6kKrN6fwwrABMNVYeG2R3po52DYg921yfnZyfMWLyZPjip5yfH+K0Hn/FVRk1XTQrIUYerJFYlWS5s8qQ1VOaPWghnlI+QA6RyQp1foncV6Y5SZ44C0S/ihkyvX1CvvDs8/nNTwuRoKQrpNp8dufIN93Pr2lhB/38yubbYZY+oyDKb8KpWzNK1FMatsLniZyY9v0bA5sJ2Kp/MX4O5gJSTMQeKNW+w2wH8S8XjPo4COQmlt71NwFeiHAlQWAkKIxyIc8QOp/PPUtofg84KCb+ZZKk1oymN7ac7vwSJW9LuuaOMfEYBMoAi0PWX7PkegM6EufAw2rSbYQVmtUWMu2a5TVty6tDtQ4ttPrK++op9apAKYuKJx5tEcGu2AmKx5o2ATZSQTyCIOVKKBWma2GIHhWff1p3TR8Wr+ll6mxzU28HnvxzCj5POZyEYouIckbzt+9YjrP0wsCnXNuHK6m9wKOxEljiQ54YxPEE9izDMvcN+RkYxqH6yCLO3Pz1yO33Q27tG/Vl5Kr2kKgAEYNRiTtEXBwo3ay4CCoOnseSiJPAe6IgAbq2by8JmlBvk5S9I+C3AEGmpgp1yjxgRzOlZBezvMxp6i8qImwfwZLl0qyysIe91do3iji/1UIt8FqGD4ZfNxwAs77DzU6FQzBbhFhs/ph7nFvc43K4uyo6L1WmHvnaouZsGkZlv5JNqfRFMMtir145Pe0UKtBrt4Btwq0rZnj0di7eXtfXNzpP6uL1muOaDjSh6TiW6dbNWsvZoYtvKHt2JJUfogBb+svAVwH2FRKz96i8vkOB/7NXqHcHSlwqh5eU8R3BWRt0RyjC0Y6AvH46wGUhr3c3X8qTcizfRGD60ICq2azV/UajCh/nVFjxY9jLsp8PfZhvR0j642hSrh+NeRQiF/oogBI9VT3IA2+DruqfLB7UTJL/AQduzJHkIQAA"},
    {label: 'test 2', value: 'val2'},
    {label: 'test 3', value: 'val3'},
  ]

  const [bookmarks, setBookmarks] = React.useState(props.bookmarks);

  async function saveBookmark(name)
  {

    // Capture the report's current state with personalized visuals
    const capturedBookmark = await report.bookmarksManager.capture({ personalizeVisuals: true });

    // Build bookmark
    let bookmark = {
      label: name,
      value: capturedBookmark.state
    }

    let tempBookmarks = bookmarks;
    tempBookmarks.push(bookmark)

    // Add to backend here
    postData('/data/powerBiBookmark/' + props.code, powerforce.authToken, {bookmarks: tempBookmarks}).then(response => {

      // Add to dropdown
      setBookmarks(tempBookmarks)

      // Close modal
      setModalVisible(false);
    });

  }

  function manageBookMarks(bookmarks){
    postData('/data/powerBiBookmark/' + props.code, powerforce.authToken, {bookmarks: bookmarks}).then(response => {

      // Add to dropdown
      setBookmarks(bookmarks)

      // Close modal
      setManagerModalVisible(false);
    });
  }

  async function applyBookmark(state){
    if(state === 'reset'){
      await report.reload();
    }else{
      report.bookmarksManager.applyState(state);
    }

  }

  function getEmbedConfigs(){
    let embedConfigs;
    if(props.type === 'tile'){
      embedConfigs = {
        type: props.type,   // Supported types: report, dashboard, tile, visual and qna
        id: props.reportId,
        dashboardId: props.dashboardId,
        embedUrl: props.embedUrl,
        accessToken: props.accessToken,    // Keep as empty string, null or undefined
        tokenType: models.TokenType.Embed,
      }
    }else{
      embedConfigs = {
        type: props.type,   // Supported types: report, dashboard, tile, visual and qna
        id: undefined,
        embedUrl: props.embedUrl,
        accessToken: props.accessToken,    // Keep as empty string, null or undefined
        tokenType: models.TokenType.Embed,
        settings: {
          panes: {
            bookmarks: {
              visible: false
            },
            fields: {
              expanded: true
            },
            filters: {
              expanded: false,
              visible: true
            },
            pageNavigation: {
              visible: true
            },
            selection: {
              visible: true
            },
            syncSlicers: {
              visible: true
            },
            visualizations: {
              expanded: true
            }
          },
          bars: {
            statusBar: {
              visible: true
            },
            actionBar: {
              visible: true
            }
          },
          background: models.BackgroundType.Transparent,
          personalBookmarksEnabled: true
        }
      }
    }

    return embedConfigs;
  }

  const [height, setHeight] = React.useState(getHeight());
  function getHeight(){
    if(props.height){
      return props.height;
    }
    return '80vh';
  }

  function getWidth(){
    if(props.width){
      return props.width;
    }
    return '100%';
  }

  const bookMarksModal = () => {
    if(props.reportType === 'PowerBIReport'){
      return (
        <BookmarkModal
          visible={modalVisible}
          onDismiss={() => {setModalVisible(false);}}
          onSave={saveBookmark}
        />
      )
    }
  }

  const bookMarksManagerModal = () => {
    if(props.reportType === 'PowerBIReport'){
      return (
        <BookmarkManagerModal
          visible={managerModalVisible}
          onDismiss={() => {setManagerModalVisible(false);}}
          onSave={manageBookMarks}
          bookmarks={bookmarks}
        />
      )
    }
  }

  const infoModal = () => {
    return (
      <InfoModal
        visible={infoModalVisible}
        onDismiss={() => {setInfoModalVisible(false);}}
        refreshData={props.refreshData}
      />
    )
  }



  const [fullScreenIcon, setFullScreenIcon] = React.useState('maximize-2');
  const toggleFullScreen = () => {
    props.toggleTopMenu();
    props.toggleSideMenu();
    let icon = fullScreenIcon === 'maximize-2' ? 'minimize-2' : 'maximize-2';
    setFullScreenIcon(icon);

    if(height === getHeight()){
      setHeight('90vh')
    }else{
      setHeight(getHeight())
    }
  }

 const refreshFilters = async () => {

   await report.reload();
    /*console.log('refreshFilters');
    await report.updateFilters(models.FiltersOperations.RemoveAll);
    let activePage = await report.getActivePage();

   let reportFilters = await activePage.getFilters();
   console.log(reportFilters);

    console.log(activePage);
    await activePage.updateFilters(models.FiltersOperations.RemoveAll);

    let visuals = await activePage.getVisuals();
     visuals.map(function (visual) {
       console.log(visual);
       visual.updateFilters(models.FiltersOperations.RemoveAll)
     })*/
  }

  const FullScreen = () => {
    return(
      <Pressable
        onPress={toggleFullScreen}
        style={{marginLeft:"10px"}}
      >
        <Feather name={fullScreenIcon} size={20} color={'gray'}  />
      </Pressable>
    )
  }

  const Info = () => {
    return (
      <Pressable
        onPress={() => {setInfoModalVisible(true)}}
        style={{marginLeft:"auto"}}
      >
        <Feather name={'info'} size={20} color={'gray'}  />
      </Pressable>
    )
  }



  const bookMarksMenu = () => {
    if(props.reportType === 'PowerBIReport'){
      return (
        <View style={{ flexDirection:"row", width: '100%' }}>
          <BookmarksMenu
            items={bookmarks}
            onChange={applyBookmark}
          />
          <View style={{justifyContent: 'center', marginLeft:'20px', flexDirection: 'row'}}>
            <Button icon="bookmark-plus" mode="contained" onPress={() => {setModalVisible(true);}}>
              Add Bookmark
            </Button>
            <Button icon="bookmark-plus" mode="contained" onPress={() => {setManagerModalVisible(true);}} style={{marginLeft:'20px'}}>
              Manage Bookmarks
            </Button>
          </View>


          <Info />

          <Pressable
            onPress={refreshFilters}
            style={{marginLeft:"10px"}}
          >
            <Feather name={'refresh-cw'} size={20} color={'gray'}  />
          </Pressable>

          <FullScreen />

        </View>
      )
    }else{
      return(
        <View style={{ flexDirection:"row", width: '100%' }}>
          <View style={{ marginLeft:"auto", flexDirection:"row" }}>
            <Info />
            <FullScreen />
          </View>
        </View>
      )
    }
  }

  if(props.accessToken === undefined){
    return (
      <View style={{ width: getWidth() }}>
        <Text>Could not get PBI Report.</Text>
      </View>
    )
  }

  return (
    <View style={{ width: getWidth() }} dataSet={{'code': props.code}}>

      {bookMarksModal()}
      {bookMarksManagerModal()}
      {infoModal()}

      {isPortrait ? <Text>Best viewed in landscape.</Text> : null }

      <View style={{ width: '100%', zIndex: -1 }}>

        <Mobile>
          <View style={{height: '20px', width: '100%'}} />
        </Mobile>

        {bookMarksMenu()}

        <View style={{ flexDirection:"row", width: '100%', height: height, zIndex: 7, marginTop: '5px' }}>

          <PowerBIEmbed
            embedConfig = {getEmbedConfigs()}

            eventHandlers = {
              new Map([
                ['loaded', async function (event, embeddedReport) {
                  //let bookmarks = await embeddedReport.bookmarksManager.getBookmarks();
                  //console.log(bookmarks);
                  let reportFilters = await embeddedReport.getFilters();

                  let activePage = await embeddedReport.getActivePage();

                  let pages = await embeddedReport.getPages();

                }],
                ['rendered', function (event, embeddedReport) {
                  //createResetBookMark(embeddedReport)
                }],
                ['error', function (event) {/*console.log(event.detail);*/}],
              ])
            }

            cssClassName = { 'reportStyleClass' }

            getEmbeddedComponent = { (embeddedReport) => {
              setReport(embeddedReport);

            }}
          />


        </View>
      </View>
    </View>
  );

}

