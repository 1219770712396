import {StyleSheet, View} from "react-native";
import React, {useContext} from "react";
import {PowerforceContext} from "../../helpers/context";
import ScreenComponent from "./ScreenComponent";
import {ActivityIndicator} from "react-native-paper";
import {getData} from "../../helpers/api";
import { isEqual } from "lodash";


export default function Screen(props) {

  const powerforce = useContext(PowerforceContext);

  const [screenComponents, setScreenComponents] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState(null);

  //const controller = new AbortController();
  //const signal = controller.signal;
  const ref = React.useRef(null);
  React.useEffect(() => {
    if(ref.current){
      ref.current.abort('user cancelled.')
      //ref.current= new AbortController;
    }
    //ref.current= new AbortController;
    //return () => (ref.current.abort());
  }, []);

  const cancelAllCalls = ()=>{
    if(ref.current){
      ref.current.abort()
      //ref.current= new AbortController;
    }
  }

  React.useEffect(() => {

    //cancelAllCalls();
    if(ref.current){
      ref.current.abort('user')
    }
    const newAbortController = new AbortController();
    ref.current= newAbortController;

    let signal = ref.current.signal;

    let newScreenData = props.moduleData.screens[0];

    if(props.route && props.route.params && props.route.params.screenName) {
      newScreenData = props.moduleData.screens.find(x => x.url === decodeURIComponent(props.route.params.screenName))
    }


    if(!screenComponents || !props.route.params || (!props.route.params.recordID && !props.route.params.tableName)){
      setLoading(true);
      getData('/screen/' + newScreenData.moduleUrl + '/' + newScreenData.url, powerforce.authToken, signal)
        .then(fetchedData => {
          //console.log(fetchedData);

          if(fetchedData.status === 401){
            //console.log('401 from screen');
            powerforce.removeAppState();
          }

          else if(fetchedData.status === 500){
            alert('Could not load screen');
            setScreenComponents([]);
            setLoading(false);
          }
          else{
            setScreenComponents(fetchedData);
            setLoading(false);
          }

        })

    }else{
      //setLoading(false);
    }
    //setCurrentRoute(props.route.path);

  }, [props.route])

  if(loading){
    return (
      <View style={styles.loadContainer}>
        <ActivityIndicator size="large" animating={true} color={powerforce.colors.primary} />
      </View>
    );
  }

  return (

      <View style={styles.container}>

        {

          screenComponents.map((component, index) => {
            return (
              <ScreenComponent
                key={index}
                component={component}
                route={props.route}
                navigation={props.navigation}
                toggleSideMenu={props.toggleSideMenu}
                toggleTopMenu={props.toggleTopMenu}
              />
            )
          })

        }

      </View>

  );



}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width:'100%',
    padding: '15px',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  loadContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh'
  }
});
