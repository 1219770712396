import * as React from 'react';
import { Card, Paragraph } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import {Image, Pressable, Text, View} from "react-native";
import {isDesktop} from '../../helpers/responsive'

export default function SimpleCard(props){

  //Create Image or Icon
  const image = () => {
    if(props.data.icon){
      return (
        <View style={{
          height: 'auto',
          width: '100px',
          justifyContent: 'center'
        }}>
          <Feather
            name={props.data.icon}
            size={ '90px'}
            color="#000000"
            style={{textAlign: 'center', maxWidth: '100%', padding:'3px'}}
          />
        </View>
      );
    }

    if(props.data.coverUri){


      return <Image
        source={{ uri: props.data.coverUri }}
        resizeMode={'contain'}
        style={{
          backgroundColor: '#ffffff',
          maxWidth: '100%',
          width: '100px',
          height: '100px',
          padding:'3px',
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
      />


      // Old version. May remove later.
      /*return <Card.Cover
        source={{ uri: props.data.coverUri }}
        resizeMode={'contain'}
        style={{ backgroundColor: '#ffffff', padding: '10px', maxWidth: '100%'}}
      />*/
    }

  }

  //OnPress Functions - arguments will always be an array
  function goToExternalLink(args){
    window.open(args[0], '_blank');
  }

  function goToInternalLink(args){
    if(args[1]){
      props.navigation.navigate(
        args[0],
        {
          screenName: args[1],
          recordID: undefined,
          tableName: undefined,
        }
      )
    }else{
      if(args[0].match(/\//)){
        let screen = args[0].replace(/^[^/]+\//, '');
        let module = args[0].replace(/\/[^\/]+$/, '');
        props.navigation.navigate(
          module,
          {
            screenName: screen,
            recordID: undefined,
            tableName: undefined,
          }
        )
      }
      props.navigation.navigate(args[0]);
    }

  }

  function mailTo(args){
    if(args[1]){
      window.location.href = "mailto:" + args[0] + "?subject=" + args[1];
    }else{
      window.location.href = "mailto:" + args[0];
    }
  }

  function alertWeb(args){
    alert(args[0]);
  }

  //Enabling dynamic OnPress Functions
  function doOnPressAction(){
    let functionName = props.data.onPressFunction;
    if(typeof onPressActions[functionName] === 'function' ){
      return onPressActions[functionName](props.data.onPressFunctionArgs);
    }else{
      console.log('function does not exist');
      return;
    }

  }
  const onPressActions = {
    'goToExternalLink' : goToExternalLink,
    'goToInternalLink' : goToInternalLink,
    'mailTo' : mailTo,
    'alertWeb' : alertWeb
  }

  const [borderColor, setBorderColor] = React.useState('rgba(0,0,0,.25)');
  const [cursorStyle, setCursorStyle] = React.useState(props.data.onPressFunction === 'none' ? '' : 'pointer');
  const [displayText, setDisplayText] = React.useState(false);


  const hover = () => {
    if(props.data.onPressFunction !== 'none'){
      setBorderColor('0,0,0,.5');
    }
    setDisplayText(true);
  }

  const unHover = () => {
    console.log('unhover');
    setBorderColor('rgba(0,0,0,.25)');
    setDisplayText(false);
  }


  return(
    <Pressable
      style={{
        width: isDesktop() ? props.data.width : '50%',
        marginBottom: '20px'
      }}
      onPress={ doOnPressAction }
      onMouseEnter={hover}
      onMouseLeave={unHover}
      dataSet={{'code': props.code}}
    >
      <View
        style={{
          flexDirection: isDesktop() ? 'row' : 'column',
          alignItems: 'center',
          height: '100%',
          marginTop:'5px',
          marginBottom:'15px',
          marginLeft:'15px',
          marginRight:'15px',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 4px 5px',
          borderWidth:'1px',
          borderRadius: '5px',
          transition:'.3s ease',
          borderColor: borderColor,
          cursor: cursorStyle
      }}
      >
        { image() }
        <View style={{
          flexDirection: 'column',
          flexShrink:'initial',
          /*alignItems: 'center',*/
          justifyContent: 'center',
          paddingTop: '10px',
          paddingLeft: isDesktop() ? '15px' : '0px',
          paddingBottom: '10px'
        }}>
          <Text style={{
            fontSize: isDesktop() ? '20px' : '16px',
            textAlign: isDesktop() ? 'left' : 'center',
            paddingBottom: '10px',
            fontWeight: 'bold'
          }}>
            {props.data.title}
          </Text>
          <Text style={{
            transition:'.3s ease',
            height: displayText ? 'auto' : '0px',
            opacity: displayText ? '100%' : '0%',
          }}>
            {displayText ? props.data.content : ''}
          </Text>
        </View>
      </View>



    </Pressable>
  )


  //Old style card kept for posterity, may remove later if not needed.
  /*return(
    <View style={{
      width: isDesktop() ? props.data.width : '100%',
      padding: '5px'
    }}>
      <Card
        style={{
          borderWidth:'1px',
          height: '100%',
          borderRadius: '5px',
          borderColor: borderColor,
          cursor: cursorStyle
      }}
        onPress={ doOnPressAction }
        elevation={5}
        onMouseEnter={hover}
        onMouseLeave={unHover}
      >
        <Card.Title title={props.data.title} subtitle={props.data.subtitle} />
        { image() }
        <Card.Content>
          <Paragraph>{props.data.content}</Paragraph>
        </Card.Content>
      </Card>
    </View>
  )*/

}
