import React from "react";

function fetchJson(url, options) {
  return fetch(url, Object.assign({
    credentials: 'same-origin',
  }, options))
    .then(response => {
      return response.json();
    });
}

export function getData(url, authToken = null, signal = null){

  return fetchJson(url, {
    method: 'GET',
    headers: {
      'Accept' : "application/json",
      'X-AUTH-TOKEN': authToken,
    },
    signal: signal
  })
}

export function patchData(url, authToken = null, values){

  let body = JSON.stringify(values);
  return fetchJson(url, {
    method: 'PATCH',
    body: body,
    headers: {
      'Accept' : "application/json",
      'Content-Type': "application/merge-patch+json",
      'X-AUTH-TOKEN': authToken,
    }
  })
}

export function postData(url, authToken = null, values){

  let body = JSON.stringify(values);
  return fetchJson(url, {
    method: 'POST',
    body: body,
    headers: {
      'Accept' : "application/json",
      'Content-Type': "application/json",
      'X-AUTH-TOKEN': authToken,
    }
  })
}

export function deleteData(url, authToken = null, values){

  let body = JSON.stringify(values);
  return fetchJson(url, {
    method: 'DELETE',
    body: body,
    headers: {
      'Accept' : "application/json",
      'Content-Type': "application/json",
      'X-AUTH-TOKEN': authToken,
    }
  })
}

