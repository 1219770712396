import {addRoleCheckboxes, addRoleDefaultValues, addRoleDefaultValuesToNewComponent} from "../FoundationsPlugin";
import * as React from "react";
import {PowerforceContext} from "../../../helpers/context";

const script = function() {

  //console.log('### Blank Script ###');

}

export const DividerMethods = (options, editor) => {

  console.log(options);

  let config = {
    // Model definition
    model: {
      // Default properties
      defaults: {
        script,
        tagName: 'div',
        droppable: false, // Can't drop other elements inside
        attributes: { // Default attributes
          componentId: 'add',
          //type: 'text',
          //name: 'default-name',
          //placeholder: 'Insert text here',
        },
        traits: [],

      },
      updated(property, value, prevValue) {
        //console.log('updated', property, value, prevValue);
        if (property === 'traits') {
          this.views[0].el.style.height = value.target.attributes.attributes.height;
        }
      }
    },
    view: {
      updated(property, value, prevValue) {
        //console.log('updateView', property, value, prevValue);
      }
    }
  }

  if(options.editorType === 'full'){
    //Add role checkboxes
    config = addRoleCheckboxes(options.roles, config);
  }

  console.log(config);

  return config;
}



/* Label for sidebar*/

export const DividerLabel = '<div style="font-size:20px;">' +
  '<div><b>Divider</b></div>' +
  '<i class="fa fa-minus" aria-hidden="true" style="padding-top:10px"></i></div>'

/* Component structure */
export const Divider = () => {

  let json = {
    "type": "divider",
    "attributes": {
      "width": "100%",
      "height": "5px",
      "style": "background-color: #f05d2a;width:100%;height:5px;"
    },
    "removable": true,
    "editable" : true,
    "components": [
      {
        "type": "textnode",
        "content": " "
      }
    ]
  };

  return json;


}
