
export function getIdfromApiString(apiString){

}

export function randomKey(max){
  let text = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < max; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}


function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    'T' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}

export function formatDate2(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  let dateTimeString = [year, month, day].join('-');
  dateTimeString = dateTimeString + 'T'
    + ("00" + d.getHours()).slice(-2) + ":"
    +("00" + d.getMinutes()).slice(-2) + ":"
    + ("00" + d.getSeconds()).slice(-2);
  return [year, month, day].join('-');
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}


export function inputMask(value, maskPattern){

  let number = value.replace(/[^0-9]/g, '');
  number = '(' + number[0] + number[1] + number[2] + ')';

  return number;
}

export function stripInputMask(value, maskPattern){
  let number = value.replace(/[^0-9]/g, '');
  return number;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function uncapitalizeFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function formatFromCamelCase(string){
  return capitalizeFirstLetter(string.replace(/([A-Z])/g, ' $1').trim());
}

export function formatToCamelCase(string){
  return uncapitalizeFirstLetter(string.replace(/\s/g, '').trim());
}

export function objectToString(o) {
  let string;
  for (let [key, value] of Object.entries(o)) {
    if(value){
      string = string + ' ' + value;
    }
  }

  return string;
}

