import * as React from 'react';
import {Pressable, Text, StyleSheet} from 'react-native';
import {View, Image} from "react-native-web";
import { Avatar } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import {useContext} from "react";
import {PowerforceContext} from "../../helpers/context";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SimpleNav from "./SimpleNav";
import MultiNav from "./MultiNav";




const TopNavigationBar = (props) => {

  const powerforce = useContext(PowerforceContext);

  if (!props.showTopMenu) {
    return null;
  }

  const navPicker = () => {

    if(powerforce.companies && powerforce.companies.length > 1){
      return <MultiNav
        navItems={powerforce.topbar}
        companies={powerforce.companies ? powerforce.companies : []}
        navigation={props.navigation}
      />
    }
    return <SimpleNav
      navItems={powerforce.topbar}
      navigation={props.navigation}
    />;
  }

  return (
    <View style={{
      flexDirection:"row",
      background: powerforce.colors.primary,
      padding: '10px',
      height: '10vh',
      minHeight: '70px'
      }}
    >

      <View style={styles.logoContainer}>
        <View style={{
          width: '100%',
          height: '100%',

        }}>
          <Image
            source={{uri: powerforce.logoUrl}}
            resizeMode="contain"
            nativeID={'logo'}
            style={{
              width: '100%',
              height: '100%',
              minHeight: '5vh',
              maxWidth: '240px'
            }}
          />
        </View>
      </View>

      <View style={styles.navBar}>

        {navPicker()}

      </View>

      <View style={styles.userArea}>
        <Pressable
          onPress={ async () => {
            await AsyncStorage.removeItem('foundationsUserConfig');
            window.location.reload();
          } }
        >
          <Feather nativeID={'logout'} name="log-out" size={24} color="white" style={styles.notifications} />
        </Pressable>

        <Pressable
          onPress={ async () => {
            props.navigation.navigate('settings', { screenName: 'release-notes'})
          } }
        >
          <Feather name="settings" nativeID={'settings'} size={24} color="white" style={styles.notifications} />
        </Pressable>

        <Avatar.Text
          label={powerforce.userInitials}
          nativeID={'avatar'}
          size={58}
          style={{backgroundColor:powerforce.colors.accent}}
        />
      </View>


    </View>
  )

}

export default TopNavigationBar;

const styles = StyleSheet.create({
  logoContainer: {
    flex: 2,
    alignItems: "flex-start",
    justifyContent: 'left',
  },
  navItem: {
    textAlign:'center',
    padding: '10px',
    flex: 1
  },
  navBar: {
    flexDirection: "row",
    flex: 8,
    alignItems: "center"
  },
  userArea: {
    flex: 2,
    flexDirection: "row",
    justifyContent: 'right',
    alignItems: "center"
  },
  notifications: {
    paddingRight: '20px',
  }
});
