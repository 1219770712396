import {addRoleCheckboxes, addRoleDefaultValues, addRoleDefaultValuesToNewComponent} from "../FoundationsPlugin";

const script = function() {

  //console.log('### Text Script ###');

}

export const TextMethods = (options, editor) => {

  console.log(options);

  let config = {
    // Model definition
    model: {
      // Default properties
      defaults: {
        script,
        tagName: 'div',
        droppable: false, // Can't drop other elements inside
        attributes: { // Default attributes
          componentId: 'add',
          //type: 'text',
          //name: 'default-name',
          //placeholder: 'Insert text here',
        },
        traits: [
          {
            type: 'text',
            label: 'Width',
            name: 'width',
          },
          {
            type: 'text',
            label: 'Font Size',
            name: 'fontSize',
          },
          {
            type: 'select',
            label: 'Text Align',
            name: 'textAlign',
            options: [ // Array of options
              {id: 'left', name: 'left'},
              {id: 'right', name: 'right'},
              {id: 'center', name: 'center'},
            ]
          },
        ],

      },
      updated(property, value, prevValue) {
        //console.log('updated', property, value, prevValue);
        if (property === 'traits') {

          this.views[0].el.style.width = value.target.attributes.attributes.width;
          this.views[0].el.style.fontSize = value.target.attributes.attributes.fontSize;
          this.views[0].el.style.textAlign = value.target.attributes.attributes.textAlign;

        }
      }
    },
    view: {
      updated(property, value, prevValue) {
        //console.log('updateView', property, value, prevValue);
      }
    }
  }

  if(options.editorType === 'full'){
    //Add role checkboxes
    config = addRoleCheckboxes(options.roles, config);
  }

  return config;
}



/* Label for sidebar*/

export const TextLabel = '<div style="font-size:20px;">' +
  '<div><b>Text</b></div>' +
  '<i class="fa fa-text-width" aria-hidden="true" style="padding-top:10px"></i></div>'

/* Component structure */
export const Text = () => {

  let json = {
    "type": "text",
    "attributes": {
      "fontSize": "14px",
      "textAlign": "left",
      "width": "100%",
      "style": "font-size:14px;width:100%;"
    },
    "components": [
      {
        "type": "textnode",
        "content": "Text Here"
      }
    ]
  };

  return json;


}
