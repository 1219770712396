import * as React from 'react';
import {Picker} from "@react-native-picker/picker";
import {View, TextInput, Text, StyleSheet} from "react-native";
import {ActivityIndicator, Button, Checkbox} from "react-native-paper";
import {PowerforceContext} from "../../helpers/context";
import {getData, postData} from "../../helpers/api";
import {debounce} from "lodash";
import {useCallback} from "react";
import DropDown from "react-native-paper-dropdown";

export default function ReportSelector(props){

    const powerforce = React.useContext(PowerforceContext);

    const [reportChanges, setReportChanges] = React.useState([]);
    const [selectAll, setSelectAll] = React.useState(false);

    // =========== Updating Reports ============

    const updateReports = (reportToChange, changeKey, changeValue) => {

        // Copy state array
        let updatedReportChanges = reportChanges;
        // Add report to changes array if it doesn't exist
        if(!updatedReportChanges[reportToChange.reportKey]){
            updatedReportChanges[reportToChange.reportKey] = {...reportToChange};
        }
        // Change the specified value
        updatedReportChanges[reportToChange.reportKey][changeKey] = changeValue;
        // Set changes to state
        setReportChanges(updatedReportChanges);
        console.log(reportChanges);

        // Update all reports props and send to ReportManager
        let updatedReports = props.reports;
        updatedReports.find((report, index) => {
            if (report.reportKey === reportToChange.reportKey) {
                updatedReports[index][changeKey] = changeValue;
                return true; // stop searching
            }
            return false;
        });

        // Callback to ReportManager. Must be full array of reports with changes.
        props.updateReports(updatedReports)
    }

    const selectAllReports = () => {
        let updatedReports = props.reports;

        // If selectAll was true, make them all unselected
        let value = true;
        if(selectAll){
            value = false;
        }

        updatedReports.map(report => {
            updateReports(report, 'active', value);
        })

        setSelectAll(!selectAll);
    }

    const updateRoles = (reportToChange, role) => {
        console.log(reportToChange, role);
        let newRoles = reportToChange.roles;

        // We need to remove it if it is already in the array
        if(reportToChange.roles.includes(role)){
            newRoles = newRoles.filter(item => item !== role)
        }else{
            // We need to add the report to the role if it is not in the array
            newRoles.push(role);
        }
        // Now update the report
      console.log(newRoles);
        updateReports(reportToChange, 'roles', newRoles)
    }
    // =========== Saving Reports ============

    const saveReportsCallback = () => {
        setReportChanges([]);
        console.log('callback!');
    }

    // =========== RETURN ============

    if(props.loading){
        return(
            <View style={styles.loadContainer}>
                <ActivityIndicator size="large" animating={true} color={powerforce.colors.primary} />
            </View>
        );
    }

    if(props.reports === null){
        return(<View><Text>Select a Company and a Workspace then click "Get Reports"</Text></View>);
    }

    return (
      <View style={{display: "block", width: "100%"}}>

          <View style={styles.tableHeader}>

            <View style={styles.checkColumn}>
                <Checkbox
                    status={selectAll ? 'checked' : 'unchecked'}
                    color={props.color}
                    disabled={false}
                    onPress={() => {
                        selectAllReports()
                    }}
                />
            </View>
            <View style={styles.column}><Text>PowerBI Name</Text></View>
            <View style={styles.smallColumn}><Text>Type</Text></View>
              <View style={styles.column}><Text>Foundations Friendly Name</Text></View>
            <View style={styles.column}><Text>Report Group</Text></View>
          </View>

          {props.reports.map((report, index) => {
              return(
                <ReportRow
                    report={report}
                    roles={props.roles}
                    reportIndex={index}
                    key={index}
                    updateRoles={updateRoles}
                    updateReports={updateReports}
                    datasets={props.datasets}
                />
              )
          })}

          <View style={{display:'block', maxWidth:'300px', marginTop:'50px'}}>
              <Button
                  icon={null}
                  mode={"contained"}
                  onPress={() => {
                      console.log('saveReports!');
                      console.log(reportChanges);
                      props.saveReports({ ...reportChanges }, saveReportsCallback)
                  }}
              >
                  Save
              </Button>
          </View>

      </View>


    )

}

function ReportRow(props){

    // report, roles
    const [expanded, setExpanded] = React.useState(false);
    const [friendlyName, setFriendlyName] = React.useState(props.report.friendlyName);
    const [reportGroup, setReportGroup] = React.useState(props.report.reportGroup);
    const [datasetId, setDatasetId] = React.useState(props.report.datasetId);


    const updateReportsDebounced = useCallback(
        debounce(props.updateReports, 1000, {
            'leading': false,
            'trailing': true
        }), []
    )

    //debounceUpdateFriendlyName(props.report, 'friendlyName', text)

    const Roles = (props) => {
        if(!expanded){
            return (
                <View style={styles.roleRow}>
                    <Button
                        icon={null}
                        mode={"contained"}
                        onPress={() => setExpanded(true)}
                    >
                        Show Roles
                    </Button>
                </View>
            );
        }

        return(
            <View style={styles.expandedRoleRow}>
                <View style={styles.assignRolesTop}>
                    <Text style={{fontSize:'1.4em', paddingRight:'20px'}}>Assign Roles</Text>
                    <Button
                        icon={null}
                        mode={"text"}
                        onPress={() => setExpanded(false)}
                    >
                        Hide Roles
                    </Button>
                </View>

                {props.roles.map((role, index) => {
                    return(
                        <View style={styles.roleContainer} key={index}>
                            <Checkbox
                                status={props.report.roles.includes(role) ? 'checked' : 'unchecked'}
                                color={props.color}
                                disabled={false}
                                onPress={() => {
                                    props.updateRoles(props.report, role)
                                }}
                            />
                            <Text style={styles.roleName}>{role}</Text>
                        </View>
                    )
                })}
            </View>
        )
    }

    return (
        <View style={styles.tableRow} key={props.reportIndex}>
            <View style={styles.checkColumn}>
                <Checkbox
                    status={props.report.active ? 'checked' : 'unchecked'}
                    color={props.color}
                    disabled={false}
                    onPress={() => {
                        console.log(props.report);
                        props.updateReports(props.report, 'active', !props.report.active)
                    }}
                />
            </View>

            <View style={styles.column}>
                <TextInput
                    value={props.report.name ? props.report.name : ''}
                    editable={false}
                    onChangeText={ text => { } }
                    onSubmitEditing={(e) => {} }
                    style={styles.textInput}
                />
              <DatasetDropdown
                selectedDataset={props.report.datasetId}
                datasets={props.datasets}
                type={props.report.type}
                report={props.report}
                onChange={props.updateReports}
              />
            </View>

            <View style={styles.smallColumn}>
                <TextInput
                    value={props.report.type ? props.report.type : ''}
                    editable={false}
                    onChangeText={ text => { } }
                    onSubmitEditing={(e) => {} }
                    style={styles.textInput}
                />
            </View>

            <View style={styles.column}>
                <TextInput
                    value={friendlyName ? friendlyName : ''}
                    editable={props.report.active}
                    placeholder={'Friendly Name'}
                    onChangeText={ text => {
                        setFriendlyName(text);
                        updateReportsDebounced(props.report, 'friendlyName', text)
                    } }
                    style={styles.textInputBordered}
                />
            </View>

            <View style={styles.column}>
                <TextInput
                    value={reportGroup ? reportGroup : ''}
                    editable={props.report.active}
                    placeholder={'Report Group'}
                    onChangeText={ text => {
                        setReportGroup(text);
                        updateReportsDebounced(props.report, 'reportGroup', text)
                    } }
                   style={styles.textInputBordered}
                />
            </View>


            <Roles {...props} />

        </View>
    )
}

function DatasetDropdown(props){
  const [selectedDataset, setSelectedDataset] = React.useState(props.selectedDataset ? props.selectedDataset : false);

  //console.log(props);

  if(props.type !== 'Paginated Report'){
    return null;
  }

  return(
    <Picker
      selectedValue={selectedDataset}
      onValueChange={(itemValue, itemIndex) => {
        setSelectedDataset(itemValue)
        props.onChange(props.report, 'datasetId', itemValue)
      }}
    >

      <Picker.Item label="No Dataset" value="none" />

      {props.datasets.map(dataset => {
        return(<Picker.Item label={dataset.name} value={dataset.id} key={dataset.id} />)
      })}

    </Picker>
  )
}


const styles = StyleSheet.create({
  loadContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      height: '50vh'
  },
  tableHeader: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: '#c0c0c0',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  tableRow: {
    flexDirection: "row",
    flexWrap: 'wrap',
    width: "100%",
    borderBottomColor: '#c0c0c0',
    borderBottomWidth: '2px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  column: {
    width: '20%',
    marginLeft: '10px'
  },
  smallColumn: {
      width: '10%',
      marginLeft: '10px'
  },
  checkColumn: {
      width: '5%',
      marginLeft: '10px'
  },
  textInput: {
      width: "100%",
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingLeft: '2px',
  },
  textInputBordered: {
    width: "100%",
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '2px',
    borderWidth: '1px',
    borderColor: '#c0c0c0',
  },
  roleRow: {
      width: '14%',
      marginLeft: '1%'
  },
  expandedRoleRow: {
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingTop: '20px',
      paddingRight: '20px',
      paddingBottom: '20px',
      paddingLeft: '20px',
      backgroundColor: '#dadada',

  },
  roleContainer: {
      flexDirection: 'row',
      minWidth: '20%'
  },
  roleName:{
      paddingTop: '6px',
      paddingBottom: '5px',
  },
  assignRolesTop: {
      width:'100%',
      paddingBottom: '20px',
      paddingLeft:'10px',
      flexDirection: 'row',
  }
})
