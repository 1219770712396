import * as React from "react";
import {Checkbox, DataTable} from "react-native-paper";
import {ScrollView, Text} from "react-native-web";
import {StyleSheet, View} from "react-native";
import {PowerforceContext} from "../../../helpers/context";
import {useNavigation} from "@react-navigation/native";
import {some, isEqual} from 'lodash';


export const RelationTable = (props) => {

  const powerforce = React.useContext(PowerforceContext);
  const navigation = useNavigation();

  const styles = StyleSheet.create({
    pagination: {
      justifyContent: 'left',
      flex: 1
    },
    grid: {
      position: 'relative',
      backgroundColor: '#ffffff',
      borderRadius:'15px',
      flexGrow: 1,
      boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 4px;',
      width: '48%',
      marginRight: '1%'
    },
    tableHeader: {
      backgroundColor: '#eeeeee',
      cursor: 'pointer'
    },
    scrollView: {
      maxHeight: 600,
      minHeight: 400
    },
    searchBar: {
      flex: 2,
      border: '2px solid grey',
      borderRadius: '50px',
      boxShadow:'none'
    },
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      marginTop: '10vh'
    },
    snackbar:{
      backgroundColor: powerforce.colors.primary,
      color: '#ffffff'
    }
  });


  //STATE FOR ALL
  const [allData, setAllData] = React.useState(props.items);
  const numberOfItemsPerPageList = [10, 20, 50, 100];
  const [tableData, setTableData] = React.useState(allData.slice(0, numberOfItemsPerPageList[0]));
  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, allData.length);

  //Change Page
  const changePage = (page) => {
    setPage(page);
  }
  React.useEffect(() => {
    let newData = allData.slice(from, from+numberOfItemsPerPage);
    setTableData(newData);
  }, [page]);

  //Change Items Per Page
  const changeItemsPerPage = (numberOfItemsPerPage) => {
    onItemsPerPageChange(numberOfItemsPerPage);
  }
  React.useEffect(() => {
    setPage(0);
    let newData = allData.slice(from, from+numberOfItemsPerPage);
    setTableData(newData);
  }, [numberOfItemsPerPage]);


  //STATE FOR SELECTED
  const [selectedData, setSelectedData] = React.useState(props.value ? props.value : []);

  const selectedNumberOfItemsPerPageList = [10, 20, 50, 100];
  const [selectedTableData, setSelectedTableData] = React.useState(selectedData.slice(0, selectedNumberOfItemsPerPageList[0]));
  const [selectedPage, setSelectedPage] = React.useState(0);
  const [selectedNumberOfItemsPerPage, onSelectedItemsPerPageChange] = React.useState(selectedNumberOfItemsPerPageList[0]);
  const selectedFrom = selectedPage * selectedNumberOfItemsPerPage;
  const selectedTo = Math.min((selectedPage + 1) * selectedNumberOfItemsPerPage, selectedData.length);

  function areAllSelected(selectedItems, allItems){
    return isEqual(selectedItems, allItems);
  }
  const [selectAllItems, setSelectAllItems] = React.useState(areAllSelected(props.value, props.items));

  //Change Page
  const selectedChangePage = (page) => {
    setSelectedPage(page);
  }
  React.useEffect(() => {
    let newData = selectedData.slice(selectedFrom, selectedFrom+selectedNumberOfItemsPerPage);
    setSelectedTableData(newData);
  }, [selectedPage]);

  //Change Items Per Page
  const changeSelectedItemsPerPage = (numberOfItemsPerPage) => {
    onSelectedItemsPerPageChange(numberOfItemsPerPage);
  }
  React.useEffect(() => {
    //setSelectedPage(0);
    let newData = selectedData.slice(selectedFrom, selectedFrom+selectedNumberOfItemsPerPage);
    setSelectedTableData(newData);
  }, [selectedNumberOfItemsPerPage]);

  //Checkbox functionality
  const onChange = (item, checkboxValue, changeSource = 'all') => {

    let newValue;

    // Make sure we have an array to work with
    if(props.value){
      newValue = props.value;
    }else{
      newValue = [];
    }


    if(checkboxValue){
      // If checkbox is true then add it
      newValue.push(item);
    }else{
      // If checkbox is false, find index and remove it
      const index = newValue.findIndex(x => x.id === item.id);

      if (index > -1) { // only splice array when item is found
        newValue.splice(index, 1);
      }else{
        console.log('no index found')
      }
    }

    let newSelectedData = selectedData;
    if(checkboxValue){
      // Make sure the array does not already contain the object
      if(!some(newSelectedData, item)){
        newSelectedData.push(item);
      }

    }else{

      const index = newSelectedData.findIndex(x => x.id === item.id);
      if (index > -1) { // only splice array when item is found
        newSelectedData.splice(index, 1);
        setSelectAllItems(false);
      }else{
        console.log('no index found for selectedData')
      }

    }


    setSelectedData([...newSelectedData]);
    let newSelectedTableData = selectedData.slice(selectedFrom, selectedFrom+selectedNumberOfItemsPerPage);
    setSelectedTableData([...newSelectedTableData]);

    let newData = allData.slice(from, from+numberOfItemsPerPage);
    setTableData([...newData]);

    // Force Rerender if on the page where data is shown
    let rerender = false;
    const index = tableData.findIndex(x => x.id === item.id);
    if (index > -1 && changeSource === 'selected') {
      rerender = true;
    }

    props.onChange(props.dataSource, newSelectedData, null, rerender);

  }

  const selectAll = (datum, checked) => {

    if(checked){
      let newValue = [];
      allData.map((row) => {
        newValue.push(row);
      })
      props.onChange(props.dataSource, newValue, null, true);
    }else{
      props.onChange(props.dataSource, [], null, true);
    }

  }

  return (
    <View style={{flexDirection:"row"}}>

      {/*ALL ITEMS*/}
      <DataTable style={styles.grid}>
        <View style={{flexDirection:"row", padding:"20px"}}>
          <Text style={{flex:1, fontSize: '2rem'}}>All {props.label}</Text>
        </View>
        <DataTable.Header>
          <DataTable.Title
          >
            Selected
          </DataTable.Title>
          <DataTable.Title
          >
            {props.relationColumn}
          </DataTable.Title>
        </DataTable.Header>

        <ScrollView>
          <DataTable.Row>
            <DataTable.Cell >
              <CheckboxGridInput
                value={selectAllItems}
                onChange={selectAll}
              />
            </DataTable.Cell>
            <DataTable.Cell >
              <Text>Select All</Text>
            </DataTable.Cell>
          </DataTable.Row>
          {
            tableData.map(datum => {

              return (

                <DataTable.Row
                  key={datum.id}
                  //onPress={() => console.log('row pressed')}
                >
                  <DataTable.Cell >
                    <CheckboxGridInput
                      value={some(selectedData, datum)}
                      datum={datum}
                      onChange={onChange}
                      changeSource={'all'}
                    />
                  </DataTable.Cell>
                  <DataTable.Cell >
                    {datum[props.relationColumn]}
                  </DataTable.Cell>
                </DataTable.Row>
              )
            })
          }
        </ScrollView>

        <DataTable.Pagination
          page={page}
          numberOfPages={Math.ceil(allData.length / numberOfItemsPerPage)}
          onPageChange={page => changePage(page)}
          label={`${from + 1}-${to} of ${allData.length}`}
          numberOfItemsPerPageList={numberOfItemsPerPageList}
          numberOfItemsPerPage={numberOfItemsPerPage}
          onItemsPerPageChange={changeItemsPerPage}
          selectPageDropdownLabel={'Rows per page'}
        />
      </DataTable>

      {/*SELECTED ITEMS*/}
      <DataTable style={styles.grid}>
        <View style={{flexDirection:"row", padding:"20px"}}>
          <Text style={{flex:1, fontSize: '2rem'}}>Selected {props.label}</Text>
        </View>
        <DataTable.Header>
          <DataTable.Title
          >
            Selected
          </DataTable.Title>
          <DataTable.Title
          >
            {props.relationColumn}
          </DataTable.Title>
        </DataTable.Header>

        <ScrollView>
          {
            selectedTableData.map(datum => {
              return (

                <DataTable.Row key={datum.id}>
                  <DataTable.Cell >
                    <CheckboxGridInput
                      value={true}
                      datum={datum}
                      onChange={onChange}
                      changeSource={'selected'}
                    />
                  </DataTable.Cell>
                  <DataTable.Cell >
                    {datum[props.relationColumn]}
                  </DataTable.Cell>
                </DataTable.Row>
              )
            })
          }
        </ScrollView>

        <DataTable.Pagination
          page={selectedPage}
          numberOfPages={Math.ceil(selectedData.length / selectedNumberOfItemsPerPage)}
          onPageChange={page => selectedChangePage(page)}
          label={`${selectedFrom + 1}-${selectedTo} of ${selectedData.length}`}
          numberOfItemsPerPageList={selectedNumberOfItemsPerPageList}
          numberOfItemsPerPage={selectedNumberOfItemsPerPage}
          onItemsPerPageChange={changeSelectedItemsPerPage}
          selectPageDropdownLabel={'Rows per page'}
        />
      </DataTable>

    </View>
  );


}

const CheckboxGridInput = (props) => {

  const [checked, setChecked] = React.useState(props.value);

  return (
    <Checkbox
      status={checked ? 'checked' : 'unchecked'}
      color={props.color}
      disabled={props.disabled}
      onPress={() => {
        if(!props.disabled){
          setChecked(!checked);
          props.onChange(props.datum, !checked, props.changeSource)
        }
      }}
    />
  );
};
